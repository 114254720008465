/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const BookmarkFavouriteStarBold = React.forwardRef(function BookmarkFavouriteStarBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M21 2.25A2.25 2.25 0 0018.75 0H5.25A2.25 2.25 0 003 2.25V22.5a1.5 1.5 0 002.382 1.212l6.177-4.492a.75.75 0 01.882 0l6.177 4.492A1.5 1.5 0 0021 22.502V2.25zm-9.737 4.005c.232-.714 1.242-.714 1.474 0l.652 2.008c.004.01.013.017.024.017h2.111c.751 0 1.063.96.456 1.402l-1.708 1.24a.025.025 0 00-.01.029l.653 2.007c.232.714-.585 1.308-1.192.867l-1.708-1.241a.025.025 0 00-.03 0l-1.707 1.24c-.608.442-1.425-.152-1.193-.866l.652-2.007a.025.025 0 00-.009-.028L8.02 9.682c-.607-.441-.295-1.402.456-1.402h2.11a.026.026 0 00.025-.017l.652-2.008z"
        fillRule="evenodd"
      />
    </svg>
  );
});

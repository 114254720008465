/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const PinBold = React.forwardRef(function PinBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M15.336.013a2.203 2.203 0 011.798.632l5.721 5.721a2.203 2.203 0 01-.376 3.416l-5.965 3.797a8.099 8.099 0 01-.98 5.445v.002a2.203 2.203 0 01-3.46.441L9.09 16.485 2.507 23.07c-.398.399-.969.52-1.472.365a.734.734 0 01-.97-.972 1.468 1.468 0 01.365-1.47l6.584-6.584-2.981-2.981a2.203 2.203 0 01.44-3.46l.373.634-.37-.635a8.1 8.1 0 015.446-.98l3.796-5.966A2.203 2.203 0 0115.336.013z" />
    </svg>
  );
});

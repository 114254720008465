import * as React from 'react';
import { Divider } from '../divider/Divider';

/**
 * CardDivider
 * @param param0 props
 */
export const CardDivider: React.FunctionComponent = () => {
  return <Divider />;
};

CardDivider.displayName = 'Card.Divider';

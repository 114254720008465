/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesXls = React.forwardRef(function FilesXls(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M1.72 1.712a.752.752 0 01.53-.218H12v5.228c0 .594.237 1.164.659 1.584.422.42.994.657 1.591.657h5.25v3.734a.749.749 0 001.5 0V8.088a2.233 2.233 0 00-.659-1.584L14.471.657A2.251 2.251 0 0012.879 0H2.25C1.653 0 1.081.236.659.656.237 1.076 0 1.646 0 2.241v19.418c0 .595.237 1.165.659 1.585.422.42.994.656 1.591.656h3c.414 0 .75-.334.75-.747a.748.748 0 00-.75-.747h-3a.751.751 0 01-.75-.747V2.241c0-.198.079-.388.22-.529zm11.78.091v4.919a.745.745 0 00.75.747h4.94L13.5 1.803z"
        fillRule="evenodd"
      />
      <path d="M16.74 22.491h-1.51a.761.761 0 01-.754-.754v-6a.761.761 0 00-.755-.754.761.761 0 00-.754.754v6a2.253 2.253 0 002.246 2.246h1.508a.76.76 0 00.755-.754.732.732 0 00-.737-.738zm-5.726-7.44a.758.758 0 00-.977.412l-.789 2.023-.805-2.006a.752.752 0 00-.978-.411.752.752 0 00-.411.977l1.389 3.463-1.389 3.462a.758.758 0 00.411.978.752.752 0 00.977-.429l.806-2.006.806 2.006a.76.76 0 00.977.429.752.752 0 00.412-.978l-1.389-3.462 1.389-3.463a.765.765 0 00-.429-.995zm9.48 2.195c0 .703.428 1.063 1.2 1.645l.038.03c.799.611 1.762 1.348 1.762 2.816a2.253 2.253 0 01-2.246 2.246H19.74a.761.761 0 01-.755-.754c0-.412.343-.755.755-.755h1.508a.761.761 0 00.755-.754c0-.703-.429-1.063-1.2-1.646l-.006-.004c-.805-.599-1.794-1.336-1.794-2.841a2.253 2.253 0 012.245-2.246h1.509c.411 0 .754.343.754.754a.76.76 0 01-.754.754h-1.509a.761.761 0 00-.754.755z" />
    </svg>
  );
});

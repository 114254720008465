/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DevicesSmartWatch = React.forwardRef(function DevicesSmartWatch(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M18.373 4.488h-.994l-.428-2.62A2.26 2.26 0 0014.724 0H9.773a2.23 2.23 0 00-2.21 1.867l-.428 2.621H6.14c-1.18 0-2.14.96-2.14 2.124V17.37c0 1.165.96 2.125 2.124 2.125h.994l.428 2.62A2.242 2.242 0 009.756 24h4.95a2.229 2.229 0 002.21-1.884l.429-2.621h.993c1.165 0 2.124-.96 2.124-2.125V6.612a2.095 2.095 0 00-2.09-2.124zM9.019 2.124a.752.752 0 01.737-.617h4.95c.36 0 .686.257.737.617l.394 2.381H8.625l.394-2.38zM15.46 21.86a.766.766 0 01-.736.633H9.773a.738.738 0 01-.737-.633l-.41-2.364h7.228l-.394 2.364zm3.53-4.489a.614.614 0 01-.617.617H6.124a.614.614 0 01-.617-.617V6.612c0-.342.275-.616.617-.616h12.249c.342 0 .616.274.616.616V17.37z" />
      <path d="M12.24 13.122a1.508 1.508 0 100-3.015 1.508 1.508 0 000 3.015zm-1.867-2.998a1.13 1.13 0 001.13-1.13c0-.634-.497-1.131-1.13-1.131-.634 0-1.131.497-1.131 1.13a1.13 1.13 0 001.13 1.131zm-.377 1.867a1.13 1.13 0 00-1.13-1.13 1.12 1.12 0 00-1.132 1.13 1.13 1.13 0 102.262 0zm5.619-1.131a1.13 1.13 0 100 2.262c.633 0 1.13-.497 1.13-1.13a1.14 1.14 0 00-1.13-1.131zm-1.491-.736a1.13 1.13 0 001.13-1.13c0-.634-.496-1.131-1.13-1.131-.634 0-1.13.497-1.13 1.13 0 .634.496 1.131 1.13 1.131zm-3.751 3.735a1.13 1.13 0 00-1.131 1.13c0 .634.497 1.13 1.13 1.13.634 0 1.131-.496 1.131-1.13a1.13 1.13 0 00-1.13-1.13zm3.751 0a1.13 1.13 0 00-1.13 1.13c0 .634.496 1.13 1.13 1.13.634 0 1.13-.496 1.13-1.13a1.13 1.13 0 00-1.13-1.13z" />
    </svg>
  );
});

import * as React from 'react';
import { FontSizeMap } from '../../constants';
import { getValidChildren, useFrameworkClassnamePrefixer } from '../../utils';
import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';
import { BreadcrumbLink } from './BreadcrumbLink';
import { BreadcrumbSeparator } from './BreadcrumbSeparator';

type SizeCompatibility = keyof Pick<FontSizeMap, 'small' | 'normal' | 'medium'>;

interface IBreadcrumbSubComponents {
  Item: typeof BreadcrumbItem;
  Link: typeof BreadcrumbLink;
  Separator: typeof BreadcrumbSeparator;
}

export interface IBreadcrumbProps {
  size?: SizeCompatibility;
}

type BreadcrumbComponent = React.FunctionComponent<IBreadcrumbProps> & IBreadcrumbSubComponents;

export const Breadcrumb: BreadcrumbComponent = ({ children, size = 'medium', ...props }) => {
  const cn = useFrameworkClassnamePrefixer();

  /**
   * TODO: Add automatic dropdown addition class to an item.
   * ! ASSUMPTION: a <Breadcrumb> element that contains 6 <Breadcrumb.Item> elements will collapse
   * ! the 3rd, 4th and 5th element in one cloned <Breadcrumb.Item> with `...` as text and a
   * ! dropdown generated from the 3rd, 4th and 5th item.
   * ? we need to know whether this should be with responsiveness
   * ? we need to know whether this is simple like f.ex pagination
   */

  const className = cn('breadcrumb', {
    [`is-${size}`]: size,
  });

  const validChildren = getValidChildren<BreadcrumbItemProps>(children);
  const validChildrenCount = validChildren.length;

  return (
    <nav aria-label="breadcrumb" className={className} role="presentation" {...props}>
      <ol>
        {validChildren.map((child, index) =>
          React.cloneElement(child, {
            isLastItem: validChildrenCount === index + 1,
          })
        )}
      </ol>
    </nav>
  );
};

Breadcrumb.Item = BreadcrumbItem;
Breadcrumb.Link = BreadcrumbLink;
Breadcrumb.Separator = BreadcrumbSeparator;

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export interface ICardImageProps {
  ratio?: 'square' | '5by4' | '5by3' | '5by2';
  cover?: boolean;
}

/**
 * CardImage
 * @param param0 props
 */
export const CardImage: React.FunctionComponent<ICardImageProps> = ({
  children,
  ratio = '5by3',
  cover = false,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const className = cn('card-image');

  const figureClassName = cn('card-image-figure', {
    [`is-ratio-${ratio}`]: ratio,
    [`is-cover`]: cover,
  });

  return (
    <div className={className}>
      <figure className={figureClassName}>{children}</figure>
    </div>
  );
};

CardImage.displayName = 'Card.Image';

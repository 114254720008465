import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Tab } from './Tab';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';
import { TabPanels } from './TabPanels';
import { FocusSelectionMode, TabsContext } from './TabsContext';
import { useID } from './utils';

interface ITabsSubComponents {
  Tab: typeof Tab;
  TabList: typeof TabList;
  TabPanel: typeof TabPanel;
  TabPanels: typeof TabPanels;
}

export interface ITabsProps {
  id?: string;
  initialActiveTab?: number;
  activeTab?: number;
  selectionMode?: FocusSelectionMode;
  onActiveTabChange?: (activeTab: number) => void;
}

type TabsComponent = React.FunctionComponent<ITabsProps> & ITabsSubComponents;

/**
 * <Tabs
 *   initalActiveTab={0}
 *   onActiveTabChange={() => console.log('onChange))}
 *   selectionMode={selectionMode}>
 *   <Tabs.TabList size="small">
 *     <Tabs.Tab>My tickets</Tabs.Tab>
 *     <Tabs.Tab>My devices</Tabs.Tab>
 *     <Tabs.Tab>Tab #3</Tabs.Tab>
 *     <Tabs.Tab>Tab #4</Tabs.Tab>
 *     <Tabs.Tab disabled={true}>Tab #5</Tabs.Tab>
 *   </Tabs.TabList>
 *   <Tabs.TabPanels>
 *     <Tabs.TabPanel>Tab #1 Content</Tabs.TabPanel>
 *     <Tabs.TabPanel>Tab #2 Content</Tabs.TabPanel>
 *   </Tabs.TabPanels>
 * </Tabs>
 */
export const Tabs: TabsComponent = ({
  children,
  onActiveTabChange,
  initialActiveTab = 0,
  selectionMode = FocusSelectionMode.AUTOMATIC,
}) => {
  const [activeTab, setActiveTab] = React.useState(initialActiveTab);
  const [focusedTab, setFocusedTab] = React.useState(activeTab);

  const cn = useFrameworkClassnamePrefixer();

  React.useEffect(() => {
    onActiveTabChange?.(activeTab);
  }, [activeTab, onActiveTabChange]);

  const id = useID('tabs');

  const contextValue = React.useMemo(
    () => ({
      id,
      activeTab,
      setActiveTab,
      focusedTab,
      setFocusedTab,
      selectionMode,
      onActiveTabChange,
    }),
    [id, activeTab, setActiveTab, focusedTab, setFocusedTab, onActiveTabChange, selectionMode]
  );

  const className = cn('tabs');

  return (
    <TabsContext.Provider value={contextValue}>
      <div className={className} id={id}>
        {children}
      </div>
    </TabsContext.Provider>
  );
};

Tabs.TabList = TabList;
Tabs.Tab = Tab;
Tabs.TabPanels = TabPanels;
Tabs.TabPanel = TabPanel;

import * as React from 'react';

export interface IContentSwitcherContext {
  id: string;
  selectedItem: number;
  setSelectedItem: (index: number) => void;
  focusedItem: number;
  setFocusedItem: (index: number) => void;
  isDisabled?: boolean;
}

export const ContentSwitcherContext = React.createContext<IContentSwitcherContext | undefined>(
  undefined
);

export const useContentSwitcherContext = (): IContentSwitcherContext => {
  const context = React.useContext(ContentSwitcherContext);

  if (context) {
    return context;
  }

  throw new Error(
    "ContentSwitcherItem compound components can't be rendered outside ContentSwitcher component"
  );
};

import * as React from 'react';
import { FontSizeMap, Weights } from '../../constants';
import { useFont } from '../../hooks';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { forwardRefAs, IPolymorphProps, Polymorph } from '../polymorph';

type SizeCompatibility = keyof Omit<FontSizeMap, ''>;

export type TypographyVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'a';

export type TypographyProps = IPolymorphProps & {
  size?: SizeCompatibility;
  weight?: Weights;
};

/**
 * Typography
 * The Typography component presents text in klik-ui. Typography has no specific semantic meaning
 * and can be used everywhere withint the application.
 * <Heading> and <Paragraph> are helper components that default to p and h1 tags
 * @see Heading and @see Paragraph
 * @returns text
 */
export const Typography = forwardRefAs<TypographyProps>(
  ({ as = 'p', size = 'normal', weight = 'default', ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    const fontSize = useFont(size);

    return (
      <Polymorph
        as={as}
        className={cn('text', `${fontSize}`, `text-weight-${weight}`)}
        ref={ref}
        {...props}
      />
    );
  }
);

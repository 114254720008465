/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LayoutList = React.forwardRef(function LayoutList(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M3.66 2a2.66 2.66 0 100 5.32 2.66 2.66 0 000-5.32zm-.482 1.496a1.26 1.26 0 11.964 2.328 1.26 1.26 0 01-.964-2.328zM3.66 9.84a2.66 2.66 0 100 5.318 2.66 2.66 0 000-5.318zm-.482 1.496a1.26 1.26 0 11.964 2.329 1.26 1.26 0 01-.964-2.33zm-.536 6.546a2.661 2.661 0 013.475 3.476 2.66 2.66 0 11-3.475-3.476zM3.66 19.08a1.258 1.258 0 00-.891 2.15 1.26 1.26 0 10.891-2.15z"
        fillRule="evenodd"
      />
      <path d="M8.233 4.613a.7.7 0 100 1.4H21.3a.7.7 0 100-1.4H8.233zm-.7 8.54a.7.7 0 01.7-.7H21.3a.7.7 0 110 1.4H8.233a.7.7 0 01-.7-.7zm.7 7.14a.7.7 0 100 1.4H21.3a.7.7 0 100-1.4H8.233z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FeedScroll = React.forwardRef(function FeedScroll(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M2.2 2.467a.733.733 0 00-.733.733v13.2a.733.733 0 00.733.733h5.136a.733.733 0 110 1.467H2.2A2.2 2.2 0 010 16.4V3.2A2.2 2.2 0 012.2 1h19.067a2.2 2.2 0 012.2 2.2v11.733a.733.733 0 11-1.467 0V3.2a.733.733 0 00-.733-.733h-2.2v8.066a.733.733 0 01-1.467 0V2.467H2.2z" />
      <path d="M5.133 5.4a.733.733 0 000 1.467h7.334a.733.733 0 000-1.467H5.133zm9.018 6.815a.733.733 0 011.252.518v2.934c0 .358.26.664.613.723l1.949.325a2.2 2.2 0 011.838 2.17v3.382a.733.733 0 101.467 0v-3.383a3.667 3.667 0 00-3.064-3.616l-1.336-.223v-2.312a2.2 2.2 0 00-4.4 0v3.95l-.092-.046a2.334 2.334 0 00-3.309 1.521v.002a2.334 2.334 0 00.813 2.388l.001.001 2.862 2.29a.733.733 0 10.916-1.145l-2.864-2.292-.002-.002a.867.867 0 01-.129-1.23.866.866 0 011.056-.222l1.154.575a.733.733 0 001.06-.656v-5.134c0-.194.078-.38.215-.518zM4.4 9.067c0-.405.328-.734.733-.734h7.334a.733.733 0 010 1.467H5.133a.733.733 0 01-.733-.733zm.733 2.2a.733.733 0 100 1.466H8.8a.733.733 0 100-1.466H5.133z" />
    </svg>
  );
});

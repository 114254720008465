/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const StatusHelp = React.forwardRef(function StatusHelp(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12.825 14.11h-1.32a2.008 2.008 0 01-.198-.868c0-.986.73-1.676 1.577-2.425.986-.927 1.538-1.46 1.538-2.425 0-1.184-.867-1.992-1.932-1.992-1.005 0-1.814.631-2.208 1.893L9 7.761C9.513 6.006 10.794 5 12.53 5 14.442 5 16 6.36 16 8.293c0 1.321-.769 2.208-1.873 3.194-.868.79-1.4 1.282-1.4 1.992 0 .203.043.391.08.552l.018.079zm.69 3.096c0 .73-.571 1.242-1.261 1.242a1.237 1.237 0 01-1.243-1.242c0-.73.592-1.243 1.243-1.243.69 0 1.261.513 1.261 1.243z" />
      <path
        clipRule="evenodd"
        d="M0 12C0 5.383 5.383 0 12 0s12 5.383 12 12-5.383 12-12 12S0 18.617 0 12zm1.509 0c0 5.777 4.697 10.491 10.491 10.491S22.491 17.777 22.491 12c0-5.777-4.697-10.491-10.491-10.491S1.509 6.223 1.509 12z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardFigure
 * @param param0
 */
export const CardFigure: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-figure')}>{children}</div>;
};

CardFigure.displayName = 'Card.Figure';

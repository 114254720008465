/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const WorkshopBold = React.forwardRef(function WorkshopBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#workshop-bold_svg__workshop-bold_svg__clip0)">
        <path d="M9.74 12.14v1.36a.76.76 0 00.75.75h3a.76.76 0 00.75-.75v-1.36a4.5 4.5 0 10-4.51 0h.01zM13.48 15h-3a.755.755 0 100 1.51h3a.76.76 0 00.696-1.04.75.75 0 00-.696-.47zM24 23a5.18 5.18 0 00-2.66-3 6.92 6.92 0 00-.57-.24 3.38 3.38 0 10-3.39 0A5.181 5.181 0 0014.12 23a.75.75 0 00.46 1 .578.578 0 00.23 0h8.25v-.05a.74.74 0 00.49 0A.77.77 0 0024 23zM7.24 20a6.92 6.92 0 00-.57-.24 3.39 3.39 0 10-3.41 0A5.181 5.181 0 000 23a.75.75 0 00.5 1c.08.016.16.016.24 0H9.1a.74.74 0 00.36 0 .75.75 0 00.46-1 5.29 5.29 0 00-2.68-3zM12 3.47a1 1 0 001-1V1a1 1 0 00-2 0v1.47a1 1 0 001 1zm5 2.07a1 1 0 00.69-.27l1.07-1a1.022 1.022 0 00-1.42-1.47l-1.07 1A1 1 0 0017 5.54zM5.28 4.26l1.07 1a1 1 0 001.38-1.45l-1.07-1a1 1 0 00-1.2-.12.73.73 0 00-.19.13 1 1 0 00-.14.22 1 1 0 00.15 1.22z" />
        <path d="M17 8.14a1 1 0 001 1h1.5a1 1 0 000-2H18a1 1 0 00-1 1zM6 9.26a1 1 0 000-2H4.49a1 1 0 000 2H6z" />
      </g>
      <defs>
        <clipPath id="workshop-bold_svg__workshop-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { IPolymorphProps, Polymorph } from '../polymorph';

export interface IMenuTriggerProps extends IPolymorphProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
  iconRight?: React.ReactElement;
  iconCenter?: React.ReactElement;
  parentClass?: string;
}

/**
 * MenuTrigger
 * React MenuTrigger component.
 *
 *
 * @returns MenuTrigger Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuTrigger: React.FunctionComponent<IMenuTriggerProps> = ({
  as = 'button',
  iconRight,
  iconCenter,
  children,
  onClick,
  parentClass,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const className = cn('menu-trigger', {
    [`${parentClass as string}`]: parentClass,
  });

  if (iconCenter) {
    const textClasses = cn('text');

    return (
      <MenuTrigger parentClass="is-icon">
        <Icon icon={iconCenter} />
        <div className={textClasses}>{children}</div>
      </MenuTrigger>
    );
  }

  return (
    <Polymorph as={as} className={className} onClick={onClick}>
      {children}
      {iconRight && <Icon icon={iconRight} />}
    </Polymorph>
  );
};

MenuTrigger.displayName = 'Menu.Trigger';

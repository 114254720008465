import * as React from 'react';
import { FontSizeMap } from '../../constants';
import { useFont } from '../../hooks';
import { useFrameworkClassnamePrefixer } from '../../utils';

type SizeCompatibility = keyof Pick<FontSizeMap, 'small' | 'xsmall' | 'normal'>;

export type HelpTextProps = React.HTMLAttributes<HTMLParagraphElement> & {
  label?: string;
  size?: SizeCompatibility;
};

/**
 * HelpText
 * @summary use the help text as a part of
 * @returns ExoticLabelElement
 */
export const HelpText = React.forwardRef<HTMLParagraphElement, HelpTextProps>(
  ({ label, size, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    const fontSize = useFont(size);

    return (
      <p ref={ref} {...props} className={cn('help', fontSize)}>
        {label}
      </p>
    );
  }
);

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const MenuNavigationHorizontalBold = React.forwardRef(function MenuNavigationHorizontalBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M6.5 12a3 3 0 11-6 0 3 3 0 016 0zm8.5 0a3 3 0 11-6 0 3 3 0 016 0zm5.5 3a3 3 0 100-6 3 3 0 000 6z" />
    </svg>
  );
});

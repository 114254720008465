/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const HistoryBold = React.forwardRef(function HistoryBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M11.014 19.833A6.75 6.75 0 0017.25 24 6.758 6.758 0 0024 17.251a6.75 6.75 0 10-12.986 2.582zM12.5 17.25a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0z"
        fillRule="evenodd"
      />
      <path d="M19.25 18.251h-2a1 1 0 01-1-1v-2.5a1 1 0 112 0V16c0 .138.112.25.25.25h.75a1 1 0 110 2.001z" />
      <path
        clipRule="evenodd"
        d="M8.3 18.5a.968.968 0 01.946.722l.004.022A1 1 0 018.3 20.5H3a3 3 0 01-3-3V3a3 3 0 013-3h18a3 3 0 013 3v7.049a.983.983 0 01-1.59.777l-.022-.018a1 1 0 01-.388-.777V5.25a.25.25 0 00-.25-.25H2.25a.25.25 0 00-.25.251V17.5a1 1 0 001 1h5.3zm2.2-17a.966.966 0 00-.846.5.969.969 0 00-.154.5c.005.178.058.35.154.5a.966.966 0 001.692 0 .972.972 0 00.154-.5.969.969 0 00-.154-.5.966.966 0 00-.846-.5zM6.154 2a.966.966 0 011.692 0A.97.97 0 018 2.5a.972.972 0 01-.154.5.966.966 0 01-1.692 0A.972.972 0 016 2.5a.969.969 0 01.154-.5zM3.5 1.5a1 1 0 00-.908.591.992.992 0 00-.092.409c.005.178.058.35.154.5a.966.966 0 001.692 0 .972.972 0 00.154-.5.97.97 0 00-.154-.5.983.983 0 00-.846-.5z"
        fillRule="evenodd"
      />
    </svg>
  );
});

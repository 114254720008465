const mediaSizes = {
  medium: 592,
  large: 1024,
  xLarge: 1440,
  fullWidth: 1632,
};

export const media = {
  medium: `@media (min-width: ${mediaSizes.medium}px)`,
  large: `@media (min-width: ${mediaSizes.large}px)`,
  xLarge: `@media (min-width: ${mediaSizes.xLarge}px)`,
};

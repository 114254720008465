/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const HumanResourcesOfferManBold = React.forwardRef(function HumanResourcesOfferManBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.728 4.503c0 1.958-1.653 3.545-3.692 3.545-2.04 0-3.692-1.587-3.692-3.545 0-1.957 1.653-3.544 3.692-3.544s3.692 1.587 3.692 3.544zm-3.692 4.834c-2.695 0-5.062 1.717-5.8 4.205a.235.235 0 00.04.21c.048.06.122.096.2.096h11.118a.253.253 0 00.2-.096.232.232 0 00.04-.21c-.738-2.487-3.104-4.203-5.798-4.205zM1.156 15.12c-.553 0-1 .43-1 .96v6.96c0 .53.447.96 1 .96.552 0 1-.43 1-.96v-6.96c0-.53-.448-.96-1-.96zm14.5 4.56H6.53a.613.613 0 01-.625-.6c0-.331.28-.6.625-.6h2.526c.336 0 .65-.162.835-.431a.927.927 0 00.085-.907c-.352-.79-1.162-1.303-2.057-1.302H3.656a.49.49 0 00-.5.48v6.24c0 .265.224.48.5.48H14.42c1.234-.002 2.235-.962 2.236-2.148v-.252c0-.53-.448-.96-1-.96z" />
    </svg>
  );
});

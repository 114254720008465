/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowRight = React.forwardRef(function ArrowRight(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M6.495.445a.75.75 0 011.06.05l10 11a.75.75 0 010 1.01l-10 11a.75.75 0 11-1.11-1.01L15.986 12 6.445 1.505a.75.75 0 01.05-1.06z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const Stepper = ({
  activeColor,
  activeStep,
  shouldHideInactiveStepsContent,
  children,
}) => {
  const childrenWithProps = Children.map(children, (child, index) => {
    const stepNumber = index + 1;
    const props = {
      number: stepNumber,
      isActive: stepNumber === activeStep,
      isLast: index + 1 === children.length,
      activeColor,
      shouldHideInactiveStepsContent,
    };

    return cloneElement(child, props);
  });

  return <ul>{childrenWithProps}</ul>;
};

Stepper.propTypes = {
  activeColor: PropTypes.string,
  stepsCompleted: PropTypes.number,
  activeStep: PropTypes.number,
  shouldHideInactiveStepsContent: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export { Stepper };

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LayoutListView = React.forwardRef(function LayoutListView(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M0 .75A.75.75 0 01.75 0h22.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75H.75A.75.75 0 010 5.25V.75zm1.5.75v3h21v-3h-21zM0 9.75A.75.75 0 01.75 9h22.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75v-4.5zm1.5.75v3h21v-3h-21zM0 18.75A.75.75 0 01.75 18h22.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75v-4.5zm1.5.75v3h21v-3h-21z"
        fillRule="evenodd"
      />
    </svg>
  );
});

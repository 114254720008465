/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Plus = React.forwardRef(function Plus(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12.75 1a.75.75 0 00-1.5 0v10.25H1a.75.75 0 000 1.5h10.25V23a.75.75 0 001.5 0V12.75H23a.75.75 0 000-1.5H12.75V1z" />
    </svg>
  );
});

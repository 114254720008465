/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowLongUpBold = React.forwardRef(function ArrowLongUpBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M10.991.39a1.5 1.5 0 012.018 0l8.001 7.272a1.5 1.5 0 01-2.017 2.22L13.5 4.89V22.5a1.5 1.5 0 01-3 0V4.89L5.009 9.88A1.5 1.5 0 012.99 7.66l8-7.27z"
        fillRule="evenodd"
      />
    </svg>
  );
});

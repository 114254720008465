/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const StrategyBold = React.forwardRef(function StrategyBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12 24c.306 0 .6-.126.816-.352.216-.224.338-.53.338-.848v-.404c-.008-3.763 2.036-7.206 5.274-8.884a.225.225 0 01.184-.01c.06.024.108.072.133.133l.232.582a.929.929 0 00.854.593.91.91 0 00.739-.384l2.322-3.208a.992.992 0 00.116-.946.93.93 0 00-.725-.583l-3.827-.56a.907.907 0 00-.857.364.992.992 0 00-.123.955l.207.52a.244.244 0 01-.113.31 11.909 11.909 0 00-4.007 3.252.226.226 0 01-.256.072.24.24 0 01-.153-.225V6.923a.25.25 0 01.067-.17.225.225 0 01.164-.07h.745a.956.956 0 00.837-.503c.172-.312.17-.696-.007-1.006l-2.13-3.758A.993.993 0 0012 .958a.993.993 0 00-.83.458L9.04 5.174c-.175.31-.177.694-.005 1.006a.957.957 0 00.836.504h.745c.06 0 .12.024.163.07a.244.244 0 01.067.17v7.453a.24.24 0 01-.153.225.226.226 0 01-.256-.072 11.909 11.909 0 00-4.007-3.253.244.244 0 01-.112-.308l.206-.521a.992.992 0 00-.122-.955.906.906 0 00-.858-.363l-3.827.56a.93.93 0 00-.725.583.992.992 0 00.116.946l2.326 3.209a.911.911 0 00.855.376.929.929 0 00.738-.585l.232-.582a.239.239 0 01.133-.133.223.223 0 01.184.01c3.239 1.679 5.282 5.121 5.275 8.884v.404c0 .66.514 1.195 1.15 1.198z" />
    </svg>
  );
});

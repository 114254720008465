/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const WifiOff = React.forwardRef(function WifiOff(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M23.78.22a.75.75 0 010 1.06l-22.5 22.5a.75.75 0 01-1.06-1.06L22.72.22a.75.75 0 011.06 0zM9.348 17.598a3.75 3.75 0 115.304 5.304 3.75 3.75 0 01-5.304-5.304zM12 18a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm2.361-4.904a.75.75 0 01.99-.382c.924.41 1.765.988 2.48 1.703a.75.75 0 01-1.061 1.06 6.726 6.726 0 00-2.027-1.392.75.75 0 01-.382-.99zM12 8.227a.75.75 0 01-.703.795 11.2 11.2 0 00-7.25 3.272.75.75 0 11-1.063-1.058 12.7 12.7 0 018.221-3.712.75.75 0 01.795.703zm5.304 1.229a.75.75 0 011.024-.278 12.768 12.768 0 012.688 2.057.75.75 0 11-1.062 1.06 11.271 11.271 0 00-2.372-1.815.75.75 0 01-.278-1.024zM7.146 3.695a17.234 17.234 0 017.94-.417.75.75 0 11-.268 1.476A15.734 15.734 0 001.262 8.729.75.75 0 01.238 7.633a17.234 17.234 0 016.908-3.938zM20.178 5.9a.75.75 0 011.035-.234 17.212 17.212 0 012.549 1.967.75.75 0 01-1.024 1.096 15.711 15.711 0 00-2.327-1.795.75.75 0 01-.233-1.035z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import { CheckTickBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { useID } from '../tabs/utils';

export type StepperState = 'active' | 'complete' | 'incomplete';

export type StepperItemProps = React.HTMLAttributes<HTMLButtonElement> & {
  label?: string;
  index?: number;
  state?: StepperState;
};

/**
 * StepperItem
 * use StepperItems in kombination with a stepper
 *
 * @returns Stepper Item
 * @memberof stepper
 * @author Simon Groenborg
 */
export const StepperItem: React.FunctionComponent<StepperItemProps> = ({
  label,
  state = 'incomplete',
  index,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const uid = useID('stepper');

  const body = React.useMemo(() => {
    if (state === 'complete') {
      return <Icon icon={<CheckTickBold />} />;
    }

    return index;
  }, [index, state]);

  return (
    <li
      aria-current={state === 'active'}
      className={cn('stepper-item', `is-${state}`)}
      role="presentation"
    >
      <div className={cn('stepper-graph')}>
        <button
          className={cn('stepper-link')}
          id={uid}
          {...props}
          tabIndex={state === 'complete' ? 0 : -1}
        >
          {body}
        </button>
      </div>
      <label className={cn('stepper-label')} htmlFor={uid}>
        {label}
      </label>
    </li>
  );
};

StepperItem.displayName = 'Stepper.Item';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LayoutDiagram = React.forwardRef(function LayoutDiagram(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M7.5.75A.75.75 0 018.25 0h7.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75h-3v3H18a2.25 2.25 0 012.25 2.25V15h3a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75v-7.5a.75.75 0 01.75-.75h3v-.75a.75.75 0 00-.75-.75H6a.75.75 0 00-.75.75V15h3a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75v-7.5A.75.75 0 01.75 15h3v-.75A2.25 2.25 0 016 12h5.25V9h-3a.75.75 0 01-.75-.75V.75zM15 7.5v-6H9v6h6zm-13.5 9h6v6h-6v-6zm15 6v-6h6v6h-6z"
        fillRule="evenodd"
      />
    </svg>
  );
});

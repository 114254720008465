import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { BaseModalProps } from './types';

/**
 * ModalInset
 *
 * @memberof modal
 * @author Jeremy Thomas
 */
export const ModalInset = React.forwardRef<HTMLDivElement, BaseModalProps>(
  ({ children, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    return (
      <div className={cn('toast-inset')} ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

ModalInset.displayName = 'Modal.Inset';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Toast, ToastProps } from '../elements/toast/Toast';
import { ToastContainer } from '../elements/toast/ToastContainer';
import { PortalRoots, usePortalRoot } from '../hooks';
import { Action } from '../utility/types';

/**
 * TODO: Explore which properties we want to configure
 * TODO: Add transistion as a property
 * TODO: Revisit placements
 * TODO: Add banners, modals and dialogs
 */
export interface INotificationDependencies {
  autoDismiss?: boolean;
  autoDismissTimeout?: number;
  placement?: 'top-right';
}

export type ToastActions = {
  addToast: (content: React.ReactElement, options: ToastProps) => void;
  removeToast: Action;
  clearToasts: Action;
};

export type ModalActions = {
  showModal?: Action;
};

export type NotificationActions = ToastActions & ModalActions;

export const NotificationContext = React.createContext<NotificationActions | undefined>(undefined);

/**
 * NotificationContextProvider
 * use the notification context provider to enable notification in the application
 * @example
 *  <AppContextProvider>
 *     <NotificationContextProvider>
 *      app....
 *     <NotificationContextProvider>
 *  <AppContextProvider>
 *
 * @returns Notification Context
 * @memberof context
 * @author Simon Groenborb
 */
export const NotificationContextProvider: React.FunctionComponent<INotificationDependencies> = ({
  children,
  autoDismiss = true,
  autoDismissTimeout = 3000,
}) => {
  const [toasts, setToasts] = React.useState<React.ReactElement[]>([]);
  const [tobeCleaned, scheduleClean] = React.useState<string>();
  const { portalRoot } = usePortalRoot(PortalRoots.TOAST);

  React.useEffect(() => {
    setToasts((prevToasts) => {
      return prevToasts.filter((toast) => toast.key !== tobeCleaned);
    });
  }, [tobeCleaned]);

  const addToast = (content: React.ReactNode, options: ToastProps): void => {
    const toast = (
      <Toast key={Math.random()} status={options.status}>
        {content}
      </Toast>
    );

    setToasts((prevToasts) => [...prevToasts, toast]);

    // TODO: the timeout must also be cleared otherwise they will continue to run even if the component is removed
    if (autoDismiss) {
      setTimeout(() => {
        if (toast.key) {
          scheduleClean(toast.key.toString());
        }
      }, autoDismissTimeout);
    }
  };

  const removeToast = (): void => {
    setToasts((prev) => {
      return prev.filter((_, i) => i);
    });
  };

  const clearToasts = (): void => {
    setToasts([]);
  };

  return (
    <NotificationContext.Provider value={{ addToast, clearToasts, removeToast }}>
      {children}
      {!!toasts.length &&
        ReactDOM.createPortal(<ToastContainer>{toasts}</ToastContainer>, portalRoot)}
    </NotificationContext.Provider>
  );
};

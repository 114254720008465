/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LayoutGridView = React.forwardRef(function LayoutGridView(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M0 .75A.75.75 0 01.75 0h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9A.75.75 0 010 9.75v-9zm1.5.75V9H9V1.5H1.5zM0 14.25a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9zm1.5.75v7.5H9V15H1.5zM13.5.75a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9zm1.5.75V9h7.5V1.5H15zm-1.5 12.75a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9zM15 15v7.5h7.5V15H15z"
        fillRule="evenodd"
      />
    </svg>
  );
});

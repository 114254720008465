/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Minus = React.forwardRef(function Minus(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M23.913 12a.75.75 0 01-.75.75H.837a.75.75 0 010-1.5h22.326a.75.75 0 01.75.75z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * MenuIcons
 * React MenuIcons component.
 *
 *
 * @returns MenuIcons Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuIcons: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('menu-icons')}>{children}</div>;
};

MenuIcons.displayName = 'Menu.Icons';

import * as React from 'react';
import { InputTypes, Sizes, Status } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type TextFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  isDisabled?: boolean;
  isFlexible?: boolean;
  isRequired?: boolean;
  validationStatus?: Status;
  size?: Sizes;
  type?: InputTypes;
};

/**
 * TextField
 * TODO: make a clear API for the interactions e.g. onPress
 * TODO: rename sizing to size
 */
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(function Input(
  {
    size = 'medium',
    isDisabled = false,
    isFlexible = false,
    isRequired = false,
    validationStatus = '',
    type = 'text',
    ...props
  },
  ref
) {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <input
      className={cn('input', {
        [`is-${size}`]: size,
        ['is-flexible']: isFlexible,
        [`status-${validationStatus}`]: validationStatus,
        [`is-disabled`]: isDisabled,
      })}
      ref={ref}
      required={isRequired}
      type={type}
      {...props}
    />
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Dashboard = React.forwardRef(function Dashboard(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M11 12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h7a2 2 0 012 2v10zm-2 .5H2a.5.5 0 01-.5-.5V2a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v10a.5.5 0 01-.5.5zm4-.5a2 2 0 012-2h7a2 2 0 012 2v10a2 2 0 01-2 2h-7a2 2 0 01-2-2V12zm2-.5h7a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5V12a.5.5 0 01.5-.5zM15 0a2 2 0 00-2 2v4a2 2 0 002 2h7a2 2 0 002-2V2a2 2 0 00-2-2h-7zm7 1.5h-7a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h7a.5.5 0 00.5-.5V2a.5.5 0 00-.5-.5zM11 22a2 2 0 01-2 2H2a2 2 0 01-2-2v-4a2 2 0 012-2h7a2 2 0 012 2v4zm-2 .5H2a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Date = React.forwardRef(function Date(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.427 3.813h-3.552V1.707A.713.713 0 0017.168 1a.713.713 0 00-.707.707v2.106H8.039V1.707A.713.713 0 007.332 1a.713.713 0 00-.707.707v2.106H3.073A2.078 2.078 0 001 5.886v15.557c0 1.14.932 2.073 2.073 2.073h18.354a2.078 2.078 0 002.073-2.073V5.886a2.078 2.078 0 00-2.073-2.074zM3.073 5.21h3.552v1.414c0 .386.321.707.707.707a.713.713 0 00.707-.707V5.211h8.438v1.414c0 .386.321.707.707.707a.713.713 0 00.707-.707V5.211h3.552c.37 0 .659.305.659.659v3.55H2.414V5.886c0-.37.29-.675.66-.675zm18.354 16.875H3.073a.662.662 0 01-.659-.66v-10.59h19.688v10.59a.676.676 0 01-.675.66z" />
      <path d="M6.271 15.063a1.06 1.06 0 100-2.122 1.06 1.06 0 000 2.121zm.049 2.812h-.097a1.066 1.066 0 00-.996 1.045c0 .578.466 1.06 1.06 1.06a1.05 1.05 0 001.061-1.06 1.084 1.084 0 00-1.028-1.045zm5.93-2.812a1.06 1.06 0 100-2.122 1.06 1.06 0 000 2.121zm.048 2.812h-.112a1.066 1.066 0 00-.997 1.045 1.06 1.06 0 102.122 0c0-.563-.45-1.013-1.013-1.045zm5.995-4.918h-.097a1.058 1.058 0 00-1.012 1.045c0 .578.466 1.06 1.06 1.06a1.05 1.05 0 001.061-1.06 1.081 1.081 0 00-1.012-1.045zm-.064 4.918h-.017c-.578 0-1.044.482-1.044 1.06 0 .58.466 1.061 1.06 1.061a1.05 1.05 0 001.061-1.06 1.07 1.07 0 00-1.06-1.061z" />
    </svg>
  );
});

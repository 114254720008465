import { ArrowRightBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { FontSizeMap } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';

type SizeCompatibility = keyof Pick<FontSizeMap, 'small' | 'normal' | 'medium'>;

export type BreadcrumbSeparatorProps = React.HTMLAttributes<HTMLOListElement> & {
  size?: SizeCompatibility;
};

export const BreadcrumbSeparator = React.forwardRef<HTMLOListElement, BreadcrumbSeparatorProps>(
  function BreadcrumbSeparator({ ...props }, ref) {
    const cn = useFrameworkClassnamePrefixer();

    const className = cn('icon', 'breadcrumb-separator');

    return (
      <span className={className} ref={ref} role="presentation" {...props}>
        <ArrowRightBold />
      </span>
    );
  }
);

BreadcrumbSeparator.displayName = 'Breadcrumb.Separator';

import { CrossBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { Sizes } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { Polymorph } from '../polymorph';

export type TagProps = React.HTMLAttributes<HTMLSpanElement> & {
  size?: Sizes;
  label?: string;
  isDisabled?: boolean;
  isInteractive?: boolean;
  showDelete?: boolean;
  onDelete?: () => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

/**
 * Tag
 * use the tag component to associate metadata to any other given component, text, page etc.
 *
 * @param size of the tag
 * @param text of the tag
 * @param isDisabled disables the tag
 * @param isInteractive makes the tag clickable
 * @param showDelete shows the delete button
 * @param onDelete onDelete callback handler
 * @returns Tag Component
 * @memberof tag
 * @author Simon Groenborg
 */
export const Tag = React.forwardRef<HTMLSpanElement, TagProps>(
  (
    { size = 'medium', label, isDisabled, showDelete, isInteractive, onDelete, onClick, ...props },
    ref
  ) => {
    const cn = useFrameworkClassnamePrefixer();

    return (
      <span
        className={cn('tag', `is-${size}`, {
          'is-disabled': isDisabled,
        })}
        ref={ref}
        {...props}
      >
        <Polymorph
          as={isInteractive ? 'button' : 'span'}
          className={cn('tag-item')}
          onClick={isInteractive ? onClick : undefined}
        >
          {label}
        </Polymorph>
        {showDelete && (
          <button className={cn('tag-item', 'is-icon')} onClick={onDelete}>
            <Icon as="span" icon={<CrossBold />} />
          </button>
        )}
      </span>
    );
  }
);

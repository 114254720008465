/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LevelIndicator = React.forwardRef(function LevelIndicator(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12 3.771a11.961 11.961 0 00-8.52 3.6A12.054 12.054 0 000 15.943v2.074a2.253 2.253 0 002.246 2.245h19.508A2.253 2.253 0 0024 18.017v-2.074c.05-6.653-5.332-12.12-12-12.173zm-.017 12.754h.034a2.25 2.25 0 012.229 2.246H9.754a2.25 2.25 0 012.229-2.246zm10.525 1.492a.761.761 0 01-.754.754h-6c0-1.714-1.166-3.171-2.76-3.617l1.972-5.897a.736.736 0 00-.48-.943.736.736 0 00-.943.48l-2.092 6.274a3.761 3.761 0 00-3.188 3.703h-6a.761.761 0 01-.754-.754v-2.074A10.535 10.535 0 014.56 8.434 10.389 10.389 0 0112 5.28c5.828.034 10.543 4.817 10.508 10.663v2.074z" />
      <path d="M4.869 16.525a1.131 1.131 0 100-2.262 1.131 1.131 0 000 2.262zm1.508-6a1.13 1.13 0 100 2.263 1.12 1.12 0 001.132-1.131 1.13 1.13 0 00-1.132-1.132zm12.754 6a1.131 1.131 0 100-2.263 1.131 1.131 0 000 2.263zm-.377-4.868c0-.6-.48-1.097-1.08-1.114h-.12a1.138 1.138 0 00-1.063 1.114 1.13 1.13 0 102.263 0zM9.429 7.526c-.018 0-.018 0 0 0h-.12A1.138 1.138 0 008.246 8.64a1.13 1.13 0 001.131 1.13 1.12 1.12 0 001.131-1.131c0-.6-.497-1.097-1.08-1.114z" />
    </svg>
  );
});

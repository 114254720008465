/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LevelIndicatorBold = React.forwardRef(function LevelIndicatorBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12 4C8.8 4 5.7 5.3 3.5 7.6 1.2 9.9 0 12.9 0 16.2v2.1c0 1.2 1 2.2 2.2 2.2h5.6c.5 0 .4-1 .4-1.5 0-1.9 1.4-3.4 3.2-3.7L13.5 9c.1-.4.5-.6.9-.5.4.1.6.5.5.9l-2 5.9c1.4.4 2.4 1.5 2.7 2.9 0 .2.1.5.1.7 0 .5-.3 1.5.5 1.5h5.5c1.2 0 2.2-1 2.2-2.2v-2.1C24.1 9.5 18.7 4.1 12 4zm7 7.9c0 .8-.6 1.4-1.4 1.4-.8 0-1.4-.6-1.4-1.4 0-.7.6-1.3 1.3-1.4h.1c.8 0 1.4.7 1.4 1.4zm.1 5.1c-.8 0-1.4-.6-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4zM7 13.1c-.2.1-.4.2-.6.2-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.2 0 .4.1.6.2.4.2.8.7.8 1.2s-.3 1-.8 1.2zm-2.1 1.1c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4-.8 0-1.4-.6-1.4-1.4 0-.7.6-1.4 1.4-1.4zm4.5-4c-.8 0-1.4-.6-1.4-1.3 0-.2.1-.4.2-.6.2-.4.6-.7 1.1-.7h.1c.5 0 .9.3 1.2.7.1.2.2.4.2.6 0 .7-.7 1.3-1.4 1.3z" />
    </svg>
  );
});

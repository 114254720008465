/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ProfitAndLoss = React.forwardRef(function ProfitAndLoss(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20 1.25a.75.75 0 01.743.648L20.75 2v7a.75.75 0 01-1.493.102L19.25 9V2a.75.75 0 01.75-.75z" />
      <path d="M19.47 1.47a.75.75 0 01.976-.073l.084.073 3 3a.75.75 0 01-.976 1.133l-.084-.073L20 3.061l-2.47 2.47a.75.75 0 01-.976.072l-.084-.073a.75.75 0 01-.073-.976l.073-.084 3-3zM4 22.75a.75.75 0 01-.743-.648L3.25 22v-7a.75.75 0 011.493-.102L4.75 15v7a.75.75 0 01-.75.75z" />
      <path d="M4.53 22.53a.75.75 0 01-.976.073l-.084-.073-3-3a.75.75 0 01.976-1.133l.084.073L4 20.939l2.47-2.47a.75.75 0 01.976-.072l.084.073a.75.75 0 01.073.976l-.073.084-3 3zM13.5 8.25a.75.75 0 01.102 1.493l-.102.007h-2.033a.592.592 0 00-.305 1.1l.083.04 2.065.826a2.092 2.092 0 01-.613 4.028l-.166.006H10.5a.75.75 0 01-.102-1.493l.102-.007h2.032a.592.592 0 00.304-1.1l-.083-.042-2.066-.825a2.092 2.092 0 01.614-4.026l.166-.007H13.5z" />
      <path d="M12 7.5a.75.75 0 01.743.648l.007.102V9a.75.75 0 01-1.493.102L11.25 9v-.75A.75.75 0 0112 7.5zm0 6.75a.75.75 0 01.743.648l.007.102v.75a.75.75 0 01-1.493.102l-.007-.102V15a.75.75 0 01.75-.75z" />
      <path d="M12 5.25a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zm0 1.5a5.25 5.25 0 110 10.5 5.25 5.25 0 010-10.5z" />
    </svg>
  );
});

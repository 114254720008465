import { Placement } from '@popperjs/core';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PortalRoots, useClickOutside, usePortalRoot } from '../../hooks';
import { Nullable } from '../../utility/types';
import { useTooltipControl } from '../tooltip';
import { PopoverBody, PopoverBodyProps } from './PopoverBody';

export type PopoverProps = PopoverBodyProps & {
  popoverPosition?: Placement;
};

/**
 * Popover
 * TODO: The portal is still rendered in the component, maybe create a hook
 * utility to provide a rendering scope
 *
 * @param tooltipPosition Position of the tooltip relative to the element
 * @returns Tooltip Component
 * @memberof tooltip
 * @author Simon Groenborg
 */
export const Popover: React.FunctionComponent<PopoverProps> = ({
  children,
  content,
  title,
  popoverPosition = 'bottom',
}) => {
  // eslint-disable-next-line no-null/no-null
  const [referenceElement, setReferenceElement] = React.useState<Nullable<HTMLElement>>(null);
  // eslint-disable-next-line no-null/no-null
  const [popperElement, setPopperElement] = React.useState<Nullable<HTMLElement>>(null);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const { portalRoot } = usePortalRoot(PortalRoots.POPOVER);

  const { styles, attributes, position } = useTooltipControl(
    referenceElement,
    popperElement,
    popoverPosition
  );

  const renderPopover = (): JSX.Element | undefined => {
    if (isVisible) {
      return ReactDOM.createPortal(
        <PopoverBody
          aria-label={title}
          // eslint-disable-next-line react/jsx-no-bind
          onExit={() => setIsVisible(false)}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          content={content}
          position={position}
          title={title}
        />,
        portalRoot
      );
    }

    return undefined;
  };

  useClickOutside(
    [{ current: popperElement }, { current: referenceElement }] as React.RefObject<
      HTMLDivElement
    >[],
    () => {
      setIsVisible(false);
    }
  );

  return (
    <React.Fragment>
      {React.isValidElement(children) &&
        React.cloneElement<HTMLDivElement>(children, {
          onClick: () => setIsVisible(true),
          ref: (node: HTMLElement) => setReferenceElement(node),
          ...children.props,
        })}
      {renderPopover()}
    </React.Fragment>
  );
};

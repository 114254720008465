import { ArrowLeftBold, ArrowRightBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { PaginationButton } from './PaginationButton';
import { PaginationItem } from './PaginationItem';
import { PaginationResult } from './PaginationResult';
import { IPaginationProps, usePagination } from './usePagination';

/**
 * Pagination
 * this component is used for pagination though multiple pages of large datasets in either tables or
 * sites.
 *
 * TODO: Add disbled state to navigation buttons
 * TODO: Add focus outline/state when targeted through tabindex
 * TODO: Add a11y controls
 * TODO: Add pagination when total pages is unknown
 *
 * @param nextLabel label on next button
 * @param prevLabel label on prev button
 * @param headLabel label on first button
 * @param tailLabel label on last
 * @param showLabel display the text labels of the pagination controls
 * @param showHeadTail show the First and Last button
 * @param showPageSizeSelector Show the PageSizeSelector
 * @param isCentered center the pagination
 * @param isStacked stack the pagination and PageSizeSelector
 * @param page the current selected page / default startpage
 * @param totalPages the total amount of pages
 * @param pageSizeOptions a new set of Options For the PageSizeSelector
 * @param onPageClick callback for clicking on a page element
 * @param onSizeChange callbackEvent for selecting a new size
 * @returns Pagination Component
 * @memberof elements
 * @author Simon Groenborg
 */
export const Pagination: React.FunctionComponent<IPaginationProps> = ({
  isCentered = false,
  isStacked = false,
  showFirstLast,
  showPageSizeSelector,
  onSizeChange,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const { getPaginationItems, getNavigation } = usePagination({ ...props });
  // eslint-disable-next-line no-null/no-null
  const sharedRef = React.useRef<HTMLButtonElement>(null);

  const onFocus = () => {
    sharedRef.current?.focus();
  };

  return (
    <nav
      aria-label="pagination navigation"
      className={cn('pagination', {
        'is-centered': isCentered,
        'is-stacked': isStacked,
      })}
      // eslint-disable-next-line react/jsx-no-bind
      onFocus={onFocus}
      tabIndex={0}
    >
      <ol className={cn('pagination-list')}>
        {showFirstLast && (
          <PaginationButton icon={<ArrowLeftBold />} {...getNavigation().first()} />
        )}

        <PaginationButton icon={<ArrowLeftBold />} {...getNavigation().prev()} />

        {getPaginationItems().map(({ key, ...item }) => (
          <PaginationItem key={key} {...item} />
        ))}

        <PaginationButton icon={<ArrowRightBold />} {...getNavigation().next()} />

        {showFirstLast && (
          <PaginationButton icon={<ArrowRightBold />} {...getNavigation().last()} />
        )}
      </ol>
      {showPageSizeSelector && <PaginationResult onSizeChange={onSizeChange} />}
    </nav>
  );
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Target = React.forwardRef(function Target(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M19.735.61a.75.75 0 00-1.262-.395l-3.248 3.192a.75.75 0 00-.216.423l-.53 3.506-3.993 3.993a.75.75 0 101.06 1.06l3.993-3.992 3.506-.532a.75.75 0 00.423-.215l3.192-3.248a.75.75 0 00-.394-1.263l-2.124-.406-.407-2.124zm-3.658 6.188l.378-2.496 2.07-2.035.24 1.248a.75.75 0 00.595.596l1.248.238-2.035 2.07-2.496.379z"
        fillRule="evenodd"
      />
      <path d="M12.538 3.046A9 9 0 006.68 19.518l.01.002.008.002a.746.746 0 01.4.255A8.999 8.999 0 0020.33 9.704a.75.75 0 111.45-.382 10.5 10.5 0 01-3.938 11.141l.651 2.605a.75.75 0 11-1.455.364l-.533-2.134a10.5 10.5 0 01-9.51.125l-.502 2.009a.75.75 0 11-1.455-.364l.61-2.437a10.492 10.492 0 01-4.245-6.24A10.5 10.5 0 0112.69 1.554a.75.75 0 11-.152 1.492z" />
      <path d="M11.622 7.5a.75.75 0 000-1.5 6.001 6.001 0 105.828 4.556.75.75 0 10-1.456.362A4.501 4.501 0 1111.622 7.5z" />
    </svg>
  );
});

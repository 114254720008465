import * as React from 'react';
import { forwardRefAs } from '../polymorph';
import { Typography, TypographyProps } from './Typography';

/**
 * Heading
 * TODO: Heading could be removed and a clean typography component could be used instead
 * @see Typography.tsx
 */
export const Heading = forwardRefAs<TypographyProps>(({ as = 'h1', ...props }, ref) => {
  return <Typography as={as} {...props} ref={ref} />;
});

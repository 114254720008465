import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardItem
 * @param param0 props
 */
export const CardItem: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-item')}>{children}</div>;
};

CardItem.displayName = 'Card.Item';

import * as React from 'react';
import { Pair } from '../../utility/types';
import { PaginationAction, PaginationButtonProps } from './PaginationButton';
import { PaginationItemProps } from './PaginationItem';

export interface IPaginationProps {
  nextLabel?: string;
  prevLabel?: string;
  firstLabel?: string;
  lastLabel?: string;
  showLabel?: boolean;
  showFirstLast?: boolean;
  showPageSizeSelector?: boolean;
  isCentered?: boolean;
  isStacked?: boolean;
  page: number;
  totalPages: number;
  pageSizeOptions?: Pair<number>[];
  onPageClick?: (page: number) => void;
  onSizeChange?: (size: number) => void;
}

export type PaginationActions = {
  getNavigation: () => NavigationButtons;
  getPaginationItems: () => PaginationItemProps[];
};

type NavigationButtons = {
  next: () => PaginationButtonProps;
  prev: () => PaginationButtonProps;
  first: () => PaginationButtonProps;
  last: () => PaginationButtonProps;
};

type HooksProps = IPaginationProps & {
  page: number;
  totalPages: number;
};

type Hook = (props: HooksProps) => PaginationActions;

const itemsAmout = 7;
const startEndAmount = 4;

/**
 * usePagination
 * @returns PaginationActions
 * @memberof pagination
 * @author Simon Groenborg
 */
export const usePagination: Hook = ({
  page,
  totalPages,
  nextLabel = 'Next',
  prevLabel = 'Previous',
  lastLabel = 'Last',
  firstLabel = 'First',
  showLabel = true,
  onPageClick,
}): PaginationActions => {
  const [currentPage, setCurrentPage] = React.useState<number>(page);

  const onClick = (clickedPage: number): void => {
    setCurrentPage(clickedPage);
  };

  const onNavigationClick = (action: PaginationAction): void => {
    switch (action) {
      case PaginationAction.First:
        setCurrentPage(1);
        break;
      case PaginationAction.Last:
        setCurrentPage(totalPages);
        break;
      case PaginationAction.Next:
        setCurrentPage((prev) => (prev !== totalPages ? ++prev : prev));
        break;
      case PaginationAction.Prev:
        setCurrentPage((prev) => (prev !== 1 ? --prev : prev));
        break;
    }
  };

  React.useLayoutEffect(() => {
    if (onPageClick) {
      onPageClick(currentPage);
    }
  }, [currentPage]);

  const fullLayout = (): PaginationItemProps[] => {
    let items: PaginationItemProps[] = [];

    for (let index = 1; index <= totalPages; index++) {
      items = [...items, { key: index, onClick, page: index, isSelected: index === page }];
    }

    return items;
  };

  const edgeLayout = (): PaginationItemProps[] => {
    let items: PaginationItemProps[] = [];

    if (page > totalPages - startEndAmount) {
      items = [...items, { key: 1, onClick, page: 1 }];
      items = [...items, { isEllipsis: true, key: Math.random(), page: 0 }];
    }

    for (let index = 1; index <= 5; index++) {
      const itemPage = page <= 4 ? index : totalPages - 5 + index;
      const isSelected = itemPage === page;

      items = [...items, { key: itemPage, onClick, page: itemPage, isSelected }];
    }

    if (page <= startEndAmount) {
      items = [...items, { isEllipsis: true, key: Math.random(), page: 0 }];
      items = [...items, { key: totalPages, onClick, page: totalPages }];
    }

    return items;
  };

  const middleLayout = (): PaginationItemProps[] => {
    let items: PaginationItemProps[] = [];
    items = [...items, { key: 1, onClick, page: 1 }];
    items = [...items, { isEllipsis: true, key: Math.random(), page: 0 }];

    for (let index = page - 1; index < page + 2; index++) {
      items = [...items, { key: index, onClick, page: index, isSelected: index === page }];
    }

    items = [...items, { isEllipsis: true, key: Math.random(), page: 0 }];
    items = [...items, { key: totalPages, onClick, page: totalPages }];

    return items;
  };

  const getPaginationItems = React.useCallback((): PaginationItemProps[] => {
    if (totalPages <= itemsAmout) {
      return fullLayout();
    }

    if (page > startEndAmount && page <= totalPages - startEndAmount) {
      return middleLayout();
    }

    return edgeLayout();
  }, [page, totalPages]);

  const getNavigation = (): NavigationButtons => {
    return {
      prev: () => ({
        ariaLabel: prevLabel,
        label: showLabel ? prevLabel : '',
        navAction: PaginationAction.Prev,
        onClick: onNavigationClick,
      }),
      next: () => ({
        ariaLabel: nextLabel,
        label: showLabel ? nextLabel : '',
        iconPosition: 'right',
        navAction: PaginationAction.Next,
        onClick: onNavigationClick,
      }),
      first: () => ({
        ariaLabel: firstLabel,
        label: showLabel ? firstLabel : '',
        navAction: PaginationAction.First,
        onClick: onNavigationClick,
      }),
      last: () => ({
        ariaLabel: lastLabel,
        label: showLabel ? lastLabel : '',
        iconPosition: 'right',
        navAction: PaginationAction.Last,
        onClick: onNavigationClick,
      }),
    } as NavigationButtons;
  };

  return {
    getPaginationItems,
    getNavigation,
  };
};

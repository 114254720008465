/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AdvertisingMegaphone = React.forwardRef(function AdvertisingMegaphone(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#advertising-megaphone_svg__advertising-megaphone_svg__clip0)">
        <path d="M11.808.371a1.678 1.678 0 011.98-.092l.127.092 2.063 1.665 2.647-.131a1.678 1.678 0 011.659 1.097l.044.14.691 2.558 2.22 1.449c.617.403.894 1.155.7 1.853l-.049.149-.944 2.476.944 2.477a1.68 1.68 0 01-.525 1.912l-.127.092-2.219 1.444-.695 2.557a1.677 1.677 0 01-1.556 1.24h-.147l-2.648-.133-2.063 1.662a.75.75 0 01-.978-.032l-.076-.082a.75.75 0 01.033-.978l.08-.076 2.287-1.841a.75.75 0 01.398-.163l.11-.002 2.932.146a.177.177 0 00.161-.087l.02-.046.77-2.835a.75.75 0 01.228-.365l.087-.067 2.457-1.599a.18.18 0 00.08-.165l-.01-.048-1.046-2.743a.75.75 0 01-.031-.429l.031-.105 1.045-2.744a.178.178 0 00-.032-.18l-.037-.032L19.961 6.8a.75.75 0 01-.277-.329l-.037-.103-.767-2.834a.178.178 0 00-.132-.127l-.049-.004-2.932.146a.75.75 0 01-.418-.103l-.09-.062-2.286-1.845a.178.178 0 00-.17-.03l-.053.03-2.286 1.845a.75.75 0 01-.399.163l-.11.002L7.024 3.4a.178.178 0 00-.162.086l-.019.045-.767 2.834a.75.75 0 01-.227.365l-.087.067-2.457 1.605a.18.18 0 00-.08.164l.01.048 1.046 2.744a.75.75 0 01.031.43l-.031.104-.448 1.175A.75.75 0 012.4 12.63l.03-.097.346-.908-.944-2.478a1.68 1.68 0 01.524-1.91l.127-.091 2.22-1.449.691-2.558A1.678 1.678 0 016.952 1.9l.147.001 2.646.133L11.808.371z" />
        <path
          clipRule="evenodd"
          d="M.348 19.136a.75.75 0 01.724-.045l8.25-10.469-.047-.073a.75.75 0 011.2-.893l.066.09 5.963 9.393.011.018.32.504a.75.75 0 01-1.2.893l-.066-.09-.023-.035-5.19 1.623a3.013 3.013 0 01-1.09 3.448 2.79 2.79 0 01-3.86-.665l-.668-1.025-1.585.496a.75.75 0 01-1.314.563l-.066-.09-.32-.503-.011-.017-.994-1.566-.01-.018-.321-.504a.75.75 0 01.231-1.035zm4.498 1.068a.87.87 0 00-.027.008L2.4 20.97l-.4-.631 8.173-10.374 4.54 7.153-5.653 1.769a.653.653 0 00-.043.013l-4.171 1.305zm1.379 1.14l.347.535a1.291 1.291 0 001.828.396 1.51 1.51 0 00.526-1.775l-2.701.845z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="advertising-megaphone_svg__advertising-megaphone_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesAnalytics = React.forwardRef(function FilesAnalytics(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.35 6.59L15.47.71A2.25 2.25 0 0013.88 0H3.25A2.25 2.25 0 001 2.3v19.4A2.25 2.25 0 003.25 24h16.5A2.25 2.25 0 0022 21.75V8.18a2.25 2.25 0 00-.65-1.59zM14.5 1.86l5.69 5.69h-4.94a.75.75 0 01-.75-.75V1.86zm6 19.84a.75.75 0 01-.75.75H3.25a.75.75 0 01-.75-.75V2.3a.75.75 0 01.75-.75H13v5.26a2.25 2.25 0 002.25 2.25h5.25V21.7z" />
      <path d="M6.37 8.6a.64.64 0 01.64.64v5.58l1.8-1.68a2.33 2.33 0 013.13 0l.62.58a1 1 0 00.82.26 1 1 0 00.71-.43l1.34-2.09a.64.64 0 011.08.69l-1.34 2.1a2.261 2.261 0 01-1.64 1 2.32 2.32 0 01-1.83-.59l-.62-.58a1 1 0 00-1.4 0l-2.67 2.49v1.65h9.62a.64.64 0 110 1.28H6.37a.64.64 0 01-.64-.64V9.24a.64.64 0 01.64-.64z" />
    </svg>
  );
});

/* eslint-disable react/jsx-no-bind */
import { MenuNavigationVertical } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';

type BurgerMenuHookAction = { toggle: () => void };

/**
 * useBurgerMenu
 */
export const useBurgerMenu = (): BurgerMenuHookAction => {
  const cn = useFrameworkClassnamePrefixer();

  const toggle = () => {
    const navigation = document.getElementById('headerNav');
    const overlay = document.getElementById('headerNavOverlay');

    if (navigation && overlay) {
      navigation.classList.toggle(cn('is-open'));
      overlay.classList.toggle(cn('is-open'));
    }
  };

  return {
    toggle,
  };
};

type BurgerItemProps = {
  onBurgerClickHandler?: () => void;
};

/**
 * HeaderBurgerItem
 * @param param0
 */

export const HeaderBurgerItem: React.FunctionComponent<BurgerItemProps> = ({
  onBurgerClickHandler,
}) => {
  const cn = useFrameworkClassnamePrefixer();
  const { toggle } = useBurgerMenu();

  const classes = cn('header-icon', 'is-burger');

  const expandMenuClickHandler = () => {
    toggle();

    if (onBurgerClickHandler) {
      onBurgerClickHandler();
    }
  };

  // TODO: the icon should be an <Icon as="button" icon={MenuNavigationVertical}/>
  return (
    <div className={classes}>
      <Icon as="button" icon={<MenuNavigationVertical />} onClick={expandMenuClickHandler} />
    </div>
  );
};

HeaderBurgerItem.displayName = 'Header.BurgerItem';

// /**
//  * Overlay
//  */
// export const Overlay: React.FunctionComponent = () => {
//   const cn = useFrameworkClassnamePrefixer();
//   const { toggle } = useBurgerMenu();

//   return <div className={cn('header-overlay')} id="headerNavOverlay" onClick={toggle} />;
// };

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const SupportChatBold = React.forwardRef(function SupportChatBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M10.986.044a9 9 0 019.838 8.002 3.969 3.969 0 012.926 3.829v2.875a3.969 3.969 0 01-2.881 3.817 3.969 3.969 0 01-3.963 3.745h-1.31a2.528 2.528 0 01-2.284 1.438h-1.437a2.531 2.531 0 010-5.063h1.438a2.531 2.531 0 012.282 1.438h1.311a1.782 1.782 0 001.75-1.452 1.813 1.813 0 01-1.406-1.767V9.72a1.813 1.813 0 011.358-1.755 6.813 6.813 0 00-13.466 0A1.812 1.812 0 016.5 9.72v7.187a1.812 1.812 0 01-1.813 1.813H3.97A3.969 3.969 0 010 14.75v-2.875a3.969 3.969 0 012.926-3.83 9 9 0 018.06-8.001z" />
      <path d="M13.656 9.944a1.094 1.094 0 012.188 0v1.257a1.094 1.094 0 11-2.188 0V9.944zm-5.464 4.493a1.094 1.094 0 011.545-.072 3.175 3.175 0 004.276 0 1.094 1.094 0 111.474 1.617 5.362 5.362 0 01-7.224 0 1.094 1.094 0 01-.071-1.545zM9 8.85c-.604 0-1.094.49-1.094 1.094v1.257a1.094 1.094 0 102.188 0V9.944c0-.604-.49-1.094-1.094-1.094z" />
    </svg>
  );
});

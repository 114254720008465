/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const HomeBold = React.forwardRef(function HomeBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M22.933 11.288l-9.55-9.56a2.48 2.48 0 00-3.51 0l-9.56 9.56a1.08 1.08 0 00.76 1.84h1.73v8.06a1.08 1.08 0 001.08 1.08h4.63a1.08 1.08 0 001.11-1.08v-5.63a.33.33 0 01.33-.33h3.37a.33.33 0 01.33.33v5.63a1.08 1.08 0 001.08 1.08h4.63a1.08 1.08 0 001.08-1.08v-8.06h1.76a1.08 1.08 0 00.73-1.84z" />
    </svg>
  );
});

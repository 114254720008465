/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ShareBold = React.forwardRef(function ShareBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M6 10h1.3a1.5 1.5 0 100-3H5.9A2.9 2.9 0 003 9.9v11.2A2.9 2.9 0 005.9 24h12.6a2.9 2.9 0 002.9-2.9V9.9A2.899 2.899 0 0018.5 7h-1.4a1.5 1.5 0 000 3h1.3v11H6V10z" />
      <path d="M11.19.43a1.4 1.4 0 012-.02l3.29 3.29a1.414 1.414 0 01-2 2l-.88-.88v5.8a1.41 1.41 0 01-2.82 0V4.81l-.88.88a1.414 1.414 0 01-2-2L11.19.43z" />
    </svg>
  );
});

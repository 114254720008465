/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const RetractBold = React.forwardRef(function RetractBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M23.56 3.06A1.5 1.5 0 0021.44.94L15.5 6.878V4a1.5 1.5 0 00-3 0v6.5a1.494 1.494 0 00.859 1.357c.194.092.412.143.641.143h6.5a1.5 1.5 0 000-3h-2.879l5.94-5.94zM10.5 21.728a1.5 1.5 0 01-1.5-1.5v-2.879l-5.94 5.94a1.5 1.5 0 11-2.12-2.122l5.939-5.939H4a1.5 1.5 0 010-3h6.5a1.494 1.494 0 011.374.896c.08.185.126.39.126.604v6.5a1.5 1.5 0 01-1.5 1.5z" />
    </svg>
  );
});

import * as React from 'react';
import { Mutable } from '../utility/types';

/**
 * useShareForwardRef Hook
 * Merge a forwarded ref and a useRef to expose the ref both internally and externally
 * @param forwardedRef forwardRef
 */
export const useShareForwardRef = <T>(forwardedRef?: React.Ref<T>): React.RefObject<T> => {
  // eslint-disable-next-line no-null/no-null
  const innerRef = React.useRef<T>(null);

  // Update the shared ref if the forwardedRef changes
  React.useEffect(() => {
    if (!forwardedRef) {
      return;
    }

    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
      return;
    } else {
      (forwardedRef as Mutable<React.RefObject<T>>).current = innerRef.current;
    }
  }, [forwardedRef]);

  return innerRef;
};

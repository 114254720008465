import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type LinearProgressProps = {
  hasLabel?: boolean;
  value?: number;
  max?: number;
};

export const LinearProgress = React.forwardRef<HTMLDivElement, LinearProgressProps>(
  function LinearProgress({ value, max = 100, hasLabel = false, ...props }, ref) {
    const cn = useFrameworkClassnamePrefixer();

    // eslint-disable-next-line no-null/no-null
    const innerRef = React.useRef<HTMLProgressElement>(null);

    const isIndeterminate = typeof value !== 'number';

    const accessibilityProps: Record<string, string | number | undefined> = {
      role: 'progressbar',
      'aria-valuemin': 0,
      'aria-valuemax': 100,
    };

    if (!isIndeterminate) {
      accessibilityProps['aria-valuenow'] = value;
    }

    /**
     * svg height, width and viewbox values cannot use the `var(prop)` method
     * nor can it be used in cx, cy
     */
    return (
      <div className={cn('progress')} {...accessibilityProps} ref={ref} {...props}>
        {hasLabel && <p className={cn('progress-value')}>{value}</p>}
        <progress className={cn('progress-bar')} max={max} ref={innerRef} value={value} />
      </div>
    );
  }
);

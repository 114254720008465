import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export interface ITagsProps {
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
}

/**
 * Tags
 * use the tags component to wrap tag components
 *
 * @returns Tags Component
 * @memberof tag
 * @author Jeremy Thomas
 */
export const Tags: React.FunctionComponent<ITagsProps> = ({ children, size = 'medium' }) => {
  const cn = useFrameworkClassnamePrefixer();

  const className = cn('tags', {
    [`are-${size}`]: size,
  });

  return <div className={className}>{children}</div>;
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Attach = React.forwardRef(function Attach(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M15.323 2.525c-.985.11-1.817.55-2.177.91l-8 8c-1.78 1.78-2.567 5.373 0 7.94 2.566 2.566 6.158 1.78 7.939 0l8-8a.75.75 0 011.06 1.06l-8 8c-2.22 2.22-6.771 3.289-10.06 0-3.29-3.29-2.22-7.841 0-10.06l8-8c.64-.64 1.807-1.2 3.072-1.34 1.296-.145 2.791.142 3.989 1.34 1.197 1.196 1.484 2.692 1.34 3.987-.141 1.265-.7 2.433-1.34 3.073l-8 8a3.07 3.07 0 01-1.722.824c-.755.098-1.61-.096-2.34-.824-.734-.735-.913-1.603-.811-2.356.097-.715.448-1.34.812-1.705l8-8a.75.75 0 111.06 1.06l-8 8c-.135.137-.333.462-.386.847-.047.348.023.73.387 1.093.369.37.748.441 1.085.397.374-.048.7-.242.854-.397l8-8c.36-.36.8-1.193.91-2.177.106-.955-.107-1.96-.91-2.762-.803-.803-1.807-1.016-2.762-.91z" />
    </svg>
  );
});

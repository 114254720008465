/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const SecurityBold = React.forwardRef(function SecurityBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#security-bold_svg__security-bold_svg__clip0)">
        <path d="M22.15 2.8a2.24 2.24 0 00-1-.83A22.45 22.45 0 0012 .09 22.45 22.45 0 002.82 2 2.24 2.24 0 001.5 4v7.61a12.66 12.66 0 008.11 11.83l1 .4a3.75 3.75 0 002.69 0l1-.4a12.66 12.66 0 008.2-11.82V4a2.24 2.24 0 00-.35-1.2zm-5 13.56a.7.7 0 01-.7.7H7.7a.7.7 0 01-.7-.7V9.23a.7.7 0 01.7-.7h.89V7.25a3.47 3.47 0 116.93 0v1.29h.89a.7.7 0 01.7.7l.04 7.12z" />
        <path d="M14.13 7.25a2.08 2.08 0 00-4.15 0v1.29h4.15V7.25zm-5.74 8.41h7.32V9.93H8.39v5.73zm3-3.66a.7.7 0 111.39 0v1.58a.7.7 0 11-1.39 0V12z" />
      </g>
      <defs>
        <clipPath id="security-bold_svg__security-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

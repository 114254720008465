/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Lock = React.forwardRef(function Lock(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12.75 14a.75.75 0 00-1.5 0v3a.75.75 0 001.5 0v-3z" />
      <path
        clipRule="evenodd"
        d="M6 6v2H3.75a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V8.75a.75.75 0 00-.75-.75H18V6A6 6 0 106 6zm6-4.5A4.5 4.5 0 007.5 6v2h9V6A4.5 4.5 0 0012 1.5zm-7.5 8h15v12h-15v-12z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ZoomOutBold = React.forwardRef(function ZoomOutBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M10 .5a9.5 9.5 0 100 19c2.082 0 4.008-.67 5.573-1.806l5.366 5.367a1.5 1.5 0 002.122-2.122l-5.367-5.366A9.458 9.458 0 0019.5 10 9.5 9.5 0 0010 .5zM3.5 10a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.833 10c0-.368.299-.666.667-.666h5a.666.666 0 110 1.332h-5A.666.666 0 016.833 10z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.583 10c0-.506.41-.916.917-.916h5a.916.916 0 110 1.832h-5A.916.916 0 016.583 10zm.917-.416a.416.416 0 100 .832h5a.417.417 0 000-.832h-5z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ViewHideBold = React.forwardRef(function ViewHideBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M2.967 19.978a1.365 1.365 0 00-.028 1.83 1.145 1.145 0 001.702.027l3.342-3.485c1.275.553 2.57.873 3.827.9h.31c3.597.065 7.577-2.369 10.525-5.858a2.54 2.54 0 00.002-3.219 19.969 19.969 0 00-3.61-3.35l2.973-3.1c.477-.498.49-1.318.028-1.83a1.145 1.145 0 00-1.702-.027L2.967 19.978zm12.253-9.175l-4.118 4.294a3.03 3.03 0 001.007.171c1.79 0 3.24-1.56 3.24-3.485 0-.34-.045-.669-.13-.98z"
        fillRule="evenodd"
      />
      <path d="M12.109 8.298c.304 0 .598.045.878.13l3.126-3.26c-1.231-.521-2.475-.822-3.677-.851l-.315-.001C8.529 4.25 4.527 6.677 1.576 10.17a2.54 2.54 0 00-.002 3.218 20.048 20.048 0 003.506 3.283l3.883-4.049a3.744 3.744 0 01-.095-.84c0-1.925 1.451-3.485 3.24-3.485z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DatabaseBold = React.forwardRef(function DatabaseBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.5 17.034a.25.25 0 00-.408-.194C19.155 18.417 15.5 19.25 12 19.25c-3.5 0-7.156-.833-9.093-2.41a.249.249 0 00-.407.194v1.716c0 2.21 4.254 4 9.5 4s9.5-1.79 9.5-4v-1.716z" />
      <path d="M2.5 14.25c0 2.21 4.254 4 9.5 4s9.5-1.79 9.5-4v-1.716a.25.25 0 00-.408-.194C19.155 13.917 15.5 14.75 12 14.75c-3.5 0-7.156-.833-9.093-2.41a.249.249 0 00-.407.194v1.716z" />
      <path d="M12 10.25c-3.5 0-7.156-.833-9.093-2.411a.249.249 0 00-.407.194V9.75c0 2.21 4.254 4 9.5 4s9.5-1.79 9.5-4V8.034a.25.25 0 00-.408-.193C19.154 9.418 15.5 10.25 12 10.25z" />
      <path d="M21.5 5.25c0 2.21-4.253 4-9.5 4s-9.5-1.79-9.5-4 4.253-4 9.5-4 9.5 1.79 9.5 4z" />
    </svg>
  );
});

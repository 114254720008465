/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AnnouncementBold = React.forwardRef(function AnnouncementBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20.604 2.589A.75.75 0 0121 3.25v16.5a.75.75 0 01-1.166.624l-1.134-.756a21.55 21.55 0 00-11.155-3.603 6.49 6.49 0 001.972 3.942.75.75 0 11-1.034 1.086A7.988 7.988 0 016.035 16V7h.716c4.253 0 8.41-1.26 11.95-3.618l1.133-.756a.75.75 0 01.77-.037zM1.318 8.318A4.5 4.5 0 014.5 7v9a4.5 4.5 0 01-3.182-7.682zm21.432.557c-.621 0-1.125.504-1.125 1.125v3a1.125 1.125 0 002.25 0v-3c0-.621-.504-1.125-1.125-1.125z" />
    </svg>
  );
});

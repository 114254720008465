import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { ITabProps } from './Tab';
import { FocusSelectionMode, useTabsContext } from './TabsContext';

export interface ITabListProps {
  children: React.ReactElement<ITabProps>[] | React.ReactElement<ITabProps>;
  size?: 'small';
}

/**
 * Based on an existing array return the original index identifier
 *
 * example: current `activeTab` is 1 and children returns an array of [0, 1, 2, 3, 4] where the
 * 3rd and 4th element are disabled, so you get back [0, 1, 4] and acgiveTab is still 1 but the
 * enabledSelectedIndex is for the enabledIndexes array and is also 1
 *
 * however in the case of where `activeTab` is 4 in the original array, now `enabledSelectedIndex`
 * should return `2` as the `activeTab` is the last elemnt in the enabledIndexes
 *
 * note that we are mixing index positions and index values in an array to keep track of this.
 *
 */
const getAvailableTabIndexes = (
  activeTab: number,
  children: React.ReactElement<ITabProps>[] | React.ReactElement<ITabProps>
) => {
  const enabledIndexes = React.Children.map(children, (child, index) => {
    return child.props.isDisabled === true ? undefined : index;
  });

  const enabledSelectedIndex = enabledIndexes.indexOf(activeTab);
  return { enabledSelectedIndex, enabledIndexes };
};

const getNextAvailableTab = (activeTabIndex: number, availableTabIndexes: number[]) => {
  return availableTabIndexes[(activeTabIndex + 1) % availableTabIndexes.length];
};

const getPrevAvailableTab = (activeTabIndex: number, availableTabIndexes: number[]) => {
  return availableTabIndexes[
    (activeTabIndex + availableTabIndexes.length - 1) % availableTabIndexes.length
  ];
};

const getFirstAvailableTab = (activeTabIndex: number, availableTabIndexes: number[]) => {
  return availableTabIndexes[0];
};

const getLastAvailableTab = (activeTabIndex: number, availableTabIndexes: number[]) => {
  return availableTabIndexes[availableTabIndexes.length - 1];
};

// TODO: Add a scrollview inside TabList and the hooks to scroll the tab into view when it is too far offset
// TODO: Investigate issue with focus where the focus gets set twice (non-react) when tabbing out.
export const TabList: React.FunctionComponent<ITabListProps> = ({ children, size }) => {
  const cn = useFrameworkClassnamePrefixer();

  const { activeTab, setActiveTab, focusedTab, setFocusedTab, selectionMode } = useTabsContext();

  const [hasFocus, setHasFocus] = React.useState(false);

  React.useEffect(
    () => {
      if (!hasFocus) {
        setFocusedTab(-1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasFocus, setFocusedTab]
  );

  const onFocus = () => {
    setHasFocus(true);
    setFocusedTab(activeTab);
  };

  // TODO: Think about this whole onKeyDown/OnFocus/hasFocus
  // in terms of a re-usable hook cause this is messy
  // we should also probably work with refs and prefer to use ref.current.focus(), but sadly we
  // have a design that seems to re-implement the default focus behavior
  // we need a broad discussion about focus traps and what consitutes the focus, because we are missing
  // focus behavior on the TabPanel component.
  // eslint-disable-next-line complexity
  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    const { enabledSelectedIndex, enabledIndexes } = getAvailableTabIndexes(focusedTab, children);

    let newIndex: number;

    if (hasFocus) {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowRight':
          e.preventDefault();

          newIndex = getNextAvailableTab(enabledSelectedIndex, enabledIndexes);

          if (selectionMode === FocusSelectionMode.AUTOMATIC) {
            setActiveTab(newIndex);
          }

          setFocusedTab(newIndex);

          return;
        case 'ArrowLeft':
        case 'ArrowUp':
          e.preventDefault();

          newIndex = getPrevAvailableTab(enabledSelectedIndex, enabledIndexes);

          if (selectionMode === FocusSelectionMode.AUTOMATIC) {
            setActiveTab(newIndex);
          }

          setFocusedTab(newIndex);
          return;
        case 'End':
          e.preventDefault();

          newIndex = getLastAvailableTab(enabledSelectedIndex, enabledIndexes);

          if (selectionMode === FocusSelectionMode.AUTOMATIC) {
            setActiveTab(newIndex);
          }

          setFocusedTab(newIndex);
          return;
        case 'Home':
          e.preventDefault();

          newIndex = getFirstAvailableTab(enabledSelectedIndex, enabledIndexes);

          if (selectionMode === FocusSelectionMode.AUTOMATIC) {
            setActiveTab(newIndex);
          }

          setFocusedTab(newIndex);
          return;
        case 'Enter':
        case ' ':
          e.preventDefault();
          setActiveTab(focusedTab);

          return;
      }
    }
  };

  const className = cn('tablist', {
    ...(size && { [`is-${size}`]: true }),
  });

  return (
    <ul className={className} role="tablist">
      {React.Children.map(children, (child: React.ReactElement<ITabProps>, index) => {
        return React.cloneElement(child, {
          index,
          onFocus,
          onBlur: () => {
            // all we're doing here is making sure that we know we're in a focused mode.
            setHasFocus(false);
          },
          onKeyDown,
          ...child.props,
        });
      })}
    </ul>
  );
};

TabList.displayName = 'Tabs.TabList';

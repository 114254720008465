/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowDown = React.forwardRef(function ArrowDown(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M.445 6.495a.75.75 0 011.06-.05L12 15.986l10.495-9.541a.75.75 0 011.01 1.11l-11 10a.75.75 0 01-1.01 0l-11-10a.75.75 0 01-.05-1.06z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const BarGraph = React.forwardRef(function BarGraph(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M9.44 1.94a1.5 1.5 0 011.06-.44h3A1.5 1.5 0 0115 3v18h1.5V7.5A1.5 1.5 0 0118 6h3a1.5 1.5 0 011.5 1.5V21h.75a.75.75 0 010 1.5H.75a.75.75 0 010-1.5h.75v-9A1.5 1.5 0 013 10.5h3A1.5 1.5 0 017.5 12v9H9V3c0-.398.158-.78.44-1.06zM21 21V7.5h-3V21h3zM10.5 3h3v18h-3V3zM6 21v-9H3v9h3z"
        fillRule="evenodd"
      />
    </svg>
  );
});

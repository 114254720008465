import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * ToastContainer
 *
 * @memberof toast
 * @author Simon Groenborg
 */
export const ToastContainer: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <div className={cn('toasts')}>{children}</div>;
};

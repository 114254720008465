/* eslint-disable react/jsx-no-bind */
import { ArrowDown } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';

export type Context = 'li' | 'div';

export type InternalElementProps = {
  behaviour?: 'dropdown' | 'link' | 'default';
  isActive?: boolean;
  label?: string;
  _context?: Context;
  children?: string | JSX.Element;
  icon?: React.ReactElement;
};

// GLOABL PROPS
export type ElementProps = Omit<InternalElementProps, '_context'>;

type HeaderItemStylingContext = {
  [key in Context]: {
    wrapper: string;
    element: string;
  };
};

const contextMap: HeaderItemStylingContext = {
  li: {
    wrapper: 'header-dropdown-item',
    element: 'header-dropdown-link',
  },
  div: {
    wrapper: 'header-nav-item',
    element: 'header-nav-item-trigger',
  },
};

/**
 * HeaderItem
 * TODO: Add dynamic typing to the ref and expose the ref
 */
export const HeaderItem: React.FunctionComponent<InternalElementProps> = ({
  children,
  isActive: active,
  label,
  behaviour = 'text',
  icon,
  _context = 'div',
}) => {
  const cn = useFrameworkClassnamePrefixer();
  const [isOpen, toggleIsOpen] = React.useState<boolean>(false);

  const Component = _context;

  const wrapperClasses = cn({
    [contextMap[_context].wrapper]: true,
    'is-active': active,
  });

  const elementClasses = contextMap[_context].element;

  if (icon) {
    const classes = cn('header-icon', {
      'is-active': active,
    });

    return (
      <Component className={classes}>
        <Icon as="button" icon={icon} />
      </Component>
    );
  }

  if (behaviour === 'dropdown') {
    const handleMenuExpand = () => {
      toggleIsOpen((prev) => {
        return !prev;
      });
    };

    const dropDownChild = (): JSX.Element | undefined => {
      if (React.isValidElement(children)) {
        return React.cloneElement(children, {
          isOpen,
          ...children.props,
        });
      }

      return undefined;
    };

    return (
      <Component className={wrapperClasses}>
        <button className={cn(elementClasses, { 'is-open': isOpen })} onClick={handleMenuExpand}>
          {label}
          <Icon as="span" className={cn({ 'is-open': isOpen })} icon={<ArrowDown />} />
        </button>
        {dropDownChild()}
      </Component>
    );
  }

  if (behaviour === 'link') {
    return <Component className={wrapperClasses}>{children}</Component>;
  }

  // FIX HREF ATTRIBUTE with a real path
  return (
    <Component className={wrapperClasses}>
      <a className={cn(elementClasses)} href="/">
        {children}
      </a>
    </Component>
  );
};

HeaderItem.displayName = 'Header.Item';

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardContent
 * @param param0
 */
export const CardContent: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-content')}>{children}</div>;
};

CardContent.displayName = 'Card.Content';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const StatusErrorBold = React.forwardRef(function StatusErrorBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm5.56-17.56a1.5 1.5 0 010 2.12L14.122 12l3.44 3.44a1.5 1.5 0 01-2.122 2.12L12 14.122l-3.44 3.44a1.5 1.5 0 01-2.12-2.122L9.878 12l-3.44-3.44A1.5 1.5 0 118.56 6.44L12 9.878l3.44-3.44a1.5 1.5 0 012.12 0z"
        fillRule="evenodd"
      />
    </svg>
  );
});

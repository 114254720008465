/* eslint-disable */
import {
  Control,
  FieldErrors,
  FormStateProxy,
  useFormContext,
  UseFormMethods,
} from 'react-hook-form';
import { State } from '../constants';

export interface IFormFieldProps {
  name?: string;
  errors?: FieldErrors<any>;
  warnings?: FieldErrors<any>;
  disableWhenSubmitting?: boolean;
}

export interface IUseFormFieldProps extends IFormFieldProps {
  register?: any;
  control?: Control;
  status?: State;
}

type UseFormFieldReturnType = {
  register: any;
  status?: State;
  helperText?: string;
  name: string;
  control: Control | undefined;
  fieldErrors: FieldErrors<any>;
  fieldWarnings: FieldErrors<any>;
  formState: FormStateProxy<any> | undefined;
};

/**
 * This hook takes in optional 'register' and 'errors' props (from 'react-hook-form' useForm),
 * and figures out if the they are given directly, or available in a FormProvider.
 * It also merges errors, warnings and status, and returns the correct status and helperText
 * based on those. The result can be passed to any of our custom input field components like TextInputField
 */
export const useFormField = ({
  name,
  warnings,
  register: registerProp,
  control: controlProp,
  errors: errorsProp,
  status: statusProp,
}: IUseFormFieldProps): UseFormFieldReturnType => {
  if (!name) {
    throw new Error(`useFormField: all form fields require the 'name' prop`);
  }

  const formContext = useFormContext() as UseFormMethods<Record<string, unknown>> | undefined;
  const errors = errorsProp ?? formContext?.errors;
  const register = registerProp ?? formContext?.register;
  const control = controlProp ?? formContext?.control;

  if (!((register || control) && errors)) {
    throw new Error(
      `useFormField '${name}': you need to pass ('register' or 'control') and 'errors' props from useForm, or alternatively wrap the form in a FormProvider`
    );
  }

  const fieldErrors = errors[name];
  const fieldWarnings = warnings?.[name];

  let status = statusProp;
  let helperText;

  if (fieldErrors) {
    status = 'error';
    helperText = fieldErrors.message;
  } else if (fieldWarnings) {
    status = 'warning';
    helperText = fieldWarnings.message;
  }

  return {
    register,
    status,
    helperText,
    name,
    control,
    fieldErrors,
    fieldWarnings,
    formState: formContext?.formState,
  };
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const MinusBold = React.forwardRef(function MinusBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M23.935 12a1.5 1.5 0 01-1.5 1.5H1.565a1.5 1.5 0 010-3h20.87a1.5 1.5 0 011.5 1.5z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Filter = React.forwardRef(function Filter(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M23.571 1.029a1.495 1.495 0 00-1.028-.515H1.49C1.097.514.72.67.446.96A1.436 1.436 0 000 2.023v.103c.377 5.143 4.029 9.48 8.966 10.765v9.275c0 .394.154.771.445 1.063.292.291.652.445 1.063.445.343 0 .686-.12.96-.343l3-2.502c.343-.292.549-.703.549-1.149v-6.806c4.954-1.285 8.606-5.623 8.966-10.765a1.47 1.47 0 00-.378-1.08zm-9.497 10.525a.754.754 0 00-.6.737v7.372l-3 2.503V12.29a.754.754 0 00-.6-.737c-4.594-.943-8.04-4.868-8.383-9.531H22.44c-.326 4.663-3.771 8.588-8.366 9.531z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { BaseModalProps, ModalElement } from './types';

/**
 * ModalAction
 *
 * @memberof modal
 * @author Simon Groenborg
 */
export const ModalAction = React.forwardRef<HTMLDivElement, BaseModalProps & ModalElement>(
  ({ children, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    return (
      <div className={cn('toast-actions')} {...props} ref={ref}>
        <div className={cn('buttons', 'is-end')}>{children}</div>
      </div>
    );
  }
);

ModalAction.displayName = 'Modal.Action';

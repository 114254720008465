/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Expand = React.forwardRef(function Expand(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.746 3.806v4.69a.75.75 0 001.5 0v-6.5a.747.747 0 00-.75-.75h-6.5a.75.75 0 000 1.5h4.69l-7.22 7.22a.75.75 0 101.06 1.06l7.22-7.22zM1.996 14.974a.75.75 0 01.75.75v4.689l7.22-7.22a.75.75 0 011.06 1.061l-7.22 7.22h4.69a.75.75 0 110 1.5h-6.5a.746.746 0 01-.679-.43.747.747 0 01-.071-.32v-6.5a.75.75 0 01.75-.75z" />
    </svg>
  );
});

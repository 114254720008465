/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const UpwardTrend = React.forwardRef(function UpwardTrend(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={25} ref={svgRef} viewBox="0 0 24 25" width={24} {...props}>
      <path d="M17.331.048a.75.75 0 00-.526 1.404l1.486.558-14.42 6.548a.75.75 0 10.621 1.366l14.42-6.548-.558 1.487a.75.75 0 001.404.527l1.194-3.182.002-.006a.747.747 0 00-.44-.96l-.003-.001L17.33.048z" />
      <path
        clipRule="evenodd"
        d="M18 9a1.5 1.5 0 00-1.5 1.5v12H15v-9a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 13.5v9H7.5V18A1.5 1.5 0 006 16.5H3A1.5 1.5 0 001.5 18v4.5H.75a.75.75 0 000 1.5h22.5a.75.75 0 000-1.5h-.75v-12A1.5 1.5 0 0021 9h-3zm3 1.5h-3v12h3v-12zm-10.5 3h3v9h-3v-9zm-4.5 9V18H3v4.5h3z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export interface IMenuBlocksProps {
  isOpen?: boolean;
}

/**
 * MenuBlocks
 * React MenuBlocks component.
 *
 *
 * @returns MenuBlocks Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuBlocks: React.FunctionComponent<IMenuBlocksProps> = ({ children, isOpen }) => {
  const cn = useFrameworkClassnamePrefixer();

  const dropDownClasses = cn('menu-blocks', {
    'is-open': isOpen,
  });

  return <div className={dropDownClasses}>{children}</div>;
};

MenuBlocks.displayName = 'Menu.Blocks';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AlertBold = React.forwardRef(function AlertBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20.229 10.491c0-4.032-2.907-7.387-6.73-8.103V1.5a1.5 1.5 0 10-3 0v.879c-3.838.703-6.762 4.073-6.762 8.112 0 5.88-1.337 7.783-1.337 7.783a.77.77 0 00-.086.789c.12.257.394.428.669.428h18c.343 0 .668-.188.754-.514.086-.308 0-.617-.274-.806-.137-.171-1.235-1.731-1.235-7.68zM9.25 21.78c0-.326.21-.629.539-.729l4.405-.017c.395.12.635.532.515.943 0 0-.209 2.023-2.709 2.023s-2.726-2.023-2.726-2.023a.77.77 0 01-.025-.196z" />
    </svg>
  );
});

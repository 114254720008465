/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ZoomIn = React.forwardRef(function ZoomIn(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M10.056 1a9.056 9.056 0 017.072 14.713l5.58 5.58a1 1 0 01-1.321 1.498l-.094-.084-5.58-5.58A9.056 9.056 0 1110.056 1zm0 2a7.056 7.056 0 100 14.112 7.056 7.056 0 000-14.112z" />
      <path
        clipRule="evenodd"
        d="M10 6.833c.368 0 .666.299.666.667v1.834H12.5a.666.666 0 110 1.332h-1.834V12.5a.666.666 0 11-1.332 0v-1.834H7.5a.666.666 0 110-1.332h1.834V7.5c0-.368.298-.667.666-.667z"
        fillRule="evenodd"
      />
    </svg>
  );
});

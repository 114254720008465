/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const PlusBold = React.forwardRef(function PlusBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M13.5 1.565a1.5 1.5 0 00-3 0V10.5H1.565a1.5 1.5 0 000 3H10.5v8.935a1.5 1.5 0 003 0V13.5h8.935a1.5 1.5 0 000-3H13.5V1.565z" />
    </svg>
  );
});

import { ModifierArguments } from '@popperjs/core';
import { Options } from '@popperjs/core/lib/modifiers/arrow';
import * as React from 'react';
import * as Popper from 'react-popper';

export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

// eslint-disable-next-line complexity
const getArrowPlacement = (placement: Placement): string => {
  switch (placement) {
    case 'bottom':
      return 'arrow-nn';
    case 'bottom-end':
      return 'arrow-nw';
    case 'bottom-start':
      return 'arrow-ne';
    case 'top':
      return 'arrow-ss';
    case 'top-end':
      return 'arrow-sw';
    case 'top-start':
      return 'arrow-se';
    case 'right':
      return 'arrow-ww';
    case 'right-end':
      return 'arrow-ws';
    case 'right-start':
      return 'arrow-wn';
    case 'left':
      return 'arrow-ee';
    case 'left-end':
      return 'arrow-es';
    case 'left-start':
      return 'arrow-en';
    default:
      return 'arrow-nn';
  }
};

export type TooltipControlHookAction = {
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } };
  position?: string;
};

/**
 * useTooltipControl
 *
 * Wraps propper in a hook, to abstract away the configuration and workings of popper
 * TODO: expose popper configuration as tooltip configuration
 * TODO: Based on an axample provided by vincint, we might want to write our own popper element
 *
 * TODO: Tooltips and Popovers are created with popper based on the assumption that we want
 * automatic placement of the elements
 *
 *
 * @returns popper controlss
 * @memberof hooks
 * @author Simon Groenborg
 */
export const useTooltipControl = (
  refElement: HTMLElement | null,
  popperElement: HTMLElement | null,
  popperPlacement?: Placement
): TooltipControlHookAction => {
  const [position, setPosition] = React.useState<string>('');

  const handlePosision = React.useCallback((options: ModifierArguments<Options>) => {
    const { state } = options;
    setPosition(getArrowPlacement(state.placement));
  }, []);

  const { styles, attributes } = Popper.usePopper(refElement, popperElement, {
    placement: popperPlacement ?? 'auto',
    modifiers: [
      {
        name: 'arrow',
        fn: handlePosision,
      },
      {
        name: 'offset',
        options: {
          offset: [2, 14],
        },
      },
    ],
  });

  return {
    styles,
    attributes,
    position,
  };
};

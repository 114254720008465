/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesPtt = React.forwardRef(function FilesPtt(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M1.72 1.712a.752.752 0 01.53-.218H12v5.228c0 .594.237 1.164.659 1.584.422.42.994.657 1.591.657h5.25v3.734a.749.749 0 001.5 0V8.088a2.233 2.233 0 00-.659-1.584L14.471.657A2.251 2.251 0 0012.879 0H2.25C1.653 0 1.081.236.659.656.237 1.076 0 1.646 0 2.241v19.418c0 .595.237 1.165.659 1.585.422.42.994.656 1.591.656h3c.414 0 .75-.334.75-.747a.748.748 0 00-.75-.747h-3a.751.751 0 01-.75-.747V2.241c0-.198.079-.388.22-.529zm11.78.091v4.919a.745.745 0 00.75.747h4.94L13.5 1.803z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.499 15.748a.75.75 0 01.75-.75H9a3 3 0 010 6h-.001v2.25a.75.75 0 01-1.5 0v-7.5zm1.5 3.75v-3H9a1.5 1.5 0 110 3h-.001zm5.25-4.5H15a3 3 0 010 6h-.001v2.25a.75.75 0 01-1.5 0v-7.5a.75.75 0 01.75-.75zm.75 1.5H15a1.5 1.5 0 110 3h-.001v-3z"
        fillRule="evenodd"
      />
      <path d="M23.249 16.498h-.75v6.75a.75.75 0 01-1.5 0v-6.75h-.75a.75.75 0 010-1.5h3a.75.75 0 010 1.5z" />
    </svg>
  );
});

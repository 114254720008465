/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const EmailUnread = React.forwardRef(function EmailUnread(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#email-unread_svg__email-unread_svg__clip0)">
        <path d="M21.6 4H2.4A2.4 2.4 0 000 6.4v12.8a2.4 2.4 0 002.4 2.4h19.2a2.4 2.4 0 002.4-2.4V6.4A2.4 2.4 0 0021.6 4zM2.4 5.6h19.2a.8.8 0 01.8.8v12.8a.8.8 0 01-.8.8H2.4a.8.8 0 01-.8-.8V6.4a.8.8 0 01.8-.8z" />
        <path d="M22.35 4.753a.8.8 0 011.065 1.189l-.089.079-8.687 6.681a4.329 4.329 0 01-5.089.138l-.19-.138L.675 6.021a.8.8 0 01.876-1.334l.1.066 8.686 6.681a2.729 2.729 0 003.16.119l.168-.119 8.687-6.681z" />
      </g>
      <defs>
        <clipPath id="email-unread_svg__email-unread_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

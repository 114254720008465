/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DiscountBold = React.forwardRef(function DiscountBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M22.255 1.973a1.75 1.75 0 00-2.475-.035l-18 17.5a1.75 1.75 0 002.44 2.509l18-17.5a1.75 1.75 0 00.035-2.474zM5 8.558a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm17.5 10.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z"
        fillRule="evenodd"
      />
    </svg>
  );
});

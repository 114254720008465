/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const BusinessDealBold = React.forwardRef(function BusinessDealBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M7.63 16.08c0 1.458-1.231 2.64-2.75 2.64s-2.75-1.182-2.75-2.64c0-1.458 1.231-2.64 2.75-2.64s2.75 1.182 2.75 2.64zM.586 22.73a.232.232 0 00.039.213.254.254 0 00.201.097h8.108c.08 0 .154-.036.201-.097a.232.232 0 00.038-.213c-.577-1.812-2.32-3.05-4.293-3.05-1.974 0-3.716 1.238-4.294 3.05zm21.284-6.65c0 1.458-1.231 2.64-2.75 2.64s-2.75-1.182-2.75-2.64c0-1.458 1.231-2.64 2.75-2.64s2.75 1.182 2.75 2.64zm1.506 6.864a.231.231 0 00.038-.212c-.577-1.812-2.32-3.05-4.293-3.05-1.974 0-3.716 1.238-4.294 3.05a.233.233 0 00.038.213.254.254 0 00.201.097h8.11a.25.25 0 00.2-.098z" />
      <path
        clipRule="evenodd"
        d="M3.5 10.08V1.44C3.5.645 4.172 0 5 0h14c.828 0 1.5.645 1.5 1.44v8.64c0 .795-.672 1.44-1.5 1.44h-3.689a.255.255 0 00-.177.067l-2.78 2.673a.51.51 0 01-.545.104.48.48 0 01-.309-.444v-2.16a.245.245 0 00-.25-.24H5c-.828 0-1.5-.645-1.5-1.44zm5.583-5.44c0 .934.777 1.698 1.75 1.72h2.334c.3 0 .542.233.542.52 0 .288-.243.52-.542.52h-3.5a.613.613 0 00-.625.6c0 .332.28.6.625.6h1.458c.066 0 .13.026.177.07a.234.234 0 01.073.17v.28c0 .331.28.6.625.6a.613.613 0 00.625-.6v-.28c0-.063.026-.124.073-.17a.255.255 0 01.177-.07h.292c.99 0 1.792-.77 1.792-1.72s-.802-1.72-1.792-1.72h-2.334a.53.53 0 01-.519-.52.53.53 0 01.52-.52h3.5a.613.613 0 00.624-.6c0-.331-.28-.6-.625-.6h-1.458a.245.245 0 01-.25-.24V2.4c0-.331-.28-.6-.625-.6a.613.613 0 00-.625.6v.28c0 .133-.112.24-.25.24h-.292c-.973.022-1.75.786-1.75 1.72z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Transfer = React.forwardRef(function Transfer(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M4.28 6.53a.75.75 0 00-1.06-1.06l-3 3-.003.002A.748.748 0 00.22 9.53l3 3a.75.75 0 001.06-1.06L2.56 9.75H13.5a.75.75 0 000-1.5H2.56l1.72-1.72zm16.5 4.94a.75.75 0 10-1.06 1.06l1.72 1.72H10.5a.75.75 0 000 1.5h10.94l-1.72 1.72a.75.75 0 101.06 1.06l3-3A.746.746 0 0024 15v-.008a.748.748 0 00-.22-.522l-3-3z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Print = React.forwardRef(function Print(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M3.75 9a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5zm3.75 6.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM8.25 18a.75.75 0 000 1.5h5.25a.75.75 0 000-1.5H8.25z" />
      <path
        clipRule="evenodd"
        d="M4.5 6V2.25A2.25 2.25 0 016.75 0h10.5a2.25 2.25 0 012.25 2.25V6h2.25A2.25 2.25 0 0124 8.25v7.5A2.25 2.25 0 0121.75 18H19.5v5.25a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V18H2.25A2.25 2.25 0 010 15.75v-7.5A2.25 2.25 0 012.25 6H4.5zm1.72-4.28a.75.75 0 01.53-.22h10.5a.75.75 0 01.75.75V6H6V2.25a.75.75 0 01.22-.53zM2.25 7.5a.75.75 0 00-.75.75v7.5a.75.75 0 00.75.75H4.5v-3.75a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v3.75h2.25a.75.75 0 00.75-.75v-7.5a.75.75 0 00-.75-.75H2.25zm15.75 6v9H6v-9h12z"
        fillRule="evenodd"
      />
    </svg>
  );
});

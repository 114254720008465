/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-null/no-null */
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type Drawerprops = React.HTMLAttributes<HTMLElement> & {
  isOpen?: boolean;
  mode?: 'left' | 'right' | 'screen';
  offset?: string;
};

/**
 * Drawer
 * @param open
 * @returns Drawer
 * @memberof drawer
 * @author Simon Groenborg
 */
export const Drawer = React.forwardRef<HTMLElement, Drawerprops>(
  ({ children, isOpen, mode = 'right', offset, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    const offsetStyles = {
      top: offset,
    };

    return (
      <div className={cn('drawer')}>
        <nav
          className={cn('drawer-nav', {
            'is-open': isOpen,
            'from-left': mode === 'left',
            'from-right': mode === 'right',
            'is-fullscreen': mode === 'screen',
          })}
          style={offset ? offsetStyles : {}}
          {...props}
          ref={ref}
        >
          {children}
        </nav>
      </div>
    );
  }
);

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Cross = React.forwardRef(function Cross(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M2.53 1.47a.75.75 0 00-1.06 1.06L10.94 12l-9.47 9.47a.75.75 0 101.06 1.06L12 13.06l9.47 9.47a.75.75 0 101.06-1.06L13.06 12l9.47-9.47a.75.75 0 00-1.06-1.06L12 10.94 2.53 1.47z" />
    </svg>
  );
});

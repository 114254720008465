/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AttachBold = React.forwardRef(function AttachBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M15.157 3.02c-.845.094-1.51.474-1.73.695l-8 8c-1.56 1.56-2.205 4.673 0 6.879 2.206 2.205 5.318 1.56 6.879 0l8-8a1.5 1.5 0 012.121 2.12l-8 8c-2.44 2.44-7.471 3.651-11.121 0-3.65-3.65-2.44-8.681 0-11.12l8-8c.779-.78 2.114-1.4 3.52-1.556 1.466-.162 3.207.161 4.601 1.556 1.394 1.394 1.718 3.135 1.555 4.6-.156 1.406-.776 2.742-1.555 3.521l-8 8a3.818 3.818 0 01-2.155 1.038c-.965.125-2.059-.13-2.966-1.038-.92-.92-1.155-2.032-1.025-2.987.12-.88.546-1.656 1.025-2.134l8-8a1.5 1.5 0 112.121 2.12l-8 8v.002a.93.93 0 00-.173.416.504.504 0 00.012.219.59.59 0 00.161.243.63.63 0 00.253.169.468.468 0 00.206.014.861.861 0 00.42-.183l8-8c.22-.221.6-.886.694-1.73.088-.785-.089-1.543-.694-2.15-.606-.605-1.365-.781-2.149-.694z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { ListItem } from './ListItem';

export interface IListProps extends React.HTMLAttributes<HTMLDivElement> {
  isBorderless?: boolean;
}

interface IListSubComponents {
  ListItem: typeof ListItem;
}

export const List: React.FunctionComponent<IListProps> & IListSubComponents = ({
  children,
  isBorderless,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const className = cn('list', {
    'is-borderless': isBorderless,
  });

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

List.ListItem = ListItem;

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardBody
 * @param param0 props
 */
export const CardBody: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-body')}>{children}</div>;
};

CardBody.displayName = 'Card.Body';

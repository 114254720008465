import * as React from 'react';
import { Sizes } from '../../constants';
import { getValidChildren, useFrameworkClassnamePrefixer } from '../../utils';
import { ButtonToggleItem, IButtonToggleItemProps } from './ButtonToggleItem';

export interface IButtonToggleProps {
  size?: Extract<Sizes, 'small' | 'medium' | 'large'>;
  isFullWidth?: boolean;
  onChange?: (index: number) => void;
  initialActiveButton?: number;
}

export interface IButtonToggleSubComponents {
  Item: typeof ButtonToggleItem;
}

export type ButtonToggleType = React.FunctionComponent<IButtonToggleProps> &
  IButtonToggleSubComponents;

// TODO: <ButtonToggle>: onChange here is super crude, you can't really get the ref from the button that clicked.
export const ButtonToggle: ButtonToggleType = ({
  children,
  size = 'medium',
  isFullWidth = false,
  onChange,
  initialActiveButton,
}) => {
  const cn = useFrameworkClassnamePrefixer();
  const [activeButton, setActiveButton] = React.useState(initialActiveButton);

  const validChildren = getValidChildren<IButtonToggleItemProps>(children);

  const className = cn('button-toggle', {
    [`is-${size}`]: size,
    [`is-fullwidth`]: isFullWidth,
  });

  return (
    <div className={className}>
      {validChildren.map((child, index) =>
        React.cloneElement(child, {
          index,
          onClick: () => {
            setActiveButton(index);
            onChange?.(index);
          },
          isSelected: index === activeButton,
        })
      )}
    </div>
  );
};

ButtonToggle.Item = ButtonToggleItem;

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DevicesSmileyBold = React.forwardRef(function DevicesSmileyBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M23.53 20l-2-5H2.27L.45 19.85a2.29 2.29 0 002.13 3.26h19A2.19 2.19 0 0023.53 20zm-9.45 0h-3.92a1 1 0 110-2h3.92a1 1 0 110 2z" />
      <path d="M21.65 15.37H2.27V3.46A2.66 2.66 0 015 .89h13.92a2.66 2.66 0 012.74 2.58l-.01 11.9zM4.09 13.66h15.75V3.46a.9.9 0 00-.93-.87H5a.9.9 0 00-.93.87l.02 10.2z" />
      <path d="M9.7 7.52a1 1 0 01-1-1V5.24a1 1 0 112 0v1.28a1 1 0 01-1 1zm4.38 0a1 1 0 01-1-1V5.24a1 1 0 012 0v1.28a1 1 0 01-1 1zM11.5 12c-1.66-.1-2.846-.62-4.25-1.51a1 1 0 011.09-1.66A8.23 8.23 0 0012 10a8.16 8.16 0 003.59-1.2 1.001 1.001 0 011.09 1.68c-1.41.885-2.518 1.407-4.18 1.52h-1z" />
    </svg>
  );
});

import * as React from 'react';

type KlikErrorBoundaryProps = {
  component?: JSX.Element;
  autoNotification?: boolean;
};

/**
 * KlikErrorBoundary
 */
export class KlikErrorBoundary extends React.Component<KlikErrorBoundaryProps> {
  component?: JSX.Element;
  state = { hasError: false };

  constructor({ component, ...props }: KlikErrorBoundaryProps) {
    super(props);

    this.component = component;
  }

  static getDerivedStateFromError<T extends { hasError: boolean }>(_error: unknown): T {
    return { hasError: true } as T;
  }

  componentDidCatch<T>(_error: unknown, _errorInfo: unknown): void {
    // TODO: log to sentry
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError && this.component) {
      return this.component;
    }

    if (hasError) {
      return <h1>The App stepped on a brick and crashed</h1>;
    }

    return children;
  }
}

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { HeaderBurgerItem } from './HeaderHelpers';
import { HeaderItem } from './HeaderItem';

/**
 * HeaderIconGroup
 * Group Icons together in the navigations.
 * <NavigationIconGroups /> does not support sub Menus
 * @param param0
 */
interface IHeaderIconGroup {
  onBurgerClickHandler?: () => void;
}

export const HeaderIconGroup: React.FunctionComponent<IHeaderIconGroup> = ({
  children,
  onBurgerClickHandler,
}) => {
  const cn = useFrameworkClassnamePrefixer();
  return (
    <nav className={cn('header-icons')}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return <HeaderItem behaviour="icon" {...child.props} />;
        }

        return child;
      })}
      <HeaderBurgerItem onBurgerClickHandler={onBurgerClickHandler} />
    </nav>
  );
};

HeaderIconGroup.displayName = 'Header.IconGroup';

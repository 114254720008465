import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { RadioButtonProps, useRadio } from './useRadio';

/**
 * RadioButton
 *
 * @returns RadioButton
 * @memberof radio
 * @author Simon Groenborg
 */
export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ label, isError, isDisabled, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    const { ...ariaRadio } = useRadio({ label, isDisabled, isError, ...props });
    return (
      <div
        className={cn('radio', {
          [`status-error`]: isError,
        })}
      >
        <label className={cn('radio-label')}>
          <input className={cn('radio-hidden')} {...props} ref={ref} {...ariaRadio} />
          <span className={cn('radio-shape')} />
          {label && <span className={cn('radio-text')}>{label}</span>}
        </label>
      </div>
    );
  }
);

RadioButton.displayName = 'Radio.Button';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ZoomOut = React.forwardRef(function ZoomOut(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M7.5 9.334a.666.666 0 100 1.332h5a.666.666 0 100-1.332h-5z" />
      <path
        clipRule="evenodd"
        d="M19.112 10.056a9.056 9.056 0 10-3.399 7.072l5.58 5.58.094.083a1 1 0 001.32-1.498l-5.579-5.58a9.018 9.018 0 001.984-5.657zM3 10.056a7.056 7.056 0 1114.112 0 7.056 7.056 0 01-14.112 0z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const MenuNavigationVerticalBold = React.forwardRef(function MenuNavigationVerticalBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M1 6.5A1.5 1.5 0 012.5 5h19a1.5 1.5 0 010 3h-19A1.5 1.5 0 011 6.5zm0 6A1.5 1.5 0 012.5 11h19a1.5 1.5 0 010 3h-19A1.5 1.5 0 011 12.5zM2.5 17a1.5 1.5 0 000 3h19a1.5 1.5 0 000-3h-19z" />
    </svg>
  );
});

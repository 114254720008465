import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * MenuStack
 * React MenuStack component.
 *
 *
 * @returns MenuStack Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuStack: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('menu-stack')}>{children}</div>;
};

MenuStack.displayName = 'Menu.Stack';

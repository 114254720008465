import * as React from 'react';
import { getValidChildren, useFrameworkClassnamePrefixer } from '../../utils';
import { RadioButton } from './RadioButton';
import { RadioButtonProps } from './useRadio';

export type RadioProps = React.HTMLAttributes<HTMLElement> & {
  name?: string;
};

interface IRadioSubcomponents {
  Button: typeof RadioButton;
}

/**
 * Radio
 *
 * @param param0
 * @memberof radio
 * @author Simon Groenborg
 */
export const Radio: React.FunctionComponent<RadioProps> & IRadioSubcomponents = ({
  children,
  onChange,
  name,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();
  const [selectedRadio, setSelectedRadio] = React.useState<string>('');

  const onRadioChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setSelectedRadio(e.currentTarget.value);

      if (onChange) {
        onChange(e);
      }
    },
    [selectedRadio]
  );

  const validChildren = getValidChildren<
    RadioButtonProps & React.InputHTMLAttributes<HTMLInputElement>
  >(children);

  return (
    <div className={cn('radios')} {...props}>
      {validChildren.map((child) =>
        React.cloneElement(child, {
          onChange: onRadioChange,
          name,
        })
      )}
    </div>
  );
};

Radio.Button = RadioButton;

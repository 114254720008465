import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { forwardRefAs, IPolymorphProps, Polymorph } from '../polymorph';

export type IconProps = IPolymorphProps & {
  icon: React.ReactElement | React.ReactNode;
  parentClass?: string;
};

/**
 * Icon element
 * use the icon with klik-icons-react icons to get the prober styling
 * TODO: Size modifiers
 * TODO: Remove this element
 * @returns an icon element wrapped in klik styles
 */
export const Icon = forwardRefAs<IconProps>(function Icon(
  { icon, as = 'span', parentClass = '', ...props },
  ref
) {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <Polymorph
      as={as}
      {...props}
      className={cn('icon', {
        [parentClass]: parentClass,
      })}
      ref={ref}
    >
      {icon}
    </Polymorph>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Pin = React.forwardRef(function Pin(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M16.307.125a2.203 2.203 0 00-2.589.895L9.922 6.986a8.1 8.1 0 00-5.446.98l.37.635-.372-.634a2.203 2.203 0 00-.441 3.46l3.5 3.5-7.318 7.318a.734.734 0 001.039 1.038l7.317-7.318 3.502 3.502a2.203 2.203 0 003.46-.44l.002-.003a8.1 8.1 0 00.979-5.445l5.965-3.797a2.204 2.204 0 00.376-3.416l-5.72-5.72a2.202 2.202 0 00-.828-.521zM4.976 9.463a.734.734 0 01.24-.228 6.63 6.63 0 014.882-.713.734.734 0 00.791-.32l4.068-6.393a.735.735 0 011.139-.125l5.72 5.72a.734.734 0 01-.125 1.139l-6.392 4.068a.734.734 0 00-.32.792 6.63 6.63 0 01-.713 4.881.734.734 0 01-1.153.146l-8.042-8.042a.735.735 0 01-.095-.925z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LayoutGridViewBold = React.forwardRef(function LayoutGridViewBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M0 .773a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v8.974a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V.773zm0 13.474a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9zM13.5.773a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v8.974a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75V.773zm0 13.474a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { useID } from '../tabs/utils';
import { AccordionItem, IAccordionItemProps } from './AccordionItem';

export interface IAccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<IAccordionItemProps>[] | React.ReactElement<IAccordionItemProps>;
  // TODO: accordion: add ability to disable multiple panels to be open at the same time
}

interface IAccordionSubComponents {
  Item: typeof AccordionItem;
}

export const Accordion: React.FunctionComponent<IAccordionProps> & IAccordionSubComponents = ({
  children,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();
  const id = useID('accordion');

  return (
    // TODO: accordion: allow for custom aria-label at top-level
    <div aria-label="accordion" className={cn('accordion')} {...props} id={id}>
      {React.Children.map(children, (child: React.ReactElement<IAccordionItemProps>, index) => {
        return React.cloneElement(child, {
          index,
          id,
          ...child.props,
        });
      })}
    </div>
  );
};

Accordion.Item = AccordionItem;

import { CrossBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { ModalAction } from './ModalAction';
import { ModalBody } from './ModalBody';
import { ModalHeading } from './ModalHeading';
import { ModalInset } from './ModalInset';

export type ModalProps = {
  icon?: React.ReactNode;
  status?: 'information' | 'error' | 'warning' | 'success';
  action?: React.ReactElement<typeof ModalAction>;
  body?: React.ReactElement<typeof ModalBody>;
  heading?: React.ReactElement<typeof ModalHeading>;
  inset?: React.ReactElement<typeof ModalInset>;
  exitClickHandler?: (event: React.MouseEvent) => void;
};

interface IModalSubComponents
  extends React.ForwardRefExoticComponent<ModalProps & React.RefAttributes<HTMLElement>> {
  Heading: typeof ModalHeading;
  Body: typeof ModalBody;
  Action: typeof ModalAction;
  Inset: typeof ModalInset;
}

/**
 * Modal
 *
 * @returns Modal
 * @memberof modal
 * @author Simon Groenborg
 */
export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  ({ heading, body, action, icon, status = '', exitClickHandler, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    const statusClasses = {
      'toast-status': status,
      [`status-${status}`]: status,
    };

    return (
      <div className={cn('modal-container')} ref={ref} {...props} role="dialog">
        <div className={cn('toast', statusClasses)}>
          <div className={cn('toast-main')}>
            {icon && <div className={cn('toast-icon')}>{icon}</div>}
            <div className={cn('toast-content')}>
              {heading}
              {body}
            </div>
          </div>

          <button className={cn('toast-close')} onClick={exitClickHandler} tabIndex={0}>
            <Icon aria-label="close" icon={<CrossBold />} />
          </button>

          {action}
        </div>
      </div>
    );
  }
) as IModalSubComponents;

Modal.Heading = ModalHeading;
Modal.Body = ModalBody;
Modal.Action = ModalAction;
Modal.Inset = ModalInset;

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LearningLibraryBold = React.forwardRef(function LearningLibraryBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#learning-library-bold_svg__learning-library-bold_svg__clip0)">
        <path d="M17.39 3.09a1 1 0 001-1v-1a1 1 0 10-2 0v1a1 1 0 001 1zm3.86 1.11a1 1 0 00.75-.35l.65-.77a1 1 0 00-1.52-1.28l-.65.76a1 1 0 00.76 1.65l.01-.01zm-8.48-.35a1 1 0 001.53-1.29l-.64-.76a1 1 0 00-1.53 1.29l.64.76zM23 7.47a1 1 0 00-1 1v9.83a18.159 18.159 0 00-9 2.6V6.11A1.31 1.31 0 0012.37 5c-.95-.58-5.94-3.43-11.26-2.56A1.32 1.32 0 000 3.74v15a1.32 1.32 0 001.21 1.32c3.55.29 8.21 1.33 9.65 3.32a1.33 1.33 0 001 .55h.09a1.29 1.29 0 00.93-.4c.68-.71 3.39-3 9.83-3.29a1.32 1.32 0 001.29-1.3V8.47a1 1 0 00-1-1zm-12.87 5.48a.79.79 0 01-1 .52 20.926 20.926 0 00-5.17-1 .82.82 0 01-.71-.9.81.81 0 01.86-.74c1.874.127 3.725.486 5.51 1.07a.83.83 0 01.51 1.05zm0 4a.79.79 0 01-1 .52 20.926 20.926 0 00-5.17-1 .82.82 0 01-.71-.9.81.81 0 01.86-.74c1.878.159 3.73.552 5.51 1.17a.83.83 0 01.51 1v-.05zM9.9 9.26a.78.78 0 01-.78.16 20.88 20.88 0 00-5.17-1 .84.84 0 01-.43-1.46.77.77 0 01.59-.18 22.63 22.63 0 015.51 1.08.82.82 0 01.53.62.84.84 0 01-.25.78z" />
        <path d="M15.25 10.89v1.22a.75.75 0 00.75.75h.5v.67a1 1 0 002 0v-.67h.6a.75.75 0 00.65-.74v-1.2a4 4 0 10-4.5 0v-.03zm.3-3.24a2 2 0 11.15.758 2 2 0 01-.15-.768v.01z" />
      </g>
      <defs>
        <clipPath id="learning-library-bold_svg__learning-library-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

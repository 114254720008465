/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Tag = React.forwardRef(function Tag(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M18 3.75a2.25 2.25 0 110 4.499 2.25 2.25 0 010-4.499zm.287 1.557a.75.75 0 10-.574 1.386.75.75 0 00.574-1.386z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.75 0A2.25 2.25 0 0124 2.25v6.257a3.75 3.75 0 01-1.098 2.651L11.342 22.72a2.25 2.25 0 01-3.182 0l-6.88-6.88a2.25 2.25 0 010-3.181L12.843 1.097A3.75 3.75 0 0115.493 0h6.257zm.53 1.72a.75.75 0 00-.53-.22h-6.257a2.25 2.25 0 00-1.59.658L2.34 13.72a.75.75 0 000 1.06l6.88 6.88a.75.75 0 001.06 0l11.56-11.561a2.25 2.25 0 00.659-1.591V2.25a.75.75 0 00-.22-.53z"
        fillRule="evenodd"
      />
    </svg>
  );
});

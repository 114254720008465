/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Select = React.forwardRef(function Select(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M18.608 24H9.983c-.191 0-.479-.096-.575-.288l-3.737-4.887c-.575-.767-.575-1.725-.096-2.492.67-.958 2.013-1.245 3.067-.575 0 0 .096 0 .096.096l.479.384v-8.05c0-1.15.958-2.109 2.204-2.109 1.246 0 2.204.958 2.204 2.109v5.175l3.45.766c1.725.383 2.97 2.013 2.78 3.833l-.48 5.367c0 .383-.383.671-.767.671zm-8.241-1.438h7.57l.384-4.695c.096-1.055-.671-2.109-1.725-2.3l-4.025-.863c-.288-.096-.575-.383-.575-.67v-5.75c0-.384-.288-.767-.671-.767-.287 0-.575.287-.575.67v9.296c0 .288-.192.48-.383.671-.288.096-.575.096-.767-.096l-1.63-1.245c-.382-.192-.766-.096-1.053.191-.192.288-.192.575 0 .863l3.45 4.695zm1.15-17.92a.756.756 0 01-.767-.767V1.767c0-.48.287-.767.767-.767.479 0 .766.288.766.767v2.108c-.095.383-.383.767-.766.767zm2.779.671a.732.732 0 01-.48-.192.752.752 0 010-1.054l1.438-1.438a.752.752 0 011.054 0 .752.752 0 010 1.054l-1.437 1.438c-.192.096-.383.191-.575.191zm-5.654 0a.732.732 0 01-.48-.192L6.63 3.683a.752.752 0 010-1.054.752.752 0 011.054 0l1.438 1.438a.752.752 0 010 1.054c-.192.096-.383.191-.48.191zm8.528 2.204h-2.108a.756.756 0 01-.766-.767c0-.48.287-.767.767-.767h2.108c.383 0 .767.288.767.767 0 .48-.288.767-.767.767zm-9.295 0H5.767A.756.756 0 015 6.75c0-.383.287-.767.767-.767h2.108c.383 0 .767.288.767.767 0 .48-.384.767-.767.767z" />
    </svg>
  );
});

import * as React from 'react';
import { Sizes } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type TextLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  size?: Sizes;
  isDisabled?: boolean;
  standalone?: boolean;
  label?: string;
  disabled?: boolean;
};

/**
 * Textlink
 *
 * @param param0
 * @returns Textlink component
 * @memberof textlink
 * @author Simon Groenborg
 */
export const TextLink = React.forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ label, size = 'default', isDisabled, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    return (
      <a
        aria-disabled={isDisabled}
        aria-label={label}
        className={cn('text-link', `is-${size}`)}
        disabled={isDisabled}
        {...props}
        ref={ref}
      >
        {label}
      </a>
    );
  }
);

import * as React from 'react';
import { HelpText } from '../helptext';
import { Label } from '../label';
import { TextArea } from '../textarea';
import { TextField } from '../textfield';

interface IFormSubcomponents {
  TextField: typeof TextField;
  TextArea: typeof TextArea;
  Label: typeof Label;
  HelpText: typeof HelpText;
}

interface IFormProps {
  children?: React.ReactNode;
}

export type FormSubComponents = React.ForwardRefExoticComponent<
  IFormProps & React.RefAttributes<HTMLFormElement>
> &
  IFormSubcomponents;

/**
 * Forms
 * Forms can be used to compose larger sets of userinputs.
 * Forms accept several children
 *
 */
export const Form = React.forwardRef<HTMLFormElement, IFormProps>(({ children, ...props }, ref) => {
  return (
    <form {...props} ref={ref}>
      {children}
    </form>
  );
}) as FormSubComponents;

Form.TextArea = TextArea;
Form.TextField = TextField;
Form.Label = Label;
Form.HelpText = HelpText;

import * as React from 'react';
import { IButtonProps, UseComponent } from './types';

const ButtonElements = ['button', 'input', 'a'];

/**
 * useButton
 * this hook will let you create a pure klik button component
 *
 *
 * @param param0
 * @memberof button
 * @author Simon Groenborg
 */
export const useButton: UseComponent<IButtonProps> = ({ as, isDisabled, label, ...props }) => {
  React.useEffect(() => {
    if (ButtonElements.indexOf(as as string)) {
      throw Error(`invalid component type ${ButtonElements.toString()}`);
    }
  }, [as]);

  return {
    role: 'button',
    tabIndex: isDisabled ? -1 : 0,
    'aria-disabled': isDisabled,
    'aria-label': label,
    ...props,
  };
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const RotateLeftBold = React.forwardRef(function RotateLeftBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M0 2a1.5 1.5 0 113 0v2.414a11.746 11.746 0 118.746 19.58 1.5 1.5 0 01.002-3 8.747 8.747 0 10-7.23-13.663h3.813a1.5 1.5 0 110 3H2.152a1.2 1.2 0 01-.12 0H1.5a1.5 1.5 0 01-1.5-1.5V2z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { forwardRefAs, Polymorph } from '../polymorph';
import { IButtonProps } from './types';
import { useButton } from './useButton';

/**
 * Button
 *
 *
 * @param IButtonProps
 * @memberof button
 * @author Simon Groenborg
 */
export const Button = forwardRefAs<IButtonProps>(
  (
    { as = 'button', label, iconPosition, icon, isReverse, variant, size, state, ...props },
    ref
  ) => {
    const cn = useFrameworkClassnamePrefixer();
    const { ...aria } = useButton({ as, label, ...props });

    const children = React.useMemo(() => {
      if (!icon && label) {
        return <span>{label}</span>;
      }

      const buttonIcon = <Icon aria-hidden={true} icon={icon as React.ReactElement} />;

      if (!label && icon) {
        return buttonIcon;
      }

      return (
        <React.Fragment>
          {(!iconPosition || iconPosition === 'left') && buttonIcon}
          {label ?? <span>{label}</span>}
          {iconPosition && iconPosition === 'right' && buttonIcon}
        </React.Fragment>
      );
    }, [icon, label, iconPosition]);

    const className = cn('button', {
      'is-reverse': isReverse,
      'is-icon-only': !label && icon ? true : false,
      [`is-${variant ? variant : ''}`]: variant,
      [`is-${size as string}`]: size,
      ...(state && { [`is-${state}`]: state }),
    });

    return (
      <Polymorph as={as} {...aria} className={className} ref={ref}>
        {children}
      </Polymorph>
    );
  }
);

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LockBold = React.forwardRef(function LockBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M12 .625A6.375 6.375 0 005.625 7v2H3.75a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-1.875V7A6.375 6.375 0 0012 .625zM16.125 7v2h-8.25V7a4.125 4.125 0 018.25 0zm-5.25 8a1.125 1.125 0 012.25 0v3a1.125 1.125 0 01-2.25 0v-3z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { MenuItem } from './MenuItem';

/**
 * MenuList
 * React MenuList component.
 *
 *
 * @returns MenuList Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuList: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <ul className={cn('menu-list')}>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return <MenuItem _context="li" {...child.props} />;
        }

        return child;
      })}
    </ul>
  );
};

MenuList.displayName = 'Menu.List';

import * as React from 'react';
import { Icon } from '../icon/Icon';

export interface ICardActionProps {
  ariaLabel?: string;
  icon?: React.ReactNode;
}
/**
 * CardAction
 * @param param0 props
 */

export const CardAction: React.FunctionComponent<ICardActionProps> = ({ icon, ariaLabel = '' }) => {
  return <Icon aria-label={ariaLabel} as="button" icon={icon} />;
};

CardAction.displayName = 'Card.Action';

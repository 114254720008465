/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const MoveBold = React.forwardRef(function MoveBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M7.94 6.06a1.5 1.5 0 010-2.12l3-3a1.5 1.5 0 012.12 0l3 3a1.5 1.5 0 01-2.12 2.12l-.44-.439V10.5h4.879l-.44-.44a1.5 1.5 0 012.122-2.12l3 3a1.5 1.5 0 010 2.12l-3 3a1.5 1.5 0 01-2.122-2.12l.44-.44H13.5v4.879l.44-.44a1.5 1.5 0 012.12 2.122l-3 3a1.5 1.5 0 01-2.12 0l-3-3a1.5 1.5 0 012.12-2.122l.44.44V13.5H5.621l.44.44a1.5 1.5 0 01-2.122 2.12l-3-3a1.5 1.5 0 010-2.12l3-3a1.5 1.5 0 112.122 2.12l-.44.44H10.5V5.621l-.44.44a1.5 1.5 0 01-2.12 0z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CloudFolder = React.forwardRef(function CloudFolder(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.098 15.882a.69.69 0 00-.53-.25h-2.06v-2a.68.68 0 00-.69-.68h-5.48v-.68a.68.68 0 00-.69-.68h-4.79a.678.678 0 00-.69.68v8.45c0 .409.15.803.42 1.11a1.73 1.73 0 001.38.59h10.54c.399.002.787-.132 1.1-.38a1.73 1.73 0 00.61-1l1-4.61a.68.68 0 00-.12-.55zm-13 5.13a.35.35 0 01-.15.05h-.11a.35.35 0 01-.21-.11.34.34 0 01-.08-.22v-7.82h3.43v.68a.68.68 0 00.69.68h5.48v1.36h-6.87a.68.68 0 00-.66.49l-1.4 4.7a.34.34 0 01-.15.19h.03zm10.81-.24a.37.37 0 01-.37.29h-8.97l1.22-4.08h8.92l-.8 3.79z" />
      <path d="M4.598 15.522a.76.76 0 01-.25 0 4.82 4.82 0 01-3.33-5c.25-2 1.77-3.34 4.08-3.58.41-1.67 1.49-5.56 6.32-5.91a6.61 6.61 0 016.62 3.74 6.13 6.13 0 015.09 4.48 4.89 4.89 0 01-2.62 5.37.776.776 0 01-.71-1.38c.09 0 2.38-1.27 1.82-3.63a4.57 4.57 0 00-3.71-3.29 1.28 1.28 0 01-1.17-.75 5 5 0 00-5.17-3c-3.85.28-4.66 3.29-4.98 4.74a1.45 1.45 0 01-1.28 1.1c-1.15.1-2.55.58-2.76 2.23-.3 2.42 2.19 3.33 2.3 3.37a.78.78 0 01-.25 1.51z" />
    </svg>
  );
});

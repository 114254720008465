import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type PaginationItemProps = {
  key: number;
  page: number;
  isSelected?: boolean;
  onClick?: (page: number) => void;
  isEllipsis?: boolean;
};

/**
 * PaginationItem
 * The pagination items is used to represent a page in a paginated model.
 * Use the Items only as a page element or an ellipsis
 *
 * TODO: Add a11y controls and focus state
 *
 * @param page represented by the element
 * @param selected true if the item displays the current page
 * @param onClick callback supplying the reciver with the page number
 * @param isEllipsis is the element just a placeholder
 * @returns Controlled Pagination Item
 * @memberof pagination
 * @author Simon Groenborg
 */
export const PaginationItem: React.FunctionComponent<PaginationItemProps> = ({
  page,
  isSelected = false,
  isEllipsis = false,
  onClick,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const onPaginationItemClick = React.useCallback(() => {
    if (onClick) {
      onClick(page);
    }
  }, [page]);

  const pagelabel = !isEllipsis ? page : '...';

  return (
    <li
      className={cn('pagination-item', {
        'is-active': isSelected,
      })}
      role="presentation"
    >
      <button
        aria-label={`${page}`}
        data-text={pagelabel}
        onClick={onPaginationItemClick}
        tabIndex={-1}
      >
        <span className={cn('pagination-item-text')}>{pagelabel}</span>
      </button>
    </li>
  );
};

import * as React from 'react';
import { useUID } from 'react-uid';
import { Status } from '../../constants';

export type CheckboxProps = {
  isRequired?: boolean;
  isDisabled?: boolean;
  indeterminate?: boolean;
  label?: string;
  labelLeft?: boolean;
  status?: Status;
  defaultChecked?: CheckStates;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export enum CheckStates {
  Checked = 'checked',
  UnChecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

export type CheckBoxAria = {
  checkboxProps: React.InputHTMLAttributes<HTMLInputElement>;
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
};

/**
 * useCheckbox
 * Headless checkbox component.
 * It will calculate all checkbox and label props for a checkbox component
 *
 * TODO: Revisit aria attributes to ensure it is complient with W3 standards
 *
 * @params CheckboxProps
 * @returns CheckBox Aria Attributes
 * @memberof checkbox
 * @author Simon Groenborg
 */
export const useCheckbox = ({
  isRequired,
  defaultChecked = CheckStates.UnChecked,
  isDisabled = false,
  label,
  onChange,
}: CheckboxProps): CheckBoxAria => {
  const [checkState, setCheckState] = React.useState<CheckStates>(defaultChecked);
  const uid = useUID();

  const onCheckedChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }

      setCheckState(e.target.checked ? CheckStates.Checked : CheckStates.UnChecked);
    },
    [onChange]
  );

  const checkboxAria: React.InputHTMLAttributes<HTMLInputElement> = React.useMemo(
    () => ({
      role: 'checkbox',
      'aria-disabled': isDisabled,
      'aria-checked':
        checkState === CheckStates.Indeterminate ? 'mixed' : checkState === CheckStates.Checked,
      'aria-labelledby': uid,
      type: 'checkbox',
      tabIndex: isDisabled ? -1 : 0,
      id: uid,
      disabled: isDisabled,
      required: isRequired,
      checked: checkState === CheckStates.Checked,
      onChange: onCheckedChange,
    }),
    [isRequired, isDisabled, checkState, onCheckedChange, defaultChecked]
  );

  const labelAria: React.LabelHTMLAttributes<HTMLLabelElement> = React.useMemo(
    () => ({
      htmlFor: uid,
      'aria-label': label,
    }),
    [label]
  );

  return {
    checkboxProps: checkboxAria,
    labelProps: labelAria,
  };
};

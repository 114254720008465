/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LaptopSmiley = React.forwardRef(function LaptopSmiley(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#laptop-smiley_svg__laptop-smiley_svg__clip0)">
        <path d="M10.5 5.5a.75.75 0 00-1.5 0V7a.75.75 0 001.5 0V5.5zM8.755 9.47a.75.75 0 00-1.01 1.108 6.318 6.318 0 008.51 0 .75.75 0 10-1.01-1.108 4.818 4.818 0 01-6.49 0zm5.495-4.72a.75.75 0 01.75.75V7a.75.75 0 01-1.5 0V5.5a.75.75 0 01.75-.75zM10.5 17.5a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" />
        <path
          clipRule="evenodd"
          d="M4.5 1a2.25 2.25 0 00-2.25 2.25v11.087L.197 18.829l-.003.008A2.25 2.25 0 002.25 22h19.5a2.25 2.25 0 002.057-3.163l-2.057-4.5V3.25A2.25 2.25 0 0019.5 1h-15zm15.75 12.75H3.75V3.25a.75.75 0 01.75-.75h15a.75.75 0 01.75.75v10.5zM1.563 19.448l1.92-4.198h17.035l1.92 4.198a.75.75 0 01-.688 1.052H2.25a.75.75 0 01-.687-1.052z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="laptop-smiley_svg__laptop-smiley_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

import {
  loaderContainerStyle,
  circlesContainerStyle,
  heartContainerStyle,
  heartBottomPieceStyle,
  heartTopLeftPieceStyle,
  heartTopRightPieceStyle,
  headContainerStyle,
  headTopPieceStyle,
  headCenterPieceStyle,
  headBottomPieceStyle,
  headSmileStyle,
  circle1Style,
  circle2Style,
  circle3Style,
  circle4Style,
  eye1Style,
  eye2Style,
} from './Spinner.style';

const Spinner = () => {
  return (
    <div css={loaderContainerStyle} role="progressbar" aria-busy="true">
      <div css={circlesContainerStyle}>
        <div css={circle1Style}></div>
        <div css={circle2Style}></div>
        <div css={circle3Style}></div>
        <div css={circle4Style}></div>
      </div>
      <div css={heartContainerStyle}>
        <div css={heartBottomPieceStyle}></div>
        <div css={heartTopLeftPieceStyle}></div>
        <div css={heartTopRightPieceStyle}></div>
      </div>

      <div css={headContainerStyle}>
        <div css={headTopPieceStyle}></div>
        <div css={headCenterPieceStyle}>
          <div css={eye1Style}></div>
          <div css={eye2Style}></div>
          <div css={headSmileStyle}></div>
        </div>
        <div css={headBottomPieceStyle}></div>
      </div>
    </div>
  );
};

export { Spinner };

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-no-bind */
import { ArrowDown } from '@lego/klik-icons-react';
import * as React from 'react';
import { useClickOutside, useShareForwardRef } from '../../hooks';
import { Nullable, Pair } from '../../utility/types';
import { chain, useFrameworkClassnamePrefixer } from '../../utils';
import { Dropdown, IDropdownableElement, useDropdown } from '../dropdown';
import { DropdownItem } from '../dropdown/DropdownItem';
import { Icon } from '../icon';
import { Tag, Tags } from '../tag';

export type OnOptionChangeCallback<T> = (value: Pair<T>) => void;

export type SelectProps<T extends string | number> = IDropdownableElement<T> & {
  value?: Pair<T> | Pair<T>[];
  name?: string;
  onOptionSelect?: OnOptionChangeCallback<T>;
  onOptionDelete?: OnOptionChangeCallback<T>;
  isMultiSelect?: boolean;
};

/**
 * Select
 * use the select component when the input requires different options
 *
 * TODO: Add aria attributes and a11y navigation
 * @param onOptionSelect select callback handler
 * @param name name of element
 * @param value of the selected element
 * @returns Select Component
 * @memberof select
 * @author Simon Groenborg
 */
export const Select = (React.forwardRef(
  <T extends string | number>(
    {
      value,
      name,
      children,
      onOptionSelect,
      onOptionDelete,
      isMultiSelect = false,
      ...props
    }: SelectProps<T> & React.HTMLAttributes<HTMLDivElement>,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const cn = useFrameworkClassnamePrefixer();
    // eslint-disable-next-line no-null/no-null
    const dropdown = React.useRef<Nullable<HTMLDivElement>>(null);
    // eslint-disable-next-line no-null/no-null
    const input = React.useRef<Nullable<HTMLInputElement>>(null);
    const select = useShareForwardRef<HTMLDivElement>(ref);
    const { toggleVisible, setVisible } = useDropdown(dropdown, select, { inheritWidth: true });
    const [hasFocus, setHasFocus] = React.useState(false);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [dropdownFocus, setDropdownFocus] = React.useState(false);

    useClickOutside(dropdown, () => {
      setVisible(false);
    });

    const handleClick = React.useCallback(
      (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        setVisible((isOpen) => {
          if (isOpen && isMultiSelect) {
            return true;
          }

          return !isOpen;
        });
      },
      [input, isMultiSelect]
    );

    const displayValue = React.useMemo(() => {
      if (isMultiSelect) {
        const actualValue = value as Pair<T>[];

        if (actualValue.length > 0) {
          const tags = Array.from(actualValue).map((val) => (
            <Tag
              key={val.label + val.value}
              label={val.label}
              onDelete={() => {
                if (onOptionDelete) {
                  onOptionDelete(val);
                }
              }}
              showDelete={true}
            />
          ));

          return <Tags>{tags}</Tags>;
        } else {
          return 'Select one or more options';
        }
      } else {
        return (value as Pair<T> | undefined)?.label;
      }
    }, [value]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (hasFocus) {
        switch (e.key) {
          case ' ':
            e.preventDefault();
            toggleVisible();
            setDropdownFocus(true);
            return;
        }
      }
    };

    return (
      <div
        {...props}
        className={cn('input', 'is-select')}
        onClick={chain(handleClick, props.onClick)}
        onFocus={() => setHasFocus(true)}
        onKeyDown={chain(onKeyDown, props.onKeyDown)}
        ref={select}
        role="input"
        tabIndex={hasFocus ? 0 : -1}
      >
        <span className={cn('input-content')}>{displayValue}</span>
        <Icon icon={<ArrowDown />} />
        <input
          className={cn('input-hidden')}
          name={name}
          readOnly={true}
          ref={input}
          value={JSON.stringify(value)}
        />
        <Dropdown
          _hasFocus={dropdownFocus}
          onDropdownSelect={onOptionSelect}
          ref={dropdown}
          value={value}
        >
          {children}
        </Dropdown>
      </div>
    );
  }
) as unknown) as ISelectWithSubComponent;

interface ISelectWithSubComponent {
  <T extends string | number>(
    p: SelectProps<T> & React.HTMLAttributes<HTMLDivElement> & { ref?: React.Ref<HTMLDivElement> }
  ): React.ReactElement;
  Option: typeof DropdownItem;
}

Select.Option = DropdownItem;

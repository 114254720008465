import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type ISidebarBrandProps = {
  href?: string;
  label?: string;
  logo?: JSX.Element | React.Component | React.SVGAttributes<SVGSVGElement>;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * SidebarBrand
 * @param param0
 */
export const SidebarBrand: React.FunctionComponent<ISidebarBrandProps> = ({
  label,
  logo,
  href,
  onClick,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <div className={cn('sidebar-brand')}>
      <a className={cn('sidebar-logo')} href={href} onClick={onClick}>
        {logo}
      </a>
      <p className={cn('sidebar-name')}>{label}</p>
    </div>
  );
};

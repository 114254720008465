import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { BaseModalProps } from './types';

/**
 * ModalHeading
 *
 * @memberof modal
 * @author Simon Groenborg
 */
export const ModalHeading = React.forwardRef<HTMLDivElement, BaseModalProps>(
  ({ children, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    return (
      <div className={cn('toast-heading')} ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

ModalHeading.displayName = 'Modal.Heading';

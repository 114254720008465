import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';

export enum PaginationAction {
  Next = 'Next',
  Prev = 'Prev',
  First = 'First',
  Last = 'Last',
}

export type PaginationButtonProps = {
  label?: string;
  icon?: React.ReactElement;
  withText?: boolean;
  hasFocus?: boolean;
  navAction?: PaginationAction;
  iconPosition?: string;
  onClick?: (action: PaginationAction) => void;
};

/**
 * A pagination item for handling navigation actions
 *
 * TODO: onKeyDown should be replaced with prober keyboard controls.
 * It is just to showcase the navigation and will be replaced in the future
 *
 *
 * @param label the text of the elment
 * @param icon icon of the element
 * @param onClick onClick callback supplying the reciver with the PaginationAction
 * @returns Controlled Pagination Item
 * @memberof pagination
 * @author Simon Groenborg
 */
export const PaginationButton = React.forwardRef<HTMLButtonElement, PaginationButtonProps>(
  ({ label, icon, withText, navAction, iconPosition = 'left', hasFocus, onClick }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    // eslint-disable-next-line no-null/no-null
    const sharedRef = React.useRef<HTMLButtonElement>(null);

    const onPaginationItemClick = React.useCallback(() => {
      if (onClick && navAction !== undefined) {
        onClick(navAction);
      }
    }, []);

    React.useEffect(() => {
      if (hasFocus) {
        if (sharedRef.current) {
          sharedRef.current.focus();
        }
      }
    }, [hasFocus, ref]);

    const onKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (hasFocus) {
          switch (e.key) {
            case 'Enter':
              e.preventDefault();

              if (onClick && navAction !== undefined) {
                onClick(navAction);
              }
          }
        }
      },
      [hasFocus]
    );

    return (
      <li className={cn('pagination-item', 'is-icon')}>
        <button
          data-text={label}
          onClick={onPaginationItemClick}
          onKeyDown={onKeyDown}
          ref={sharedRef}
          role="button"
          tabIndex={!hasFocus ? -1 : 0}
        >
          {iconPosition === 'right' || <Icon icon={icon} />}
          {withText ?? <span className={cn('pagination-item-text')}>{label}</span>}
          {iconPosition === 'left' || <Icon icon={icon} />}
        </button>
      </li>
    );
  }
);

// TODO: Clean up these types. They should be moved out of consts and instead decorate components
// that have statusses, sizes etc. That way they are unified across all components, don't have variations.

export const sizes = ['small', 'medium', 'large', 'default'] as const;
export type Sizes = typeof sizes[number];

export type Weights = 'regular' | 'medium' | 'bold' | 'default';

export type Levels = 'default' | 'm2' | 'm1' | '0' | '05' | '1' | '2' | '3' | '4' | '5' | '6' | '7';

export type State = 'default' | 'valid' | 'warning' | 'error';

export type Status = 'success' | 'error' | 'warning';

export type InputTypes =
  | 'text'
  | 'number'
  | 'search'
  | 'url'
  | 'tel'
  | 'email'
  | 'password'
  | string; // More types at MDN

export const SIZE_MAP = {
  xsmall: 'm2',
  small: 'm1',
  normal: '0',
  medium: '05',
  large: '1',
  xlarge: '2',
  x2large: '3',
  x3large: '4',
  x4large: '5',
  x5large: '6',
  x6large: '7',
};

export type FontSizeMap = typeof SIZE_MAP;

export const BREAK_POINTS = {
  medium: 59,
  large: 1024,
  extraLarge: 1440,
  fullWidth: 1632,
};

export type BreakPoints = typeof BREAK_POINTS;

import { SpaceValues } from '../elements/layout/types';

/**
 * useSpace
 * Turn a spaceValue into a string
 * so it can be used as a className
 *
 * @param space SpaceValue
 * @memberof hooks
 * @author Jeremy Thomas
 */
export const useSpace = (space?: SpaceValues): string => {
  if (!space) {
    return '';
  }

  return `${space}`;
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowUpBold = React.forwardRef(function ArrowUpBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M10.994 5.888a1.5 1.5 0 012.012 0l10.5 9.5a1.5 1.5 0 01-2.012 2.224L12 9.022l-9.494 8.59a1.5 1.5 0 11-2.012-2.224l10.5-9.5z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type INavigationBrandProps = {
  href?: string;
  label?: string;
  logo?: JSX.Element | React.Component | React.SVGAttributes<SVGSVGElement>;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * NavigationBrand
 * @param param0
 */
export const HeaderBrand: React.FunctionComponent<INavigationBrandProps> = ({
  label,
  logo,
  href,
  onClick,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <div className={cn('header-brand')}>
      <a className={cn('header-logo')} href={href} onClick={onClick}>
        {logo}
      </a>
      <p className={cn('header-name')}>{label}</p>
    </div>
  );
};

HeaderBrand.displayName = 'Header.Brand';

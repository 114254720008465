/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CrossBold = React.forwardRef(function CrossBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M3.06.94A1.5 1.5 0 10.94 3.06L9.878 12l-8.94 8.94a1.5 1.5 0 002.122 2.12L12 14.122l8.94 8.94a1.5 1.5 0 002.12-2.122L14.122 12l8.94-8.94A1.5 1.5 0 0020.939.94L12 9.878 3.06.939z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { ToastBody } from './ToastBody';
import { ToastHeading } from './ToastHeading';

/**
 * ToastStatus
 * Supported statusses for toasts
 */
export type ToastStatus = 'error' | 'warning' | 'information' | 'success' | 'default';

/**
 * ToastOptions
 * Options to configure a single toast
 */
export type ToastProps = {
  timeout?: number;
  status?: ToastStatus;
  callbackClickHandler?: () => void;
};

interface IToastSubComponents {
  Heading: typeof ToastHeading;
  Body: typeof ToastBody;
}

type ToastComponent = React.FunctionComponent<ToastProps> & IToastSubComponents;

/**
 * Toast
 *
 * @param param0
 * @memberof toast
 * @author Simon Groenborg
 */
export const Toast: ToastComponent = ({ children, status = 'default' }) => {
  const cn = useFrameworkClassnamePrefixer();

  const classNames = cn('toast', {
    'toast-status': status !== 'default',
    [`status-${status}`]: status !== 'default',
  });

  return (
    <article aria-labelledby={`${status}-alert`} className={classNames} role="alert">
      {children}
    </article>
  );
};

Toast.Body = ToastBody;
Toast.Heading = ToastHeading;

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LogIn = React.forwardRef(function LogIn(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20.727 1.393h.037a2.09 2.09 0 011.985 2.165v16.769a2.09 2.09 0 01-2.022 2.164H9.235a2.09 2.09 0 01-1.985-2.164v-4.185a.75.75 0 011.5 0v4.2l-.001.033a.591.591 0 00.546.616h11.409a.591.591 0 00.545-.65V3.509a.59.59 0 00-.545-.615H9.295a.591.591 0 00-.545.65v4.2a.75.75 0 01-1.5 0V3.557a2.091 2.091 0 012.023-2.165h11.454z" />
      <path d="M12.169 7.916a.75.75 0 000 1.06l2.22 2.22H1.5a.75.75 0 000 1.5h12.888l-2.22 2.22a.75.75 0 001.062 1.06l3.496-3.496a.754.754 0 00.17-.81.747.747 0 00-.167-.254l-3.5-3.5a.75.75 0 00-1.06 0z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Directions = React.forwardRef(function Directions(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M23.28 4.183l-2.47-2.469a.752.752 0 00-.531-.223h-8.246V.754A.761.761 0 0011.28 0a.761.761 0 00-.754.754v.755H3.788a.761.761 0 00-.754.754v6c0 .411.343.754.754.754h6.755v1.509H3.788a.752.752 0 00-.53.223l-2.47 2.434a1.493 1.493 0 000 2.126l2.47 2.468a.752.752 0 00.53.223h6.755v5.246c0 .411.343.754.754.754a.761.761 0 00.754-.754V18h8.246a.761.761 0 00.754-.754v-6a.761.761 0 00-.754-.755h-8.263V8.983h8.246a.752.752 0 00.531-.223l2.469-2.469c.583-.565.583-1.525 0-2.108zM19.526 16.49H4.097l-2.246-2.245L4.097 12h15.446v4.491h-.017zm2.691-11.245L19.971 7.49H4.526V3H19.97l2.246 2.246z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AccountingDocument = React.forwardRef(function AccountingDocument(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M7.597 7.03a1.471 1.471 0 011.226.58.75.75 0 101.194-.908 2.97 2.97 0 00-1.705-1.099V5.25a.75.75 0 10-1.5 0v.363c-.43.095-.828.273-1.158.52-.515.387-.904.978-.904 1.695 0 .716.389 1.308.904 1.694.515.387 1.192.604 1.908.604.422 0 .775.13 1.006.303.23.172.303.354.303.493 0 .138-.073.32-.304.494-.232.173-.586.303-1.009.303h-.03a1.475 1.475 0 01-1.231-.58.75.75 0 10-1.194.91 2.974 2.974 0 001.709 1.098v.353a.75.75 0 001.5 0v-.363c.43-.097.825-.274 1.155-.521.515-.387.904-.978.904-1.694 0-.715-.388-1.306-.902-1.693-.515-.386-1.191-.603-1.907-.603-.422 0-.776-.13-1.008-.303-.23-.174-.304-.356-.304-.495 0-.138.073-.32.304-.494.231-.173.585-.303 1.006-.303h.037zM10.75 15a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm-4.5 3.75a.75.75 0 000 1.5H17.5a.75.75 0 000-1.5H6.25z" />
      <path
        clipRule="evenodd"
        d="M22 21.75V8.12a2.25 2.25 0 00-.659-1.59L15.47.659A2.25 2.25 0 0013.88 0H3.25A2.25 2.25 0 001 2.25v19.5A2.25 2.25 0 003.25 24h16.5A2.25 2.25 0 0022 21.75zM3.25 1.5a.75.75 0 00-.75.75v19.5a.75.75 0 00.75.75h16.5a.75.75 0 00.75-.75V9h-5.25A2.25 2.25 0 0113 6.75V1.5H3.25zm12 6h4.94L14.5 1.81v4.94a.75.75 0 00.75.75z"
        fillRule="evenodd"
      />
    </svg>
  );
});

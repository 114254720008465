import * as React from 'react';
import { Sizes, Status } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  isDisabled?: boolean;
  isFlexible?: boolean;
  isRequired?: boolean;
  size?: Sizes;
  validationStatus?: Status;
};

/**
 * TextArea
 * TODO: Rename sizing to size
 * @summary use the text area where the user needs to input multiple lines of text
 */
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function Input(
  { isDisabled = false, size = 'medium', isFlexible, isRequired, validationStatus = '', ...props },
  ref
) {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <textarea
      className={cn('input', {
        [`is-${size}`]: size,
        ['is-flexible']: isFlexible,
        [`status-${validationStatus}`]: validationStatus,
        [`is-disabled`]: isDisabled,
      })}
      ref={ref}
      required={isRequired}
      {...props}
    />
  );
});

import * as React from 'react';
import { forwardRefAs } from '../polymorph';
import { Typography, TypographyProps } from './Typography';

/**
 * Paragraph
 */
export const Paragraph = forwardRefAs<TypographyProps>(({ as = 'p', ...props }, ref) => {
  return <Typography as={as} {...props} ref={ref} />;
});

import * as React from 'react';
import { ITabPanelProps, TabPanel } from './TabPanel';
import { useTabsContext } from './TabsContext';
import { makeTabId, makeTabPanelId } from './utils';

export interface ITabPanelsProps {
  children: Array<React.ReactElement<ITabPanelProps>>;
}

export const TabPanels: React.FunctionComponent<ITabPanelsProps> = ({ children }) => {
  const { id, activeTab } = useTabsContext();
  let activeChild = children[activeTab];

  if (!React.isValidElement(activeChild)) {
    activeChild = <TabPanel>Make sure to associate a tab panel for this tab</TabPanel>;
  }

  return React.cloneElement(activeChild, {
    tabIndex: 0,
    id: makeTabPanelId(id, activeTab),
    'aria-labelledby': makeTabId(id, activeTab),
    ...activeChild.props,
  });
};

TabPanels.displayName = 'Tabs.TabPanels';

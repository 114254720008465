import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * ToastBody
 *
 * @memberof toast
 * @author Simon Groenborg
 */
export const ToastBody: React.FunctionComponent<unknown> = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <div className={cn('toast-body')}>{children}</div>;
};

ToastBody.displayName = 'Toast.Body';

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { BaseModalProps } from './types';

/**
 * ModalBody
 *
 * @memberof modal
 * @author Simon Groenborg
 */
export const ModalBody = React.forwardRef<HTMLDivElement, BaseModalProps>(
  ({ children, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    return (
      <div className={cn('toast-body')} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);

ModalBody.displayName = 'Modal.Body';

import * as React from 'react';
import { Sizes } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { ContentSwitcherContext } from './ContentSwitcherContext';
import { ContentSwitcherItem } from './ContentSwitcherItem';
import { useID } from './utils';

interface IContentSwitcherSubComponents {
  Item: typeof ContentSwitcherItem;
}

export interface IContentSwitcherProps {
  initialSelectedItem?: number;
  selectedItem?: number;
  onSelectedItemChange?: (selectedItem: number) => void;
  size?: Sizes;
  isDisabled?: boolean;
}

export const ContentSwitcher: React.FunctionComponent<IContentSwitcherProps> &
  IContentSwitcherSubComponents = ({
  children,
  initialSelectedItem = 0,
  onSelectedItemChange,
  isDisabled,
  size = 'default',
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const [selectedItem, setSelectedItem] = React.useState(initialSelectedItem);
  const [focusedItem, setFocusedItem] = React.useState(selectedItem);

  React.useEffect(() => {
    onSelectedItemChange?.(selectedItem);
  }, [selectedItem, onSelectedItemChange]);

  const id = useID('switcher');

  const className = cn('switcher', {
    [`is-${size}`]: size,
    [`is-disabled`]: isDisabled,
  });

  const listClassName = cn('switcher-list');

  const contextValue = React.useMemo(
    () => ({
      id,
      selectedItem,
      setSelectedItem,
      focusedItem,
      setFocusedItem,
      onSelectedItemChange,
      isDisabled,
    }),
    [
      id,
      selectedItem,
      setSelectedItem,
      focusedItem,
      setFocusedItem,
      onSelectedItemChange,
      isDisabled,
    ]
  );

  return (
    <ContentSwitcherContext.Provider value={contextValue}>
      <div className={className} {...props}>
        <ul className={listClassName}>{children}</ul>
      </div>
    </ContentSwitcherContext.Provider>
  );
};

ContentSwitcher.Item = ContentSwitcherItem;

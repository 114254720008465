import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ITabPanelProps {
  id?: string;
  hidden?: boolean;
}

export const TabPanel: React.FunctionComponent<ITabPanelProps> = ({ children, ...props }) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <div className={cn('tabpanel')} role="tabpanel" {...props}>
      {children}
    </div>
  );
};

TabPanel.displayName = 'Tabs.TabPanel';

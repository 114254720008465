/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const TagBold = React.forwardRef(function TagBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.966.373a2.25 2.25 0 00-.527-.063h-6.257a3.752 3.752 0 00-2.65 1.098L.97 12.968a2.25 2.25 0 000 3.182l6.879 6.88a2.25 2.25 0 003.182 0l11.56-11.562a3.753 3.753 0 001.098-2.65V2.56A2.25 2.25 0 0021.966.373z" />
    </svg>
  );
});

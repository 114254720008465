/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const WifiOn = React.forwardRef(function WifiOn(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M9.348 16.098a3.75 3.75 0 115.304 5.304 3.75 3.75 0 01-5.304-5.304zM12 16.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-5.836-3.583a8.25 8.25 0 0111.666 0 .75.75 0 01-1.06 1.06 6.75 6.75 0 00-9.546 0 .75.75 0 11-1.06-1.06z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M2.985 9.735a12.75 12.75 0 0118.03 0 .75.75 0 01-1.06 1.06 11.25 11.25 0 00-15.91 0 .75.75 0 11-1.06-1.06z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M.239 6.132a17.25 17.25 0 0123.522 0A.75.75 0 1122.74 7.23a15.75 15.75 0 00-21.478 0A.75.75 0 01.24 6.132z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardSubheading
 * @param param0
 */
export const CardSubHeading: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-subheading')}>{children}</div>;
};

CardSubHeading.displayName = 'Card.SubHeading';

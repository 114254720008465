/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const UsbStick = React.forwardRef(function UsbStick(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M11.25 3a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5h-1.5z" />
      <path
        clipRule="evenodd"
        d="M7.5.75A.75.75 0 018.25 0h7.5a.75.75 0 01.75.75v5.379A2.25 2.25 0 0118 8.25V18a6 6 0 11-12 0V8.25a2.25 2.25 0 011.5-2.121V.75zM9 1.5h6V6H9V1.5zM7.72 7.72a.75.75 0 01.53-.22h7.5a.75.75 0 01.75.75V18a4.5 4.5 0 11-9 0V8.25a.75.75 0 01.22-.53z"
        fillRule="evenodd"
      />
    </svg>
  );
});

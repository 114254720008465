/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const History = React.forwardRef(function History(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M17.987 10.535v-.788h1.508a.76.76 0 00.753-.753.76.76 0 00-.753-.754h-4.506a.76.76 0 00-.754.754.76.76 0 00.754.753h1.508v.788c-3.375.377-5.996 3.238-5.996 6.698 0 3.718 3.032 6.75 6.75 6.75 3.717 0 6.749-3.032 6.749-6.75a6.78 6.78 0 00-6.013-6.698zm-.754 11.94a5.242 5.242 0 110-10.484 5.242 5.242 0 010 10.484z" />
      <path d="M19.272 17.233h-1.285v-2.998a.76.76 0 00-.754-.753.76.76 0 00-.753.754v3.751a.76.76 0 00.753.754h2.039a.76.76 0 00.754-.754.76.76 0 00-.754-.754zM8.24 15.743H1.884a.38.38 0 01-.377-.377V5.242h17.234v.754a.76.76 0 00.754.753.76.76 0 00.753-.753V1.867C20.231.84 19.392 0 18.381 0H1.884A1.864 1.864 0 000 1.867v13.516a1.898 1.898 0 001.884 1.867h6.373a.76.76 0 00.754-.753c0-.411-.36-.754-.771-.754zM1.867 1.507h16.48c.205 0 .377.172.377.36v1.885H1.507V1.867c0-.205.155-.36.36-.36z" />
    </svg>
  );
});

import * as React from 'react';
import { Status } from '../../constants';

export type RadioButtonProps = {
  isDisabled?: boolean;
  isError?: boolean;
  label?: string;
  status?: Status;
  value?: string;
  name?: string;
  checked?: boolean;
};

export type RadioAria = React.InputHTMLAttributes<HTMLInputElement>;

/**
 * useRadio
 *
 * @param param0
 * @memberof radio
 * @author Simon Groenborg
 */
export const useRadio = ({
  label,
  value,
  isDisabled,
  isError,
  name,
}: RadioButtonProps): RadioAria => {
  const shouldBeDisabled = isDisabled ?? isError;
  return React.useMemo(
    () => ({
      'aria-label': label,
      'aria-disabled': shouldBeDisabled,
      disabled: shouldBeDisabled,
      tabIndex: shouldBeDisabled ? -1 : 0,
      type: 'radio',
      name,
      value,
    }),
    [isDisabled, isError, label, value]
  );
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const SelectBold = React.forwardRef(function SelectBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M11.517.75c-.29 0-.551.088-.74.277-.19.189-.277.45-.277.74v2.108c0 .5.38 1.017 1.017 1.017.28 0 .51-.142.675-.321a1.49 1.49 0 00.334-.635l.007-.03v-2.14c0-.289-.088-.55-.276-.74-.19-.188-.45-.276-.74-.276zM7.86 2.452a1.002 1.002 0 00-1.408 0 1.002 1.002 0 000 1.408L7.99 5.3c.14.14.392.263.653.263a.56.56 0 00.16-.028c.047-.014.095-.033.142-.053.09-.038.192-.09.285-.136l.04-.02.029-.028a1.002 1.002 0 000-1.408L7.86 2.452zm7.217 0a1.002 1.002 0 011.408 0 1.002 1.002 0 010 1.408L15.02 5.326l-.058.03c-.175.087-.413.207-.665.207a.98.98 0 01-.656-.265 1.002 1.002 0 010-1.408l1.437-1.438zm-9.31 3.281c-.638 0-1.017.517-1.017 1.017 0 .5.38 1.017 1.017 1.017h2.108c.5 0 1.017-.38 1.017-1.017 0-.637-.517-1.017-1.017-1.017H5.767zm8.556.277c.189-.189.45-.277.74-.277h2.108c.5 0 1.017.38 1.017 1.017 0 .29-.088.55-.277.74-.19.189-.45.277-.74.277h-2.108c-.501 0-1.017-.38-1.017-1.017 0-.29.088-.55.277-.74zM9.983 24h8.625c.384 0 .767-.288.767-.67l.48-5.367c.19-1.821-1.055-3.45-2.78-3.834l-3.45-.766V8.188c0-1.15-.958-2.109-2.204-2.109-1.246 0-2.204.958-2.204 2.109v8.05l-.48-.384c0-.096-.095-.096-.095-.096-1.054-.67-2.396-.383-3.067.575-.48.767-.48 1.725.096 2.492l3.737 4.887c.096.192.384.288.575.288z" />
    </svg>
  );
});

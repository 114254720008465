import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { HeaderBrand } from './HeaderBrand';
import { HeaderDropdown } from './HeaderDropdown';
import { HeaderGroup } from './HeaderGroup';
import { HeaderIconGroup } from './HeaderIconGroup';
import { HeaderItem } from './HeaderItem';

export type HeaderProps = React.HTMLAttributes<HTMLElement> & {
  size?: number;
};

export interface IHeaderSubComponents
  extends React.ForwardRefExoticComponent<HeaderProps & React.RefAttributes<HTMLElement>> {
  Item: typeof HeaderItem;
  Brand: typeof HeaderBrand;
  Group: typeof HeaderGroup;
  Dropdown: typeof HeaderDropdown;
  IconGroup: typeof HeaderIconGroup;
}

/**
 * Navigation
 * TODO: Add search to the Header
 * ! should the Header be in charge of the search or should the search be composed
 */
export const Header = React.forwardRef<HTMLElement, HeaderProps>(function Header(
  { children, ...props },
  ref
) {
  const cn = useFrameworkClassnamePrefixer();
  return (
    <header className={cn('header')} ref={ref} {...props}>
      <div className={cn('header-body')}>{children}</div>
    </header>
  );
}) as IHeaderSubComponents;

Header.Item = HeaderItem;
Header.Brand = HeaderBrand;
Header.Dropdown = HeaderDropdown;
Header.IconGroup = HeaderIconGroup;
Header.Group = HeaderGroup;

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const EditBold = React.forwardRef(function EditBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M19.592 0a4.39 4.39 0 013.128 1.28l.002.002a4.386 4.386 0 01.076 6.115 1.017 1.017 0 00-.09-.103l-6-6a1.014 1.014 0 00-.104-.09A4.388 4.388 0 0119.593 0zm1.701 8.707a.962.962 0 00.103.09L9.304 20.89 3.11 14.695 15.201 2.603a.993.993 0 00.092.104l6 6zm-19.509 7.49l-1.76 6.866a.75.75 0 00.912.913l6.865-1.76-6.017-6.019z" />
    </svg>
  );
});

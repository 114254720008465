/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowLongUp = React.forwardRef(function ArrowLongUp(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M11.496.945a.75.75 0 011.008 0l8.002 7.272a.75.75 0 11-1.009 1.11L12.75 3.195V22.5a.75.75 0 01-1.5 0V3.195l-6.746 6.13a.75.75 0 01-1.008-1.11l8-7.27z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardLinks
 * @param param0 props
 */
export const CardLinks: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-links')}>{children}</div>;
};

CardLinks.displayName = 'Card.Links';

import * as React from 'react';

export type SwitchProps = {
  isDisabled?: boolean;
  defaultChecked?: boolean;
  onSwitch?: (value: boolean) => void;
};

export type RadioAria = React.HTMLAttributes<HTMLElement> & {
  checked?: boolean;
};

/**
 * useSwitch
 * Aria and draggable attributes for a switch component
 *
 * @param param0
 * @returns Switch Aria Hook
 * @memberof switch
 * @author Simon Groenborg
 */
export const useSwitch = ({ isDisabled, defaultChecked }: SwitchProps): RadioAria => {
  const [isOn, setIsOn] = React.useState<boolean>(!!defaultChecked);

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    switch (e.key) {
      case 'Enter':
      case ' ':
        e.preventDefault();
        setIsOn((prev) => !prev);
        return;
    }
  };

  const onClick = React.useCallback(() => {
    setIsOn((prev) => !prev);
  }, [isOn]);

  return {
    checked: isOn,
    role: 'switch',
    tabIndex: isDisabled ? -1 : 0,
    'aria-checked': isOn,
    'aria-disabled': isDisabled,
    onClick,
    onKeyDown,
  };
};

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardHeading
 * @param param0
 */
export const CardHeading: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-heading')}>{children}</div>;
};

CardHeading.displayName = 'Card.Heading';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FeedScrollBold = React.forwardRef(function FeedScrollBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M5.92 16.823a.253.253 0 00-.041-.219.248.248 0 00-.2-.1H5.5a1 1 0 01-1-1v-12a1 1 0 011-1h14a1 1 0 011 1v10.678c0 .117.08.218.194.244 1.084.25 2.08.787 2.884 1.555A.25.25 0 0024 15.8V2.505a2.5 2.5 0 00-2.5-2.5h-19a2.5 2.5 0 00-2.5 2.5v14a2.5 2.5 0 002.5 2.5h3.152a.251.251 0 00.242-.312 3.6 3.6 0 01.026-1.87z" />
      <path d="M23 24a.75.75 0 00.75-.75v-3A4.756 4.756 0 0019 15.5h-2a.25.25 0 01-.25-.25v-5.5a2.25 2.25 0 00-4.5 0v7.06a.25.25 0 01-.442.16l-.578-.7a2.25 2.25 0 00-3.46 2.877l3.806 4.579c.143.172.354.27.577.271L23 24z" />
    </svg>
  );
});

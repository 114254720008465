import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export interface IDividerProps {
  isVertical?: boolean;
}

/**
 *  Divider
 *  Use the divider to seperate content, both vertically and horizontally
 *
 * @param isVertical
 * @returns Divider
 * @memberof divider
 * @author Simon Groenborg
 */
export const Divider: React.FunctionComponent<IDividerProps> = ({ isVertical = false }) => {
  const cn = useFrameworkClassnamePrefixer();
  return (
    <hr
      className={cn('divider', {
        'is-vertical': isVertical,
      })}
    />
  );
};

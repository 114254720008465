/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const UnlockBold = React.forwardRef(function UnlockBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M1.917 1.917a6.545 6.545 0 0111.174 4.628V8.6H23.23a.77.77 0 01.77.77v13.86a.77.77 0 01-.77.771H6.289a.77.77 0 01-.77-.77V9.369a.77.77 0 01.77-.77h4.492V6.545a4.235 4.235 0 10-8.47 0v2.31a1.155 1.155 0 11-2.311 0v-2.31c0-1.736.69-3.4 1.917-4.628zM14.76 13.604c-.638 0-1.155.517-1.155 1.155v3.08a1.155 1.155 0 102.31 0v-3.08c0-.638-.517-1.155-1.155-1.155z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LogInBold = React.forwardRef(function LogInBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20.727.5a2.84 2.84 0 012.772 2.928V20.17a2.841 2.841 0 01-2.772 2.928H9.273c-.025 0-.05 0-.075-.002A2.841 2.841 0 016.5 20.17V18a1.5 1.5 0 013 0v2.099h10.999V3.5h-11V6a1.5 1.5 0 11-3 0V3.427A2.841 2.841 0 019.274.5h11.454z" />
      <path d="M11.639 7.243a1.5 1.5 0 000 2.12l.939.94H1.5a1.5 1.5 0 100 3h11.078l-.94.94a1.5 1.5 0 102.122 2.12l3.495-3.494a1.495 1.495 0 00.334-1.631 1.496 1.496 0 00-.33-.496l-3.5-3.5a1.5 1.5 0 00-2.12 0z" />
    </svg>
  );
});

import * as React from 'react';
import { useClickOutside } from '../../hooks';

type DropdownHookAction = {
  toggleVisible: () => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
};

type DropdownOptions = {
  inheritWidth?: boolean;
  closeOnClickOutside?: boolean;
};

const defaultOptions: DropdownOptions = {
  inheritWidth: false,
  closeOnClickOutside: true,
};

const DROPDOWN_OFFSET = 8;

/**
 * useDropdown hook
 * action for toggleling and positioning a dropdown associated with an element.
 * use this hook in combination with the Dropdown component
 * TODO: the component must also update the width when an item is selected within the dropdown! because the
 * Select components expands dynamically
 *
 * @param dropdown ref of the Dropdown component
 * @param target ref of the targetet input associated with the dropdown
 * @returns DropdownHookAction for toggeling the inputs
 */
export const useDropdown = (
  dropdown: React.RefObject<HTMLElement>,
  target: React.RefObject<HTMLElement | SVGSVGElement>,
  options = defaultOptions
): DropdownHookAction => {
  const [rect, setRect] = React.useState<DOMRect | undefined>();
  const [isOpen, setVisible] = React.useState<boolean>(false);

  useClickOutside(
    dropdown,
    () => {
      if (options.closeOnClickOutside) {
        setVisible(false);
      }
    },
    [options.closeOnClickOutside]
  );

  const toggleVisible = (): void => setVisible((prev) => !prev);

  const setPosition = (domRect?: DOMRect): void => {
    if (dropdown.current && domRect) {
      const { top, left, height, width } = domRect;

      dropdown.current.style.position = 'absolute';
      dropdown.current.style.top = `${top + height + DROPDOWN_OFFSET}px`;
      dropdown.current.style.left = `${left}px`;

      if (options.inheritWidth) {
        dropdown.current.style.width = `${width}px`;
      }
    }
  };

  const updateRect = (): void => {
    if (dropdown.current && target.current) {
      setRect(target.current.getBoundingClientRect());
    }
  };

  // Initialize the position of the dropdown.
  // TODO: document event listeners should be removed in the future.
  React.useLayoutEffect(() => {
    if (dropdown.current && target.current) {
      setRect(target.current.getBoundingClientRect());
    }

    window.addEventListener('resize', updateRect);

    return (): void => {
      window.removeEventListener('resize', updateRect);
    };
  }, [target, dropdown]);

  React.useLayoutEffect(() => {
    setPosition(rect);
  }, [rect]);

  React.useEffect(() => {
    updateRect();

    if (dropdown.current) {
      if (isOpen) {
        setPosition(rect);
      }

      dropdown.current.style.display = isOpen ? '' : 'none';
    }
  }, [isOpen, dropdown]);

  return {
    setVisible,
    toggleVisible,
    isOpen,
  };
};

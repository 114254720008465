/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Newspaper = React.forwardRef(function Newspaper(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M2.25 0h19.5A2.25 2.25 0 0124 2.25v19.5A2.25 2.25 0 0121.75 24H2.25A2.25 2.25 0 010 21.75V2.25A2.25 2.25 0 012.25 0zm19.5 1.5H2.25a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75zm-1.5 12a.75.75 0 00-.75-.75h-6l-.102.007a.75.75 0 00.102 1.493h6l.102-.007a.75.75 0 00.648-.743zm-.75 2.25a.75.75 0 01.102 1.493l-.102.007h-6a.75.75 0 01-.102-1.493l.102-.007h6zM18 19.5a.75.75 0 00-.75-.75H13.5l-.102.007a.75.75 0 00.102 1.493h3.75l.102-.007A.75.75 0 0018 19.5zm-8.25-6.75a.75.75 0 01.102 1.493l-.102.007h-6a.75.75 0 01-.102-1.493l.102-.007h6zm.75 3.75a.75.75 0 00-.75-.75h-6l-.102.007a.75.75 0 00.102 1.493h6l.102-.007a.75.75 0 00.648-.743zm-3 2.25a.75.75 0 01.102 1.493l-.102.007H3.75a.75.75 0 01-.102-1.493l.102-.007H7.5zM4.5 3h15A1.5 1.5 0 0121 4.5V9a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 9V4.5A1.5 1.5 0 014.5 3zm15 1.5h-15V9h15V4.5z"
        fillRule="evenodd"
      />
    </svg>
  );
});

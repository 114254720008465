/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AdvertisingMegaphoneBold = React.forwardRef(function AdvertisingMegaphoneBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M23.86 14.293l-2.077-3.561a.258.258 0 01-.033-.149l.32-4.154c.038-.49-.27-.936-.73-1.052l-3.885-.987a.242.242 0 01-.126-.081l-2.592-3.174a.934.934 0 00-1.23-.206l-3.418 2.163a.23.23 0 01-.144.035l-3.988-.334a.989.989 0 00-1.01.76L3.907 8c-.126.537.19 1.078.705 1.209.515.13 1.035-.198 1.16-.735l.798-3.407a.241.241 0 01.253-.189l3.36.281a.952.952 0 00.576-.143l2.88-1.82a.232.232 0 01.307.052l2.172 2.666c.131.16.307.273.503.323l3.271.831a.25.25 0 01.183.263l-.27 3.5c-.017.21.031.42.136.6l1.748 3a.26.26 0 01-.05.32l-2.564 2.263c-.154.137-.262.32-.31.525l-.798 3.406a.241.241 0 01-.252.19l-3.355-.281c-.529-.044-.992.366-1.034.917-.043.55.351 1.033.88 1.077l4.374.366h.079a.968.968 0 00.93-.762l.95-4.049a.25.25 0 01.077-.131l3.046-2.7a1.03 1.03 0 00.198-1.279z" />
      <path
        clipRule="evenodd"
        d="M1.04 19.826a.517.517 0 01.03-.6L9.017 9.093a.257.257 0 00.015-.3l-.069-.106a.77.77 0 01.202-1.04.703.703 0 01.998.21l6.124 9.607a.77.77 0 01-.201 1.04.693.693 0 01-.398.125.715.715 0 01-.6-.335l-.076-.117a.234.234 0 00-.252-.1l-4.122.964a.24.24 0 00-.157.121.258.258 0 00-.018.203c.458 1.37-.136 2.88-1.386 3.52-1.25.64-2.761.208-3.522-1.006l-.576-.91a.234.234 0 00-.27-.1l-2.166.675a.47.47 0 01-.537-.2l-.967-1.518zm5.722 1.222a1.288 1.288 0 001.83.385c.532-.37.732-1.084.476-1.694a.238.238 0 00-.288-.139l-2.144.67a.246.246 0 00-.156.155.26.26 0 00.025.223l.257.4z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DownwardTrend = React.forwardRef(function DownwardTrend(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={25} ref={svgRef} viewBox="0 0 24 25" width={24} {...props}>
      <path d="M3.817.44A.75.75 0 014.81.067l14.42 6.549-.558-1.486a.75.75 0 111.404-.527l1.194 3.18.012.033a.747.747 0 01-.45.933L17.65 9.943a.75.75 0 11-.527-1.404l1.485-.558L4.19 1.433A.75.75 0 013.818.44z" />
      <path
        clipRule="evenodd"
        d="M3 9a1.5 1.5 0 00-1.5 1.5v12H.75a.75.75 0 000 1.5h22.5a.75.75 0 000-1.5h-.75V18a1.5 1.5 0 00-1.5-1.5h-3a1.5 1.5 0 00-1.5 1.5v4.5H15v-9a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 13.5v9H7.5v-12A1.5 1.5 0 006 9H3zm3 13.5v-12H3v12h3zm7.5-9v9h-3v-9h3zm7.5 9V18h-3v4.5h3z"
        fillRule="evenodd"
      />
    </svg>
  );
});

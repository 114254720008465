/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CloudUploadDownload = React.forwardRef(function CloudUploadDownload(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M9.11 13.46v8.18a.71.71 0 101.41 0v-8.18l1.62 1.62a.71.71 0 101-1l-2.82-2.82a.7.7 0 00-.5-.26.7.7 0 00-.49.2L6.5 14.08a.71.71 0 001 1l1.61-1.62zm6.41-2.01a.71.71 0 01.71.71v8.18l1.62-1.62a.711.711 0 111 1L16 22.54a.7.7 0 01-.46.21.7.7 0 01-.49-.21l-2.82-2.82a.71.71 0 011-1l1.62 1.62v-8.18a.71.71 0 01.67-.71z" />
      <path d="M4.66 16.41h-.2C3.07 16 .58 14.52 1.14 11.58A4.34 4.34 0 015.13 8a7.73 7.73 0 012.46-4.45 6.84 6.84 0 018-.51 7.16 7.16 0 012.6 2.69 5.38 5.38 0 014.68 4.75A5.86 5.86 0 0120.1 16a.75.75 0 11-.77-1.29 4.36 4.36 0 002-4.06 4 4 0 00-3.66-3.48.94.94 0 01-.76-.55 5.49 5.49 0 00-2.14-2.31 5.41 5.41 0 00-6.22.36 6.35 6.35 0 00-2 4 .88.88 0 01-.86.78 3 3 0 00-3.07 2.39c-.43 2.26 2 3 2.25 3.07a.75.75 0 01-.2 1.47l-.01.03z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowDownBold = React.forwardRef(function ArrowDownBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M2.506 6.388A1.5 1.5 0 00.494 8.612l10.5 9.5a1.5 1.5 0 002.012 0l10.5-9.5a1.5 1.5 0 00-2.012-2.224L12 14.978l-9.494-8.59z" />
    </svg>
  );
});

// Utilities
// These types are relied upon by the forwardRefAs functin and Polymorph component
// NOTE: Please don't modify these types without asserting that the typying tests
// are still functioning
// parts of this code is based on https://github.com/kripod/react-polymorphic-box/blob/main/src/Box.tsx

/**
 * Based on https://github.com/dfee/rbx/blob/master/src/base/exotic.ts
 * Maps a keyof JSX.IntrinsicElement (e.g. 'div' or 'svg') or a
 * React.ComponentType to it's type.
 *
 * For example:
 *   FromReactElement<"div"> ==> HTMLDivElement
 *   FromReactElement<React.FunctionComponent<P>. ==> React.FunctionComponent<P>
 */
export type FromElementType<T extends React.ElementType> = T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T] extends React.DetailedHTMLFactory<
      React.HTMLAttributes<infer U>,
      infer U
    >
    ? U
    : JSX.IntrinsicElements[T] extends React.SVGProps<infer V>
    ? V
    : never
  : T;

/**
 * Remove the defaultProps property of the ExoticComponent
 */
export type ExcludeDefaultProp<T> = Omit<React.ForwardRefExoticComponent<T>, 'defaultProps'>;

/**
 * Select the properties of P over the same properties in T
 */
export type Prefer<TPreferred, T> = TPreferred & Omit<T, keyof TPreferred>;

/**
 * IPolymorphProps
 * Each Polymorphed component needs to extend this interface
 *
 * @summary React.ElementType is the type of every intrinsic element or Component
 * To make the polymorph element and forwardRefAs understand what type it is
 * we scope the type to the property "as" so typescript knowns what property to use when infering
 * the type of component
 */
export type IPolymorphProps<E extends React.ElementType = React.ElementType> = React.HTMLAttributes<
  unknown
> & {
  as?: E;
};

/**
 * ForwardRefAsProps
 * the props accepted by the render function. It is both the props comming from an intrinsic element
 * as the as the props provided.
 */
export type ForwardRefAsProps<TProps, TComponent extends React.ElementType> = Prefer<
  IPolymorphProps<TComponent> & TProps,
  React.ComponentProps<TComponent>
> &
  React.RefAttributes<
    TComponent extends keyof JSX.IntrinsicElements ? FromElementType<TComponent> : TComponent
  >;

/**
 * ForwardRefAsExoticComponent
 * returntype definition for the forwardRefAs function
 */
export type ForwardRefAsExoticComponent<
  TProps,
  TComponent extends React.ElementType
> = ExcludeDefaultProp<TComponent> & {
  <TAsComponent extends React.ElementType = TComponent>(
    props: ForwardRefAsProps<TProps, TAsComponent>
  ): JSX.Element | null;
  displayName: string;
};

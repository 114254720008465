/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { MenuTrigger } from './MenuTrigger';

export type Context = 'li' | 'div';

// TODO: not all props are used, refactor!
export type InternalElementProps = {
  behaviour?: 'dropdown' | 'link' | 'default' | 'blocks' | 'stack';
  isActive?: boolean;
  isOpen?: boolean;
  _context?: Context;
  children: string | JSX.Element | JSX.Element[];
};

export type ElementProps = Omit<InternalElementProps, '_context'>;

type MenuItemStylingContext = {
  [key in Context]: {
    wrapper: string;
    element: string;
  };
};

const contextMap: MenuItemStylingContext = {
  li: {
    wrapper: 'menu-item',
    element: 'menu-trigger',
  },
  div: {
    wrapper: 'menu-item',
    element: 'menu-trigger',
  },
};

/**
 * MenuItem
 * TODO: Add dynamic typing to the ref and expose the ref
 */
export const MenuItem: React.FunctionComponent<InternalElementProps> = ({
  children,
  isActive,
  _context = 'div',
  isOpen,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const Component = _context;

  const wrapperClasses = cn({
    [contextMap[_context].wrapper]: true,
    'is-active': isActive,
    'is-open': isOpen,
  });

  if (typeof children === 'string') {
    return (
      <Component className={wrapperClasses}>
        <MenuTrigger>{children}</MenuTrigger>
      </Component>
    );
  }

  return <Component className={wrapperClasses}>{children}</Component>;
};

MenuItem.displayName = 'Menu.Item';

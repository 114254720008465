/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const EditFileBold = React.forwardRef(function EditFileBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M3 6.451h6.9a1.5 1.5 0 100-3h-7a2.9 2.9 0 00-2.9 2.9v14a2.9 2.9 0 002.9 2.9h14a2.9 2.9 0 002.9-2.9v-7a1.5 1.5 0 10-3 0v6.9H3v-13.8z"
        fillRule="evenodd"
      />
      <path d="M21.722 5.558a.772.772 0 00-.015-.015l-4.015-4.015.692-.693a2.85 2.85 0 014.031 4.03l-.693.693zm-1.414 1.414a1.652 1.652 0 01-.015-.015l-4.015-4.015-6.803 6.802a.75.75 0 00-.212.425l-.495 3.465a.75.75 0 00.849.849l3.464-.496a.75.75 0 00.424-.212l6.803-6.803z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ViewShowBold = React.forwardRef(function ViewShowBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M12.436 4.502h-.315c-3.592-.066-7.594 2.36-10.545 5.854a2.54 2.54 0 00-.002 3.219c2.863 3.392 6.7 5.786 10.236 5.86h.31c3.597.066 7.577-2.368 10.525-5.858a2.54 2.54 0 00.002-3.218C19.79 6.98 15.935 4.586 12.436 4.502zm-.327 10.952c1.79 0 3.24-1.56 3.24-3.485 0-1.925-1.45-3.486-3.24-3.486-1.79 0-3.24 1.56-3.24 3.486 0 1.925 1.45 3.485 3.24 3.485z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const BusinessDeal = React.forwardRef(function BusinessDeal(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#business-deal_svg__business-deal_svg__clip0)">
        <path d="M4.626 14.25a3.375 3.375 0 100 6.75 3.375 3.375 0 000-6.75zm0 1.5a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z" />
        <path d="M4.624 20.25a5.214 5.214 0 014.527 2.628.75.75 0 01-1.302.744 3.714 3.714 0 00-6.45 0 .75.75 0 01-1.302-.744 5.214 5.214 0 014.527-2.628zm14.75-6a3.375 3.375 0 100 6.75 3.375 3.375 0 000-6.75zm0 1.5a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z" />
        <path d="M19.374 20.25a5.214 5.214 0 014.527 2.628.75.75 0 01-1.302.744 3.714 3.714 0 00-6.45 0 .75.75 0 01-1.302-.744 5.214 5.214 0 014.527-2.628zM14.625 1.5a.75.75 0 01.102 1.493L14.625 3h-2.627c-.284 0-.528.2-.582.479a.602.602 0 00.24.606l.067.036 2.71.845a2.092 2.092 0 01-.612 4.028L13.655 9H10.5a.75.75 0 01-.102-1.493L10.5 7.5h3.156c.284 0 .528-.2.582-.48a.603.603 0 00-.239-.606l-.066-.036-2.71-.844a2.091 2.091 0 01.611-4.028L12 1.5h2.625z" />
        <path d="M12.75 0a.75.75 0 01.743.648L13.5.75v1.5a.75.75 0 01-1.493.102L12 2.25V.75a.75.75 0 01.75-.75zm0 7.5a.75.75 0 01.743.648l.007.102v1.5a.75.75 0 01-1.493.102L12 9.75v-1.5a.75.75 0 01.75-.75z" />
        <path d="M8.25 0a.75.75 0 01.102 1.493L8.25 1.5h-3a.75.75 0 00-.743.648L4.5 2.25v8.25c0 .38.282.694.648.743l.102.007h6a.75.75 0 01.743.648L12 12v2.688l3.22-3.218a.75.75 0 01.412-.21l.118-.01h4.5a.75.75 0 00.743-.648L21 10.5V2.25a.75.75 0 00-.648-.743L20.25 1.5H18a.75.75 0 01-.102-1.493L18 0h2.25a2.25 2.25 0 012.245 2.096l.005.154v8.25a2.25 2.25 0 01-2.096 2.245l-.154.005h-4.19l-4.28 4.28a.75.75 0 01-1.273-.427L10.5 16.5v-3.75H5.25a2.25 2.25 0 01-2.245-2.096L3 10.5V2.25A2.25 2.25 0 015.096.005L5.25 0h3z" />
      </g>
      <defs>
        <clipPath id="business-deal_svg__business-deal_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

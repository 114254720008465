/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowLeft = React.forwardRef(function ArrowLeft(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M17.505.445a.75.75 0 01.05 1.06L8.014 12l9.541 10.495a.75.75 0 01-1.11 1.01l-10-11a.75.75 0 010-1.01l10-11a.75.75 0 011.06-.05z"
        fillRule="evenodd"
      />
    </svg>
  );
});

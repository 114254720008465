/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const RotateRight = React.forwardRef(function RotateRight(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M23.257 2.002a.75.75 0 00-1.5 0v4.714a10.999 10.999 0 00-18.484-.813 10.997 10.997 0 008.987 17.343.75.75 0 100-1.5 9.496 9.496 0 118.528-13.663h-5.113a.75.75 0 000 1.5h6.2a.744.744 0 00.078 0h.554a.75.75 0 00.75-.75V2.002z" />
    </svg>
  );
});

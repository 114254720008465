{
    "name": "@aws-sdk/client-cognito-identity",
    "description": "AWS SDK for JavaScript Cognito Identity Client for Node.js, Browser and React Native",
    "version": "1.0.0-rc.4",
    "scripts": {
        "clean": "npm run remove-definitions && npm run remove-dist",
        "build-documentation": "npm run clean && typedoc ./",
        "prepublishOnly": "yarn build",
        "pretest": "yarn build:cjs",
        "remove-definitions": "rimraf ./types",
        "remove-dist": "rimraf ./dist",
        "remove-documentation": "rimraf ./docs",
        "test:unit": "mocha **/cjs/**/*.spec.js",
        "test:e2e": "mocha **/cjs/**/*.ispec.js && karma start karma.conf.js",
        "test": "yarn test:unit",
        "build:cjs": "tsc -p tsconfig.json",
        "build:es": "tsc -p tsconfig.es.json",
        "build": "yarn build:cjs && yarn build:es"
    },
    "main": "./dist/cjs/index.js",
    "types": "./types/index.d.ts",
    "module": "./dist/es/index.js",
    "browser": {
        "./runtimeConfig": "./runtimeConfig.browser"
    },
    "react-native": {
        "./runtimeConfig": "./runtimeConfig.native"
    },
    "sideEffects": false,
    "dependencies": {
        "@aws-crypto/sha256-browser": "^1.0.0",
        "@aws-crypto/sha256-js": "^1.0.0",
        "@aws-sdk/config-resolver": "1.0.0-rc.3",
        "@aws-sdk/credential-provider-node": "1.0.0-rc.3",
        "@aws-sdk/fetch-http-handler": "1.0.0-rc.3",
        "@aws-sdk/hash-node": "1.0.0-rc.3",
        "@aws-sdk/invalid-dependency": "1.0.0-rc.3",
        "@aws-sdk/middleware-content-length": "1.0.0-rc.3",
        "@aws-sdk/middleware-host-header": "1.0.0-rc.3",
        "@aws-sdk/middleware-logger": "1.0.0-rc.4",
        "@aws-sdk/middleware-retry": "1.0.0-rc.4",
        "@aws-sdk/middleware-serde": "1.0.0-rc.3",
        "@aws-sdk/middleware-signing": "1.0.0-rc.3",
        "@aws-sdk/middleware-stack": "1.0.0-rc.4",
        "@aws-sdk/middleware-user-agent": "1.0.0-rc.3",
        "@aws-sdk/node-config-provider": "1.0.0-rc.3",
        "@aws-sdk/node-http-handler": "1.0.0-rc.3",
        "@aws-sdk/protocol-http": "1.0.0-rc.3",
        "@aws-sdk/smithy-client": "1.0.0-rc.4",
        "@aws-sdk/types": "1.0.0-rc.3",
        "@aws-sdk/url-parser-browser": "1.0.0-rc.3",
        "@aws-sdk/url-parser-node": "1.0.0-rc.3",
        "@aws-sdk/util-base64-browser": "1.0.0-rc.3",
        "@aws-sdk/util-base64-node": "1.0.0-rc.3",
        "@aws-sdk/util-body-length-browser": "1.0.0-rc.3",
        "@aws-sdk/util-body-length-node": "1.0.0-rc.3",
        "@aws-sdk/util-user-agent-browser": "1.0.0-rc.3",
        "@aws-sdk/util-user-agent-node": "1.0.0-rc.3",
        "@aws-sdk/util-utf8-browser": "1.0.0-rc.3",
        "@aws-sdk/util-utf8-node": "1.0.0-rc.3",
        "tslib": "^2.0.0"
    },
    "devDependencies": {
        "@aws-sdk/client-documentation-generator": "1.0.0-rc.3",
        "@aws-sdk/client-iam": "1.0.0-rc.4",
        "@types/chai": "^4.2.11",
        "@types/mocha": "^7.0.2",
        "@types/node": "^12.7.5",
        "jest": "^26.1.0",
        "rimraf": "^3.0.0",
        "typedoc": "^0.17.8",
        "typescript": "~4.0.2"
    },
    "engines": {
        "node": ">=10.0.0"
    },
    "author": {
        "name": "AWS SDK for JavaScript Team",
        "url": "https://aws.amazon.com/javascript/"
    },
    "license": "Apache-2.0",
    "homepage": "https://github.com/aws/aws-sdk-js-v3/tree/master/clients/client-cognito-identity",
    "repository": {
        "type": "git",
        "url": "https://github.com/aws/aws-sdk-js-v3.git",
        "directory": "clients/client-cognito-identity"
    }
}

/* eslint-disable */
import * as React from 'react';
import { useRef, useState } from 'react';
import { DateUtils, DayPickerInputProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

export type DateRangeState = {
  start: Date | undefined;
  end: Date | undefined;
};

type DateRangeStatus = 'default' | 'valid' | 'warning' | 'error';

export interface IDateRangePickerProps {
  onChange: (value: DateRangeState) => void;
  initialValue?: DateRangeState;
  startLabel?: string;
  endLabel?: string;
  startStatus?: DateRangeStatus;
  endStatus?: DateRangeStatus;
  startInputProps?: Omit<React.HTMLProps<HTMLInputElement>, 'value'>;
  endInputProps?: Omit<React.HTMLProps<HTMLInputElement>, 'value'>;
  column?: boolean;
  disabled?: boolean | 'start' | 'end'; // passing true disables both inputs
  helperText?: string;
  size?: 'small' | 'medium' | 'large';
  disableAutoFocus?: boolean;
  numberOfMonths?: number;
}

// adds an inner div to each day, so we can style it
const renderDay = (date: Date): React.ReactNode => {
  return <div className="DayPicker-DayInner">{date.getDate()}</div>;
};

/**
 * Poor mans keyboard mask.
 * Adds a dash ('-') between the dates when the user enters dates,
 * if the input is at 'DD' or 'DD-MM' (to add a dash after DD or MM).
 * Except if the user deleted text using backspace or delete
 */
const maybeAddGuidingDash = (inputRef: React.RefObject<HTMLInputElement>) => (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  if (!inputRef.current) {
    return;
  }

  if (event.key === 'Backspace' || event.key === 'Delete') {
    return;
  }

  const currentValue = inputRef.current.value;

  if (!/^\d\d(-\d\d)?$/gu.test(currentValue)) {
    return;
  }

  // hacky workaround to add the dash directly to the input field, without messing with the DayPicker's state
  inputRef.current.value = `${currentValue}-`;
};

/**
 * formats a Date to a string on the format 'DD-MM-YY'
 */
const formatDate: DayPickerInputProps['formatDate'] = (date) => {
  if (!DateUtils.isDate(date)) {
    return '';
  }

  const day = date.getUTCDate().toString().padStart(2, '0'); // 9 -> '09'
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // 3 -> '04'
  const year = `${date.getUTCFullYear()}`.slice(2); // 2019 -> '19'
  return `${day}-${month}-${year}`;
};

/**
 * parses a date string from the format 'DD-MM-YY' to a Date
 * If it is invalid, it returns undefined
 */
const parseDate: DayPickerInputProps['parseDate'] = (string) => {
  if (typeof string !== 'string') {
    return undefined;
  }

  if (string.length !== 8) {
    return undefined;
  }

  const split = string.split('-');

  if (split.length !== 3) {
    return undefined;
  }

  const dayString = split[0];
  const monthString = split[1];
  const yearString = split[2];
  const dayNumber = Number.parseInt(dayString, 10);
  const monthNumber = Number.parseInt(monthString, 10) - 1;
  const yearNumber = Number.parseInt(yearString, 10);

  if (
    Number.isNaN(yearNumber) ||
    yearString.length !== 2 ||
    Number.isNaN(dayNumber) ||
    dayNumber < 1 ||
    dayNumber > 31 ||
    Number.isNaN(monthNumber) ||
    monthNumber < 0 ||
    monthNumber > 11
  ) {
    return undefined;
  }

  return new Date(`20${yearString}-${monthString}-${dayString}T12:00:00Z`); // always set noon to avoid time zone issues
};

/**
 * This component renders a date range picker, that consists of two input fields ('start' and 'end').
 * Per default a dual-month calendar date picker is opened on focus.
 * Automatic end date focus and the number of months shown can be changed with the 'disableAutoFocus' and 'numberOfMonths' props.
 * The input fields allows the user to write the dates (in the format DD-MM-YY), while the picker can be used for easier mouse input.
 * The state can be accessed via the 'onChange' prop, and be (re)set via the 'initialValue' prop.
 * The component is uncontrolled per default, but can be controlled by changing the 'initialValue' prop when the 'onChange' handler is called
 * Should the user input an invalid date - like when he is typing '31-11-...', the state will make that date 'undefined', eg. { start: undefined, end: SomeDate }
 */
// eslint-disable-next-line complexity
export const DateRangePicker: React.FC<IDateRangePickerProps> = ({
  onChange,
  initialValue,
  startLabel = 'Start',
  endLabel = 'End',
  column = false,
  size = 'medium',
  startStatus = 'default',
  endStatus = 'default',
  startInputProps,
  endInputProps,
  helperText,
  disabled = false,
  disableAutoFocus = false,
  numberOfMonths = 2,
}) => {
  const [range, setRange] = useState<DateRangeState>({
    start: undefined,
    end: undefined,
  });

  const [hoveringToDate, setHoveringToDate] = useState<Date>();
  // which month to show in the first (left) calendar when the 'end' field is open
  const [firstMonthInToCalendar, setFirstMonthInToCalendar] = useState<Date | undefined>();
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const [selectStart, setStart] = useState<boolean>(false);

  const { start, end } = range;

  // set the internal range state whenever the initialValue prop changes, making this a controlled component
  React.useEffect(() => {
    setRange(initialValue ?? { start: undefined, end: undefined });
  }, [initialValue]);

  // is called whenever 'start' or 'end' is changed, either by typing or clicking
  const handleRangeChange = (isStart: boolean) => (date: Date | undefined) => {
    setHoveringToDate(undefined);

    const nextRange = {
      start: isStart ? date : start,
      end: isStart ? end : date,
    };

    setRange(nextRange);
    onChange(nextRange);
  };

  const inputIds = {
    start: `${startLabel.replace(/\s/gu, '')}-start`,
    end: `${endLabel.replace(/\s/gu, '')}-end`,
  };

  let combinedStatus = 'default';

  if (startStatus === 'error' || endStatus === 'error') {
    combinedStatus = 'error';
  } else if (startStatus === 'warning' || endStatus === 'warning') {
    combinedStatus = 'warning';
  }

  React.useEffect(() => {
    if (start && !disableAutoFocus && selectStart) {
      endInputRef.current?.focus();
      setStart(false);
    }

    if (start && end) {
      if (start > end) {
        setRange({ start, end: undefined });
      }
    }
  }, [start, disableAutoFocus, selectStart, end]);

  return (
    <fieldset className={`DateRangePicker ${size} ${column ? 'DateRangePicker-column' : ''}`}>
      <div className="FieldsContainer">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor={inputIds.start}>{startLabel}</label>
          <DayPickerInput
            dayPickerProps={{
              selectedDays: { from: start, to: end } as any,

              // focus the 'end' input when the user selects a date
              onDayClick: () => {
                if (!disableAutoFocus) {
                  setStart(true);
                }
              },
              modifiers: { start, end },
              firstDayOfWeek: 1,
              numberOfMonths,
              showWeekNumbers: true,
              renderDay,
            }}
            formatDate={formatDate}
            inputProps={{
              ref: startInputRef,
              disabled: disabled === true || disabled === 'start',
              className: startStatus,
              // automatically add a dash to the input after 'DD' or 'DD-MM'
              onKeyUp: maybeAddGuidingDash(startInputRef),
              // select the whole text when the user focuses the input field
              onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
                event.target.select();
              },
              id: inputIds.start,
              ['aria-label']: startLabel,
              autoComplete: 'off',
              ...startInputProps,
            }}
            onDayChange={handleRangeChange(true)}
            parseDate={parseDate}
            placeholder="DD-MM-YY"
            value={start}
          />
        </div>
        <div style={{ padding: '0.5rem' }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor={inputIds.end}>{endLabel}</label>
          <DayPickerInput
            dayPickerProps={{
              // set selected days to the current 'hoveringToDate', or the 'end' date as a fallback - this is used to style the dates in between
              selectedDays: [start, { from: start, to: hoveringToDate ?? end }] as any,
              onDayMouseEnter: (date) => {
                /*
            If hovering over a date that is before 'start', we don't want to show it.
              If 'end' have already been set, set hover to that, to highlight start-end
              if 'end' have not yet been set, hover at 'start' to not highlight anything
            */
                if (start && start > date) {
                  if (end) {
                    setHoveringToDate(end);
                  } else {
                    setHoveringToDate(start);
                  }
                } else {
                  setHoveringToDate(date);
                }
              },
              // don't allow selecting a date that is before 'start'
              disabledDays: start ? { before: start } : undefined,
              modifiers: {
                start,
                end: hoveringToDate ?? end,
              },
              firstDayOfWeek: 1,
              month: firstMonthInToCalendar,
              fromMonth: start,
              numberOfMonths,
              showWeekNumbers: true,
              renderDay,
            }}
            formatDate={formatDate}
            inputProps={{
              ref: endInputRef,
              disabled: disabled === true || disabled === 'end',
              className: endStatus,
              // automatically add a dash to the input after 'DD' or 'DD-MM'
              onKeyUp: maybeAddGuidingDash(endInputRef),
              onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
                event.target.select();
              },
              id: inputIds.end,
              ['aria-label']: endLabel,
              autoComplete: 'off',
              ...endInputProps,
            }}
            onDayChange={handleRangeChange(false)}
            onDayPickerHide={() => {
              // unset hover state when calendar disappears
              setHoveringToDate(undefined);
            }}
            onDayPickerShow={() => {
              // when showing the 'end' picker, we want to ensure that the correct months are shown:
              // if no range is selected, let the 'start' month be the first shown
              // if a range within the same month is selected, let the 'start' month be the first shown
              // if a multi-month range is selected, make the 'end' date be in the RIGHT calendar, by setting the left calendar to be the month before 'end'
              let nextFirstMonthInToCalendar = start;

              if (
                start instanceof Date &&
                end instanceof Date &&
                !DateUtils.isSameMonth(start, end)
              ) {
                nextFirstMonthInToCalendar = DateUtils.addMonths(end, -1);
              }

              setFirstMonthInToCalendar(nextFirstMonthInToCalendar);
            }}
            parseDate={parseDate}
            placeholder="DD-MM-YY"
            value={end}
          />
        </div>
      </div>
      {helperText && (
        <div
          aria-label={`${startLabel} or ${endLabel}`}
          className={`HelperText ${combinedStatus}`}
          role="alert"
        >
          {helperText}
        </div>
      )}
    </fieldset>
  );
};

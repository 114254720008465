/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CloudUnavailable = React.forwardRef(function CloudUnavailable(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M22.29 23.574a.748.748 0 01-.54-.23L1.21 2.144a.75.75 0 011.08-1l20.54 21.16a.75.75 0 01-.54 1.27zM6.1 19.294a6 6 0 01-3.8-1.27 3.8 3.8 0 01-1.28-2.8 4.11 4.11 0 011.12-3.25 4.87 4.87 0 013-1.24v-.36a.72.72 0 01.88-.7.75.75 0 01.72.78l-.06 1.1a1 1 0 01-.83.7 3.83 3.83 0 00-2.57.83 2.68 2.68 0 00-.71 2.14 2.33 2.33 0 00.74 1.72 4.71 4.71 0 003 .88h7.67a.75.75 0 010 1.5H6.1v-.03zm14.92-1.57a.74.74 0 01-.41-.12.75.75 0 01-.22-1s1.39-2.15.92-3.8a3.81 3.81 0 00-3.61-2.76h-.26a.849.849 0 01-.62-.5 5.13 5.13 0 00-6.86-3 .751.751 0 11-.57-1.39 6.54 6.54 0 018.63 3.37 5.16 5.16 0 014.71 3.82c.67 2.32-1 4.93-1.11 5a.75.75 0 01-.6.38z" />
    </svg>
  );
});

/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * SidebarMenu
 */
export const SidebarMenu: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <div className={cn('sidebar-menu')}>{children}</div>;
};

SidebarMenu.displayName = 'Sidebar.Menu';

/**
 * SidebarLink
 */
export const SidebarLink: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <div className={cn('sidebar-link')}>{children}</div>;
};

SidebarLink.displayName = 'Sidebar.Link';

/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import { Pair } from '../../utility/types';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { OnOptionChangeCallback, Select } from '../select';

interface IPaginationResultProps {
  description?: string;
  sizeOptions?: Pair<number>[];
  onSizeChange?: (size: number) => void;
}

const standardSelectionRange: Pair<number>[] = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

/**
 * Pagination Size Selector
 * Select the amount of items that should be visible per page/table.
 * TODO: Add a11y controls, and focus state
 *
 * @param description the description next to the Size Selector
 * @param sizeOptions A list of options for the nr. of elements displayed pr. page
 * @param onSizeChange callBack for when a size have been selected
 * @returns A Pagination result element
 * @memberof pagination
 * @author Simon Groenborg
 */
export const PaginationResult: React.FunctionComponent<IPaginationResultProps> = ({
  description = 'Results per page',
  sizeOptions = standardSelectionRange,
  onSizeChange,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const onSelect: OnOptionChangeCallback<number> = React.useCallback(
    ({ value }) => {
      if (onSizeChange) {
        onSizeChange(value);
      }
    },
    [sizeOptions]
  );

  const options = React.useMemo(() => {
    return sizeOptions.map((size) => (
      <Select.Option key={size.value} label={size.label} value={size.value} />
    ));
  }, [sizeOptions]);

  return (
    <div aria-label="pagination result" className={cn('pagination-results')} role="presentation">
      <p className={cn('pagination-results-label')}>{description}</p>
      <Select onOptionSelect={onSelect} tabIndex={0}>
        {options}
      </Select>
    </div>
  );
};

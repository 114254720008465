/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Delete = React.forwardRef(function Delete(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M9.827 10.077a.75.75 0 01.75.75v6.461a.75.75 0 01-1.5 0v-6.461a.75.75 0 01.75-.75zm5.596.75a.75.75 0 00-1.5 0v6.461a.75.75 0 001.5 0v-6.461z" />
      <path
        clipRule="evenodd"
        d="M9.827 2A2.365 2.365 0 007.46 4.365v.866H1.75a.75.75 0 000 1.5h2.481v13.788a2.365 2.365 0 002.365 2.366h11.308a2.365 2.365 0 002.365-2.366V6.731h2.481a.75.75 0 000-1.5h-5.712v-.866A2.365 2.365 0 0014.673 2H9.827zM8.198 6.73h8.09a.74.74 0 00.014 0h2.467v13.79a.866.866 0 01-.865.864H6.596a.865.865 0 01-.865-.865V6.731h2.467zm7.34-1.5v-.865a.865.865 0 00-.865-.865H9.827a.865.865 0 00-.866.865v.866h6.577z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

type TextLinksProps = {
  children?: React.ReactNode;
};

/**
 * Textlinks
 *
 * @returns Textlinks component
 * @memberof textlink
 * @author Jeremy Thomas
 */
export const TextLinks = React.forwardRef<HTMLDivElement, TextLinksProps>(
  ({ children, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    return (
      <div className={cn('text-links')} ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

import * as React from 'react';
import { chain, useFrameworkClassnamePrefixer } from '../../utils';
import { SwitchProps, useSwitch } from './useSwitch';

/**
 * Switch
 *
 * @param param0
 * @memberof switch
 * @author Simon Groenborg
 */
export const Switch: React.FunctionComponent<SwitchProps> = ({
  isDisabled,
  onSwitch,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { checked, onClick, ...attars } = useSwitch({ isDisabled, ...props });

  const onClickHandler = React.useCallback(
    (e) => {
      if (onSwitch) {
        onSwitch(!!checked);
      }
    },
    [onClick, checked]
  );

  return (
    <div
      className={cn('switch', {
        'is-active': checked,
        'is-disabled': isDisabled,
      })}
      onClick={chain(onClickHandler, onClick)}
      {...attars}
      {...props}
    >
      <div className={cn('switch-dot')} />
    </div>
  );
};

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { SidebarBrand } from './SidebarBrand';
import { SidebarDivider, SidebarGroup } from './SidebarGroup';
import { SidebarLink, SidebarMenu } from './SidebarMenu';

export type SidebarProps = {
  Group: typeof SidebarGroup;
  Divider: typeof SidebarDivider;
  Brand: typeof SidebarBrand;
  Menu: typeof SidebarMenu;
  Link: typeof SidebarLink;
};

/**
 *
 * @param param0
 */
export const Sidebar: React.FunctionComponent & SidebarProps = ({ children, ...props }) => {
  const cn = useFrameworkClassnamePrefixer();
  return (
    <aside className={cn('sidebar')} {...props}>
      {children}
    </aside>
  );
};

Sidebar.Group = SidebarGroup;
Sidebar.Divider = SidebarDivider;
Sidebar.Brand = SidebarBrand;
Sidebar.Menu = SidebarMenu;
Sidebar.Link = SidebarLink;

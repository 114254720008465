/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Strategy = React.forwardRef(function Strategy(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#strategy_svg__strategy_svg__clip0)">
        <path
          clipRule="evenodd"
          d="M10.879.618a1.303 1.303 0 012.25.013l2.343 4.154A1.304 1.304 0 0114.35 6.75h-1.6v9.743c1.066-1.557 2.696-2.712 4.875-3.515l-.73-1.753a1.305 1.305 0 011.384-1.789l4.6.647a1.303 1.303 0 01.862 2.076l-2.793 3.709a1.305 1.305 0 01-2.246-.288l-.502-1.215c-3.592 1.302-5.358 3.562-5.446 7.287l-.004.299v1.3a.75.75 0 01-1.493.102l-.007-.102v-1.305c-.002-3.901-1.764-6.245-5.45-7.581l-.502 1.216a1.305 1.305 0 01-2.247.285L.26 12.161a1.303 1.303 0 01.86-2.078l4.6-.647a1.305 1.305 0 011.383 1.793l-.729 1.75c2.18.802 3.809 1.957 4.875 3.514V6.75h-1.6a1.303 1.303 0 01-1.128-1.952l2.357-4.18zM12 5.25h2.012L12 1.682 9.988 5.25H12zm-7.187 7.579l.774-1.86-3.93.553 2.385 3.168.77-1.861zm17.529-1.307l-3.93-.553.776 1.861.768 1.859 2.386-3.167z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="strategy_svg__strategy_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

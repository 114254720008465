import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { IPolymorphProps, Polymorph } from '../polymorph';

interface IListItemProps extends IPolymorphProps {
  contentBefore?: React.ReactNode | HTMLElement;
  contentAfter?: React.ReactNode;
  title: string;
  content?: string;
}

export const ListItem: React.FunctionComponent<IListItemProps> = ({
  as = 'div',
  contentBefore,
  contentAfter,
  title,
  content,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <Polymorph as={as} {...props} className={cn('list-item')}>
      {
        // eslint-disable-next-line no-null/no-null
        contentBefore ? contentBefore : null
      }
      <div className={cn('list-item-content')}>
        <p className={cn('list-item-title')}>{title}</p>
        {content && <div className={cn('list-item-body')}>{content}</div>}
      </div>
      {
        // eslint-disable-next-line no-null/no-null
        contentAfter ? contentAfter : null
      }
    </Polymorph>
  );
};

ListItem.displayName = 'List.ListItem';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Code = React.forwardRef(function Code(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.06 22.03H2.44A2.44 2.44 0 010 19.6V3.47A2.44 2.44 0 012.44 1h18.62a2.44 2.44 0 012.44 2.47V19.6a2.44 2.44 0 01-2.44 2.44v-.01zM2.44 2.47a.94.94 0 00-.94 1V19.6a.94.94 0 00.94.94h18.62a.939.939 0 00.94-.94V3.47a.94.94 0 00-.94-1H2.44z" />
      <path d="M22.75 5.63h-22v1.5h22v-1.5zM10.44 17.28a.75.75 0 01-.67-1.08l2.72-5.51a.751.751 0 011.35.66l-2.72 5.51a.75.75 0 01-.68.42zM7.28 17a.75.75 0 01-.5-.19l-2.26-2a1.74 1.74 0 010-2.44l2-2a.778.778 0 111.1 1.1l-2 2a.22.22 0 000 .31l2.2 1.93a.75.75 0 01-.5 1.31L7.28 17zm9.08.19a.75.75 0 01-.5-1.31l2.26-2a.291.291 0 00-.08-.25l-2-2a.75.75 0 011.05-1.07l2 2a1.73 1.73 0 01.08 2.37l-2.33 2.06a.75.75 0 01-.48.2z" />
    </svg>
  );
});

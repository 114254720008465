import { css, keyframes } from '@emotion/core';
import { colors } from '../../config';

// Variables
const animationDuration = '5s';
const spinnerColor = colors.utilityColors.warning.base;

// Animations
const circlesAnimation = keyframes`
  100% {
    transform: rotate(1080deg);
  }
  20%,
  45%,
  70%,
  95% {
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    opacity: 0;
  }

  0%,
  15%,
  50%,
  65%,
  100% {
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
    opacity: 1;
  }
`;

const heartAnimation = keyframes`
  70% {
    transform: scale(0) rotate(-40deg);
  }
  75%,
  90% {
    transform: scale(1) rotate(0deg);
  }
  95% {
    transform: scale(0) rotate(40deg);
  }
`;

const headAnimation = keyframes`
  20% {
    transform: scale(0) rotate(-40deg);
  }
  25%,
  40% {
    transform: scale(1) rotate(0deg);
  }
  45% {
    transform: scale(0) rotate(40deg);
  }
`;

const generalCircleStyle = {
  width: 40,
  height: 40,
  borderRadius: 20,
  position: 'absolute',
  background: spinnerColor,
};

const generalHeartTopPieceStyle = {
  width: 30,
  height: 30,
  background: spinnerColor,
  borderRadius: 15,
  left: '50%',
  top: '50%',
  position: 'absolute',
};

const generalEyeStyle = {
  position: 'absolute',
  background: 'black',
  width: 6,
  height: 6,
  borderRadius: 3,
  top: 14,
};

export const loaderContainerStyle = css({
  position: 'relative',
  left: '50%',
  top: '50%',
  width: 300,
  height: 300,
  margin: '-150px auto auto -150px',
  transform: 'scale(0.5)',
});

export const circlesContainerStyle = css({
  animationName: circlesAnimation,
  animationDuration,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  width: 120,
  height: 120,
  position: 'absolute',
  left: '50%',
  top: '50%',
  margin: '-60px auto auto -60px',
});

export const circle1Style = css({
  ...generalCircleStyle,
  top: 0,
  left: 0,
});

export const circle2Style = css({
  ...generalCircleStyle,
  top: 0,
  right: 0,
});

export const circle3Style = css({
  ...generalCircleStyle,
  bottom: 0,
  right: 0,
});

export const circle4Style = css({
  ...generalCircleStyle,
  bottom: 0,
  left: 0,
});

export const heartContainerStyle = css({
  animationName: heartAnimation,
  animationDuration,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  transform: 'scale(0)',
  position: 'absolute',
  width: 100,
  height: 100,
  left: '50%',
  top: '50%',
  margin: '-45px auto auto -50px',
});

export const heartBottomPieceStyle = css({
  position: 'absolute',
  width: 30,
  height: 30,
  background: spinnerColor,
  transform: 'rotate(45deg)',
  left: '50%',
  top: '50%',
  margin: '-15px 0 0 -15px',
  borderRadius: '0 0 4px 0',
});

export const heartTopLeftPieceStyle = css({
  ...generalHeartTopPieceStyle,
  margin: '-26px auto auto -26px',
  background: spinnerColor,
});

export const heartTopRightPieceStyle = css({
  ...generalHeartTopPieceStyle,
  margin: '-26px auto auto -4px',
  background: spinnerColor,
});

export const headContainerStyle = css({
  transform: 'scale(0)',
  animationName: headAnimation,
  animationDuration,
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  position: 'absolute',
  width: 52,
  height: 52,
  left: '50%',
  top: '50%',
  margin: '-26px auto auto -26px',
});

export const headTopPieceStyle = css({
  position: 'absolute',
  width: 26,
  height: 12,
  background: spinnerColor,
  left: '50%',
  marginLeft: -13,
  borderRadius: '2px 2px 0 0',
});

export const headCenterPieceStyle = css({
  position: 'absolute',
  width: 48,
  height: 39,
  background: spinnerColor,
  borderRadius: 9,
  left: 2,
  top: 8,
});

export const headBottomPieceStyle = css({
  position: 'absolute',
  width: 30,
  height: 7,
  left: '50%',
  marginLeft: -15,
  bottom: 0,
  background: spinnerColor,
});

export const headSmileStyle = css({
  position: 'absolute',
  width: 18,
  height: 7,
  background: 'transparent',
  left: '50%',
  top: 24,
  marginLeft: -9,
  borderRadius: '0 0 50% 50%',
  borderBottom: '2px solid black',
  boxSizing: 'border-box',
});

export const eye1Style = css({
  ...generalEyeStyle,
  left: 14,
});

export const eye2Style = css({
  ...generalEyeStyle,
  right: 14,
});

/**
 * Check if two arrays are equal
 * @param {Array} a
 * @param {Array} b
 * @returns {Boolean}
 * @example
 * areArraysEqual([1, 2, 3], [3, 2, 1]); // true
 * areArraysEqual([1, 2, 3], [1, 2, 3]); // true
 * areArraysEqual([1, 2, 3], [1, 2, 3, 4]); // false
 */
export const areArraysEqual = (a, b) =>
  a.sort().toString() == b.sort().toString();

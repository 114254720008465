/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const MenuNavigationVertical = React.forwardRef(function MenuNavigationVertical(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.75 16.903a1.1 1.1 0 01.12 2.194l-.12.006H2.25a1.1 1.1 0 01-.12-2.194l.12-.006h19.5zm0-6a1.1 1.1 0 01.12 2.194l-.12.006H2.25a1.1 1.1 0 01-.12-2.194l.12-.006h19.5zm0-6a1.1 1.1 0 01.12 2.194l-.12.006H2.25a1.1 1.1 0 01-.12-2.194l.12-.006h19.5z" />
    </svg>
  );
});

export const font = {
  size: {
    size7: '5.9375rem', // 95px
    size6: '3.8125rem', // 61px
    size5: '3.0625rem', // 49px
    size4: '2.4375rem', // 39px
    size3: '1.9375rem', // 31px
    size2: '1.5625rem', // 25px
    size1: '1.25rem', // 20px
    size05: '1.125rem', // 18px
    size0: '1rem', // 16px
    sizeM1: '0.875rem', // 14px
    sizeM2: '0.6875rem', // 11px
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  lineHeight: {
    l: 1.5,
    m: 1.375,
    s: 1.25,
    xs: 1.125,
  },
  letterSpacing: {
    xxl: '0.1em',
    xl: '0.0825em',
    l: '0.075em',
    m: '0.05em',
    s: '0.0375em',
    xs: '0.025em',
    xxs: '0.0125em',
  },
};

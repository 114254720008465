import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { tooltipSettings } from './tooltipSettings';

import 'tippy.js/dist/tippy.css';
import './tooltipStyle.css';

const Tooltip = ({ children, ...props }) => {
  const tooltipProps = { ...tooltipSettings, ...props };
  return <Tippy {...tooltipProps}>{children}</Tippy>;
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  rest: PropTypes.object,
};

export { Tooltip };

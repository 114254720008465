import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * ToastHeading
 *
 * @memberof toast
 * @author Simon Groenborg
 */
export const ToastHeading: React.FunctionComponent<unknown> = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <p className={cn('toast-heading')}>{children}</p>;
};

ToastHeading.displayName = 'Toast.Heading';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PortalRoots, usePortalRoot, useShareForwardRef } from '../../hooks';
import { EMPTY_ARRAY } from '../../hooks/consts';
import { Pair } from '../../utility/types';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { DropdownItem, IDropdownItemProps } from './DropdownItem';
import { IDropdownableElement } from './IDropdownableElement';

export type DropdownScreenProps = {
  isNarrow?: boolean;
  isClipped?: boolean;
};

export type DropdownProps<T extends string | number> = React.HTMLAttributes<HTMLDivElement> &
  DropdownScreenProps &
  IDropdownableElement<T> & {
    value?: Pair<T> | Pair<T>[];
    onDropdownSelect?: (option: Pair<T>) => void;
    _hasFocus?: boolean;
  };

/**
 * isSelected helper function
 * @param current
 * @param controlledValues
 */
const isSelected = <T extends string | number>(
  current: T,
  controlledValues?: Pair<T> | Pair<T>[]
): boolean => {
  if (!current || !controlledValues) {
    return false;
  }

  if (Array.isArray(controlledValues)) {
    if (controlledValues.length < 1) {
      return false;
    }

    return controlledValues.some((item: Pair<T>) => item.value === current);
  }

  return current === controlledValues.value;
};

/**
 * Dropdown
 * Use the dropdown with the useDropDown hook, to attach a selectable list to any
 * component.
 *
 * TODO: Add aria attributes and a11y navigation
 *
 * @param options list of option for the dropdown
 * @param defaultSelected the default selected element
 * @returns A portaled dropdown element
 * @memberof dropdown
 * @author Simon Groenborg
 */
export const Dropdown = (React.forwardRef(
  <T extends string | number>(
    {
      onDropdownSelect,
      children,
      value,
      isClipped = false,
      isNarrow = false,
      _hasFocus = false,
      ...props
    }: React.PropsWithChildren<DropdownProps<T>>,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const cn = useFrameworkClassnamePrefixer();
    const shared = useShareForwardRef(ref);
    const { portalRoot } = usePortalRoot(PortalRoots.DROPDOWN);

    const clickHandler = React.useCallback((option: Pair<T>): void => {
      if (onDropdownSelect) {
        onDropdownSelect(option);
      }
    }, EMPTY_ARRAY);

    const providedChildren = React.useMemo(
      () =>
        React.Children.map(children, (child: React.ReactElement<IDropdownItemProps<T>>) => {
          return React.cloneElement(child, {
            onClick: clickHandler,
            selected: isSelected(child.props.value, value),
            ...child.props,
          });
        }),
      [children]
    );

    return ReactDOM.createPortal(
      <div
        className={cn('dropdown', {
          'is-narrow': isNarrow,
          'is-clipped': isClipped,
        })}
        ref={shared}
        role="group"
        {...props}
      >
        {providedChildren}
      </div>,
      portalRoot
    );
  }
) as unknown) as IDropdownWithSubComponent;

Dropdown.Item = DropdownItem;

interface IDropdownWithSubComponent {
  <T extends string | number>(
    p: DropdownProps<T> & { ref?: React.Ref<HTMLDivElement> }
  ): React.ReactElement;
  Item: typeof DropdownItem;
}

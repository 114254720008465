import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PortalRoots, usePortalRoot } from '../../hooks';
import { Nullable } from '../../utility/types';
import { TooltipBox, TooltipBoxProps } from './TooltipBox';
import { Placement, useTooltipControl } from './useTooltipControl';

export type TooltipProps = TooltipBoxProps & {
  tooltipPosition?: Placement;
};

/**
 * Tooltip
 * Wrap a component in a tooltip
 * TODO: The portal is still rendered in the component, maybe create a hook
 * utility to provide a rendering scope
 *
 * @param tooltipPosition Position of the tooltip relative to the element
 * @returns Tooltip Component
 * @memberof tooltip
 * @author Simon Groenborg
 */
export const Tooltip: React.FunctionComponent<TooltipProps> = ({
  children,
  label,
  tooltipPosition = 'top',
}) => {
  // eslint-disable-next-line no-null/no-null
  const [referenceElement, setReferenceElement] = React.useState<Nullable<HTMLElement>>(null);
  // eslint-disable-next-line no-null/no-null
  const [popperElement, setPopperElement] = React.useState<Nullable<HTMLElement>>(null);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const { portalRoot } = usePortalRoot(PortalRoots.TOOLTIP);

  const { styles, attributes, position } = useTooltipControl(
    referenceElement,
    popperElement,
    tooltipPosition
  );

  const renderToolTip = (): JSX.Element | undefined => {
    if (isVisible) {
      return ReactDOM.createPortal(
        <TooltipBox
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          arrowPosition={position}
          label={label}
        />,
        portalRoot
      );
    }

    return undefined;
  };

  return (
    <React.Fragment>
      {React.isValidElement(children) &&
        React.cloneElement(children, {
          onMouseLeave: () => setIsVisible(false),
          onMouseOver: () => setIsVisible(true),
          ref: (node: HTMLElement) => setReferenceElement(node),
          ...children.props,
        })}
      {renderToolTip()}
    </React.Fragment>
  );
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Announcement = React.forwardRef(function Announcement(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M21 3.75a.75.75 0 00-1.166-.624l-1.134.756A21.55 21.55 0 016.751 7.5H4.5a4.5 4.5 0 000 9h1.535a7.988 7.988 0 002.448 5.043.75.75 0 101.034-1.086 6.49 6.49 0 01-1.972-3.942A21.55 21.55 0 0118.7 20.118l1.134.756A.75.75 0 0021 20.25V3.75zM7.5 15.012a23.05 23.05 0 0112 3.837V5.15a23.05 23.05 0 01-12 3.837v6.024zM6 9H4.5a3 3 0 100 6H6V9z"
        fillRule="evenodd"
      />
      <path d="M23.25 9.75a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ListSearchBold = React.forwardRef(function ListSearchBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M3 16.8h6.25c.552 0 1 .43 1 .96s-.448.96-1 .96H3c-1.657 0-3-1.29-3-2.88V2.88C0 1.29 1.343 0 3 0h15.5c1.657 0 3 1.29 3 2.88v6.48c0 .53-.448.96-1 .96s-1-.43-1-.96V7.2a.49.49 0 00-.5-.48H2.5a.49.49 0 00-.5.48v8.64c0 .53.448.96 1 .96zm8-13.44c0-.53-.448-.96-1-.96s-1 .43-1 .96.448.96 1 .96 1-.43 1-.96zM7 2.4c.552 0 1 .43 1 .96s-.448.96-1 .96-1-.43-1-.96.448-.96 1-.96zm-2 .96c0-.53-.448-.96-1-.96s-1 .43-1 .96.448.96 1 .96 1-.43 1-.96z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.64 19.15a.233.233 0 00.034.3l2.033 1.951a.934.934 0 01.259.927.981.981 0 01-.707.68 1.03 1.03 0 01-.966-.248l-2.033-1.953a.259.259 0 00-.311-.032c-2.367 1.445-5.487.95-7.242-1.151-1.755-2.1-1.583-5.132.4-7.036 1.983-1.905 5.14-2.072 7.33-.388 2.188 1.683 2.707 4.678 1.203 6.95zm-8.14-2.83c0 1.855 1.567 3.36 3.5 3.36s3.5-1.505 3.5-3.36c0-1.856-1.567-3.36-3.5-3.36s-3.5 1.504-3.5 3.36z"
        fillRule="evenodd"
      />
      <path d="M10.528 8.697l-3.41 3.415a.255.255 0 01-.361 0l-.535-.536a1.026 1.026 0 00-.969-.286.983.983 0 00-.731.673.934.934 0 00.256.941l1.438 1.44c.189.189.45.296.721.296.273 0 .533-.107.722-.296l4.313-4.32a.934.934 0 00.256-.941.983.983 0 00-.732-.673c-.353-.08-.723.03-.968.287z" />
    </svg>
  );
});

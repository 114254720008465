import * as React from 'react';

export interface IAppDependencies {
  frameworkPrefix: string;
  theme?: string;
}
const KlikContext = React.createContext<IAppDependencies | undefined>(undefined);

export const KlikContextProvider: React.FunctionComponent<Partial<IAppDependencies>> = ({
  children,
  frameworkPrefix = 'klik',
  theme = 'light-blue',
}) => {
  return <KlikContext.Provider value={{ frameworkPrefix, theme }}>{children}</KlikContext.Provider>;
};

export const KlikContextConsumer = KlikContext.Consumer;

export const useKlikContext = (): IAppDependencies => {
  const ctx = React.useContext(KlikContext);

  if (!ctx) {
    throw new Error('"useklikContext" can only be called inside of AppContext.Provider!');
  }

  return ctx;
};

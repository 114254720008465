import { useUID } from 'react-uid';

/*
 * use this at the top level of an element, pass on the prop if you need to manipulate it further
 * in the case of f.ex tabs that use a provider, assign the return value to the context and pass it
 * around further down the children nodes.
 */
export const useID = (type: string): string => {
  const uid = useUID();

  return `${type}-${uid}`;
};

export const makeTabId = (id: string, index: number): string => {
  return `${id}-tab-${index}`;
};

export const makeTabPanelId = (id: string, index: number): string => {
  return `${id}-tabpanel-${index}`;
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Database = React.forwardRef(function Database(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M3.767 1.373C5.987.484 8.949 0 12 0c3.05 0 6.012.483 8.233 1.372 1.11.444 2.029.986 2.682 1.616C23.569 3.62 24 4.388 24 5.25v13.5c0 .894-.456 1.67-1.119 2.296-.66.626-1.577 1.154-2.648 1.582C18.09 23.486 15.177 24 12 24c-3.178 0-6.089-.514-8.233-1.372-1.07-.428-1.987-.956-2.648-1.582C.456 20.42 0 19.644 0 18.75V5.251c0-.863.43-1.63 1.085-2.262.653-.63 1.571-1.172 2.682-1.616zM1.5 14.625v4.125c0 .349.174.756.65 1.206.477.452 1.208.893 2.174 1.28C6.25 22.005 8.964 22.5 12 22.5c3.036 0 5.75-.494 7.676-1.265.966-.386 1.697-.827 2.175-1.279.476-.45.649-.857.649-1.206v-4.125c-.615.484-1.389.903-2.267 1.254-2.144.857-5.056 1.371-8.233 1.371-3.178 0-6.089-.514-8.233-1.372-.878-.35-1.652-.77-2.267-1.253zM1.5 12V7.874c.606.478 1.374.898 2.267 1.255 2.22.889 5.182 1.372 8.233 1.372 3.05 0 6.012-.483 8.233-1.372.893-.357 1.661-.777 2.267-1.255V12c0 .349-.174.757-.65 1.207-.477.451-1.208.893-2.174 1.279-1.927.77-4.64 1.264-7.676 1.264-3.035 0-5.75-.494-7.676-1.264-.966-.387-1.697-.828-2.175-1.28-.475-.45-.649-.857-.649-1.206zm.627-5.566c-.465-.45-.627-.852-.627-1.183 0-.33.162-.733.627-1.183.468-.451 1.197-.903 2.197-1.303C6.322 1.966 9.084 1.501 12 1.501c2.917 0 5.678.465 7.676 1.264 1 .4 1.73.852 2.197 1.303.465.45.627.852.627 1.183 0 .33-.162.733-.627 1.183-.468.45-1.197.903-2.197 1.303C17.678 8.536 14.916 9 12 9c-2.917 0-5.678-.465-7.676-1.264-1-.4-1.73-.852-2.197-1.303z"
        fillRule="evenodd"
      />
    </svg>
  );
});

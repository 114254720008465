/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LocationBold = React.forwardRef(function LocationBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#location-bold_svg__location-bold_svg__clip0)">
        <path
          clipRule="evenodd"
          d="M6.166 2.416A8.25 8.25 0 0120.25 8.25c0 .997-.361 2.278-.87 3.614-.518 1.358-1.222 2.857-1.975 4.321-1.508 2.928-3.24 5.766-4.155 7.145a1.5 1.5 0 01-2.5 0c-.914-1.379-2.647-4.217-4.155-7.145-.753-1.463-1.457-2.963-1.975-4.321-.509-1.336-.87-2.617-.87-3.614a8.25 8.25 0 012.416-5.834zm3.536 8.132a3.25 3.25 0 114.596-4.596 3.25 3.25 0 01-4.596 4.596z"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="location-bold_svg__location-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

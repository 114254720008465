/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const HumanResourcesOfferMan = React.forwardRef(function HumanResourcesOfferMan(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#human-resources-offer-man_svg__human-resources-offer-man_svg__clip0)">
        <path
          clipRule="evenodd"
          d="M16.865 0a4.875 4.875 0 100 9.75 4.875 4.875 0 000-9.75zm0 1.5a3.375 3.375 0 110 6.75 3.375 3.375 0 010-6.75zM.749 13.5a.75.75 0 01.743.648l.007.102V15H6a3.752 3.752 0 013.624 2.784l.05.216h3.075a3.75 3.75 0 013.745 3.55l.005.2a.75.75 0 01-.648.743l-.102.007H1.499v.75a.75.75 0 01-1.493.102l-.007-.102v-9a.75.75 0 01.75-.75zm.75 7.5v-4.5H6l.154.005A2.252 2.252 0 018.122 18H5.999l-.102.007A.75.75 0 006 19.5h6.75l.154.005a2.251 2.251 0 011.92 1.37l.047.125H1.499z"
          fillRule="evenodd"
        />
        <path d="M23.937 14.75a7.502 7.502 0 00-14.144 0 .75.75 0 001.414.5 6.002 6.002 0 0111.316 0 .75.75 0 001.414-.5z" />
      </g>
      <defs>
        <clipPath id="human-resources-offer-man_svg__human-resources-offer-man_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowUp = React.forwardRef(function ArrowUp(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M11.495 6.445a.75.75 0 011.01 0l11 10a.75.75 0 01-1.01 1.11L12 8.014 1.505 17.555a.75.75 0 01-1.01-1.11l11-10z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardActions
 * @param param0 props
 */
export const CardActions: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-actions')}>{children}</div>;
};

CardActions.displayName = 'Card.Actions';

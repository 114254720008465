/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CheckTickBold = React.forwardRef(function CheckTickBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M22.56 4.44a1.5 1.5 0 010 2.12l-13 13a1.5 1.5 0 01-2.12 0l-6-6a1.5 1.5 0 012.12-2.12l4.94 4.939 11.94-11.94a1.5 1.5 0 012.12 0z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useShareForwardRef } from '../../hooks';
import { useFrameworkClassnamePrefixer } from '../../utils';

export enum OverlayMode {
  Container = 'container',
  Page = 'page',
}

export type OverlayProps = React.HTMLAttributes<HTMLDivElement> & {
  mode?: OverlayMode;
  isOpen?: boolean;
  onClick?: () => void;
};

/**
 * Overlay
 * use the overlay to fadeout any background elements to center focus around the child components
 *
 * @param mode The behaviour of the overlay
 * @param onClick callback when the overlay is clicked
 * @returns Overlay component
 * @memberof overlay
 * @author Simon Groenborg
 */
export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  ({ children, mode = OverlayMode.Page, onClick, isOpen, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    const overlayRef = useShareForwardRef(ref);

    const clickHandler = React.useCallback(
      (e: React.MouseEvent) => {
        if (overlayRef.current === e.target) {
          if (onClick) {
            onClick();
          }
        }
      },
      [onClick]
    );

    return (
      <div
        className={cn('overlay', `overlay-${mode}`, {
          'is-open': isOpen,
        })}
        onClick={clickHandler}
        ref={overlayRef}
        role="presentation"
        tabIndex={-1}
        {...props}
      >
        {children}
      </div>
    );
  }
);

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardFooter
 * @param param0 props
 */
export const CardFooter: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-footer')}>{children}</div>;
};

CardFooter.displayName = 'Card.Footer';

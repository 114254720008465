/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const SeoSearch = React.forwardRef(function SeoSearch(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M18.875 2.5a2.375 2.375 0 100 4.75 2.375 2.375 0 000-4.75zM15 4.875a3.875 3.875 0 117.75 0 3.875 3.875 0 01-7.75 0z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M20.555 6.554a.75.75 0 011.06 0L23.78 8.72a.75.75 0 01-1.06 1.06l-2.165-2.166a.75.75 0 010-1.06zM2.5 3.75a.75.75 0 00-.75.75V6c0 .414.336.75.75.75H13a.75.75 0 010 1.5H2.5A2.25 2.25 0 01.25 6V4.5A2.25 2.25 0 012.5 2.25H13a.75.75 0 010 1.5H2.5zm-.75 7.875a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zM5.5 12a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12A.75.75 0 015.5 12zm-3.75 4.875a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm3.75.375a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75zm-3.75 4.875a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm3.75.375a.75.75 0 01.75-.75h12a.75.75 0 010 1.5h-12a.75.75 0 01-.75-.75z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export interface IMenuDropdownProps {
  isOpen?: boolean;
}

/**
 * MenuDropdown
 * React MenuDropdown component.
 *
 *
 * @returns MenuDropdown Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuDropdown: React.FunctionComponent<IMenuDropdownProps> = ({ children, isOpen }) => {
  const cn = useFrameworkClassnamePrefixer();

  const dropDownClasses = cn('menu-dropdown', {
    'is-open': isOpen,
  });

  return <div className={dropDownClasses}>{children}</div>;
};

MenuDropdown.displayName = 'Menu.Dropdown';

import * as React from 'react';
import { forwardRefAs } from './forwardRefAs';
import { IPolymorphProps } from './types';

const defaultElement = 'div';

type IGenericProps = IPolymorphProps;

/**
 * Polymorph
 * this component can take on any interface of any intrinsic element in the browser
 * @returns JSX.Element
 */
export const Polymorph = forwardRefAs<IGenericProps>(
  ({ as = defaultElement, children, ...props }, ref) => {
    return React.createElement(as, { ref, ...props }, children);
  }
);

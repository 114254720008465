/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DateBold = React.forwardRef(function DateBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M6.271 12.566a1.436 1.436 0 100 2.871 1.436 1.436 0 000-2.871zm5.979 0a1.436 1.436 0 100 2.871 1.436 1.436 0 000-2.871zm6.054.016h-.128c-.75.043-1.367.646-1.367 1.42 0 .782.63 1.435 1.436 1.435.801 0 1.435-.634 1.435-1.435v-.011a1.456 1.456 0 00-1.365-1.408h-.011zM6.33 17.5h-.128c-.74.044-1.35.67-1.35 1.42 0 .781.63 1.435 1.436 1.435.801 0 1.435-.634 1.435-1.436v-.01A1.459 1.459 0 006.341 17.5h-.01zm5.979 0h-.145c-.74.044-1.35.67-1.35 1.42a1.435 1.435 0 102.872 0c0-.774-.618-1.377-1.366-1.42h-.011zm5.903 0c-.793 0-1.42.657-1.42 1.436 0 .782.631 1.435 1.437 1.435.801 0 1.435-.634 1.435-1.435 0-.786-.65-1.436-1.435-1.436h-.017z" />
      <path
        clipRule="evenodd"
        d="M7.332.625c-.593 0-1.082.49-1.082 1.082v1.73H3.073A2.453 2.453 0 00.625 5.886v15.557c0 1.348 1.1 2.448 2.448 2.448h18.354c1.348 0 2.448-1.1 2.448-2.448V5.886c0-1.348-1.1-2.449-2.448-2.449H18.25v-1.73c0-.593-.49-1.082-1.082-1.082-.593 0-1.082.49-1.082 1.082v1.73H8.414v-1.73c0-.593-.489-1.082-1.082-1.082zM2.79 21.427V11.21h18.938v10.206a.3.3 0 01-.3.294H3.073a.287.287 0 01-.284-.284z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';

type DeviceDetectHookAction = {
  isMobile: boolean;
};

/**
 * useDeviceDetect
 * TODO: Add a device detect instead and make orientation a part of the API
 * @summary detect if the app is running on mobile or desktop
 */
export const useIsMobileDetect = (): DeviceDetectHookAction => {
  const [isMobile, setMobile] = React.useState<boolean>(false);

  React.useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

    if (!userAgent) {
      // eslint-disable-next-line no-console
      console.error('could not detect userAgent, are you using ssr');
    }

    setMobile(/Mobi|Android/u.test(navigator.userAgent));
  }, []);

  return {
    isMobile,
  };
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const TargetBold = React.forwardRef(function TargetBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 23 24" width={23} {...props}>
      <path d="M21.98 3.811a.5.5 0 00-.462-.308h-2.752a.25.25 0 01-.25-.25V.5a.5.5 0 00-.854-.354l-2.416 2.416a2.486 2.486 0 00-.733 1.77v1.685a.25.25 0 01-.117.212.492.492 0 00-.09.069l-5.004 5.004a1 1 0 101.415 1.415l5.004-5.004a.464.464 0 00.07-.09.253.253 0 01.212-.117h1.684a2.485 2.485 0 001.77-.734l2.416-2.416a.5.5 0 00.108-.545z" />
      <path d="M10.01 8.006a1 1 0 000-2.001 6.005 6.005 0 106.004 6.005 1 1 0 00-2.002 0 4.003 4.003 0 11-4.003-4.004z" />
      <path d="M20.017 12.01a1 1 0 00-2.001 0 8.006 8.006 0 11-8.007-8.007 1 1 0 000-2.001 10 10 0 00-7.167 16.981.25.25 0 01.011.338l-2.61 3.047a1 1 0 001.52 1.301l2.664-3.107a.25.25 0 01.32-.05 9.948 9.948 0 0010.523 0 .25.25 0 01.323.05l2.664 3.107a1 1 0 001.52-1.3l-2.611-3.048a.25.25 0 01.01-.337 9.97 9.97 0 002.841-6.974z" />
    </svg>
  );
});

import { CheckTickBold, MinusBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { useShareForwardRef } from '../../hooks';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { CheckboxProps, useCheckbox } from './useCheckbox';

/**
 * Checkbox
 * React Checkbox component.
 *
 *
 * @returns Checkbox Component
 * @memberof checkbox
 * @author Simon Groenborg
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, labelLeft, defaultChecked, status = '', indeterminate, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    const inputRef = useShareForwardRef(ref);

    const { checkboxProps, labelProps } = useCheckbox({
      label,
      defaultChecked,
      ...props,
    });

    React.useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = !!indeterminate;
      }
    }, [inputRef, indeterminate]);

    const labelEl = label && <span className={cn('checkbox-text')}>{label}</span>;
    const icon = indeterminate ? <MinusBold /> : <CheckTickBold />;

    return (
      <div
        className={cn('checkbox', {
          [`status-${status}`]: status,
        })}
      >
        <label className={cn('checkbox-label')} {...labelProps}>
          {labelLeft && labelEl}

          <input className={cn('checkbox-hidden')} {...checkboxProps} {...props} ref={inputRef} />

          <span className={cn('checkbox-shape')}>
            <Icon icon={icon} />
          </span>

          {!labelLeft && labelEl}
        </label>
      </div>
    );
  }
);

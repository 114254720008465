/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ThreedPrinting = React.forwardRef(function ThreedPrinting(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M9.543 4.262l1.714-1.713v4.18a.76.76 0 00.754.754.76.76 0 00.754-.754V2.531l1.713 1.714a.74.74 0 00.531.223.755.755 0 00.531-1.285L12.542.2h-.017a.452.452 0 00-.103-.085c-.017-.017-.034-.017-.052-.035a.13.13 0 00-.068-.034C12.285.03 12.251.03 12.233.03c-.017 0-.034-.017-.068-.017a.832.832 0 00-.291 0c-.018 0-.035.017-.069.017s-.051.017-.086.017c-.017.017-.051.017-.068.034-.017.018-.034.018-.069.035A.452.452 0 0011.48.2h-.018l-2.98 3a.755.755 0 000 1.062.755.755 0 001.061 0zM5.106 17.815l-3.598 3.598V20.23a.76.76 0 00-.754-.754.76.76 0 00-.754.754v2.998c0 .051 0 .103.017.154 0 .017.017.035.017.069s.017.051.017.085c.018.018.018.052.035.069.017.017.017.034.034.068a.416.416 0 00.086.103v.017h.017a.455.455 0 00.103.086c.017.017.034.017.068.034.017.017.051.017.069.035.017.017.051.017.085.017.017 0 .035.017.069.017C.668 24 .72 24 .77 24h2.998a.76.76 0 00.754-.754.76.76 0 00-.754-.754H2.587l3.598-3.598a.755.755 0 000-1.062.76.76 0 00-1.08-.017z" />
      <path d="M16.277 19.7l-3.53 1.593V13.96l6.751-3.067v4.831a.76.76 0 00.754.754.76.76 0 00.754-.754V9.728c0-.035 0-.069-.017-.12v-.035a.23.23 0 00-.035-.102v-.017c-.017-.035-.034-.069-.05-.12 0-.018-.018-.018-.018-.035a.374.374 0 00-.069-.085v-.018c-.034-.034-.068-.05-.085-.085-.017 0-.017-.017-.034-.017l-.018-.017c-.034-.018-.051-.035-.085-.052l-5.277-2.364a.753.753 0 00-.994.377.753.753 0 00.377.993l3.752 1.697-6.442 2.912-6.443-2.912L9.321 8.03a.752.752 0 00-.617-1.37L3.46 9.042a.374.374 0 00-.086.052l-.017.017c-.017 0-.017.017-.034.017-.034.034-.069.051-.103.086a.358.358 0 00-.068.102c0 .018-.018.018-.018.035a.835.835 0 00-.05.12v.017c-.018.034-.018.068-.035.103v.034c0 .034-.017.068-.017.12v5.997a.76.76 0 00.754.753.76.76 0 00.753-.753v-4.85l6.751 3.068v7.35l-3.51-1.593a.753.753 0 00-.994.377.753.753 0 00.377.993l4.575 2.09c.051.018.085.035.137.052h.034a.38.38 0 00.137.017c.051 0 .086 0 .137-.017h.034c.052-.017.086-.017.137-.051l4.575-2.074a.753.753 0 00.377-.993c-.206-.412-.651-.583-1.028-.412z" />
      <path d="M23.901 23.572a.13.13 0 00.035-.069c.017-.017.017-.051.017-.068 0-.017.017-.052.017-.069a.376.376 0 00.017-.137v-2.998a.76.76 0 00-.754-.754.76.76 0 00-.754.754v1.182l-3.598-3.598a.755.755 0 00-1.062 0 .755.755 0 000 1.062l3.598 3.598h-1.182a.76.76 0 00-.754.754.76.76 0 00.754.754h2.998c.052 0 .103 0 .154-.017.018 0 .035-.017.069-.017s.051-.018.086-.018c.017-.017.051-.017.068-.034.017-.017.035-.017.069-.034s.068-.052.103-.086h.017v-.017a.453.453 0 00.085-.103c0-.051.017-.068.017-.085z" />
    </svg>
  );
});

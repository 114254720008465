import * as React from 'react';
import { Sizes } from '../../constants';
import { useKlikContext } from '../../context';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type CircularProgressProps = {
  value?: number;
  min?: number;
  max?: number;
  size?: Extract<Sizes, 'small' | 'medium' | 'large'>;
};

export const CircularProgress = React.forwardRef<HTMLDivElement, CircularProgressProps>(
  function CircularProgress({ size = 'medium', value, min = 0, max = 100, ...props }, ref) {
    const { frameworkPrefix } = useKlikContext();
    const cn = useFrameworkClassnamePrefixer();

    // eslint-disable-next-line no-null/no-null
    const innerRef = React.useRef<HTMLDivElement>(null);

    const [computedValues, setComputedValues] = React.useState({
      width: 0,
      height: 0,
      strokeWidth: 0,
      radius: 0,
      center: 0,
    });

    /**
     * useLayoutEffect because the values that are calculated are also the values that are used for
     * display, this allows us to calculate the values before the browser re-paints so there is no flicker
     *
     */
    React.useLayoutEffect(() => {
      if (innerRef.current) {
        if (value) {
          innerRef.current.style.setProperty(
            `--${frameworkPrefix}-circle-stroke-percentage`,
            value.toString()
          );
        }

        const diameter = parseInt(
          getComputedStyle(innerRef.current).getPropertyValue(
            `--${frameworkPrefix}-circle-full-diameter`
          ),
          10
        );

        const strokeWidth = parseInt(
          getComputedStyle(innerRef.current).getPropertyValue(
            `--${frameworkPrefix}-circle-stroke-width`
          ),
          10
        );

        setComputedValues({
          width: diameter,
          height: diameter,
          strokeWidth,
          radius: diameter / 2 - strokeWidth / 2,
          center: diameter / 2,
        });
      }
    }, [value]);

    const isIndeterminate = typeof value !== 'number';

    const accessibilityProps: Record<string, string | number | undefined> = {
      role: 'progressbar',
      'aria-valuemin': min,
      'aria-valuemax': max,
    };

    if (!isIndeterminate) {
      accessibilityProps['aria-valuenow'] = value;
    }

    /**
     * svg height, width and viewbox values cannot use the `var(prop)` method
     * nor can it be used in cx, cy
     */
    return (
      <div className="klik-progress" {...accessibilityProps} ref={ref} {...props}>
        <div
          className={cn('progress-ring', {
            [`is-${size}`]: size,
          })}
          ref={innerRef}
        >
          <svg
            className={cn('progress-ring-svg')}
            height={computedValues.height}
            viewBox={`0 0 ${computedValues.width} ${computedValues.height}`}
            width={computedValues.width}
          >
            <circle
              className={cn('progress-ring-circle-border')}
              cx={computedValues.center}
              cy={computedValues.center}
              fill="transparent"
              r={computedValues.radius}
              stroke="var(--klik-circle-stroke-border)"
              strokeWidth="var(--klik-circle-stroke-width)"
            />
            <circle
              className={cn('progress-ring-circle')}
              cx={computedValues.center}
              cy={computedValues.center}
              fill="transparent"
              r={computedValues.radius}
              stroke="var(--klik-circle-stroke-color)"
              strokeDasharray="var(--klik-circle-stroke-dasharray)"
              strokeDashoffset="var(--klik-circle-stroke-dashoffset)"
              strokeWidth="var(--klik-circle-stroke-width)"
            />
          </svg>
          <p className={cn('progress-value')}>50%</p>
        </div>
      </div>
    );
  }
);

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable func-style */
import * as React from 'react';
import { ForwardRefAsExoticComponent } from './types';

/**
 * forwardRefAs
 * Render an ExcoticForwarded component as any element
 * If a component share styles and context but needs to behave as a different element
 * use a forwardRefAs component in combination with a Polymorph to render and forward i
 * use cases:
 *
 * <Button as="a" /> or <Button as="button" />
 *
 * <Anchor as="button" /> or <Anchor as={Link} />
 *
 * @param render factory for a Polymorphic Component
 */
export function forwardRefAs<P, E extends React.ElementType = React.ElementType>(
  render: React.ForwardRefRenderFunction<
    HTMLElement | React.ComponentType,
    P & { as: React.ElementType }
  >
): ForwardRefAsExoticComponent<P, E> {
  const forward = React.forwardRef(render);

  return forward as ForwardRefAsExoticComponent<P, E>;
}

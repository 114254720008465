/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesDatabase = React.forwardRef(function FilesDatabase(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M11.25 9.81a10 10 0 00-3.38.53 3.62 3.62 0 00-1.16.66 1.45 1.45 0 00-.53 1.06v4.5a1.45 1.45 0 00.54 1.08c.34.28.725.497 1.14.64a10 10 0 003.38.53 9.975 9.975 0 003.38-.53c.416-.143.802-.36 1.14-.64a1.45 1.45 0 00.54-1.08v-4.5a1.451 1.451 0 00-.51-1.06 3.62 3.62 0 00-1.16-.66 10 10 0 00-3.38-.53zm3.94 6.75s0 .1-.16.24a2.582 2.582 0 01-.79.43 8.86 8.86 0 01-3 .46 8.86 8.86 0 01-3-.46 2.58 2.58 0 01-.79-.43c-.16-.14-.16-.22-.16-.24v-.78c.182.095.369.179.56.25a10 10 0 003.38.53 10 10 0 003.4-.56 4.8 4.8 0 00.56-.25v.81zm0-2.25s0 .1-.16.24a2.58 2.58 0 01-.78.45 8.86 8.86 0 01-3 .46 8.86 8.86 0 01-3-.46 2.58 2.58 0 01-.79-.43c-.16-.14-.16-.22-.16-.24v-.78c.182.095.369.179.56.25a10 10 0 003.38.53 9.975 9.975 0 003.38-.53 4.76 4.76 0 00.56-.25l.01.76zm0-2.25s0 .09-.16.23a2.53 2.53 0 01-.8.44 8.909 8.909 0 01-3 .46 8.909 8.909 0 01-3-.46 2.528 2.528 0 01-.8-.44c-.15-.14-.16-.22-.16-.23 0-.01 0-.09.16-.23a2.53 2.53 0 01.82-.43 8.909 8.909 0 013-.46 8.909 8.909 0 013 .46c.292.097.563.246.8.44.14.16.14.21.14.22z" />
      <path d="M21.35 6.59L15.47.71A2.25 2.25 0 0013.88 0H3.25A2.25 2.25 0 001 2.3v19.4A2.25 2.25 0 003.25 24h16.5A2.25 2.25 0 0022 21.75V8.18a2.25 2.25 0 00-.65-1.59zM14.5 1.86l5.69 5.69h-4.94a.75.75 0 01-.75-.75V1.86zm6 19.84a.75.75 0 01-.75.75H3.25a.75.75 0 01-.75-.75V2.3a.75.75 0 01.75-.75H13v5.26a2.25 2.25 0 002.25 2.25h5.25V21.7z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const SupportChat = React.forwardRef(function SupportChat(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M12.5 2.438a7.188 7.188 0 00-7.152 6.469 1.438 1.438 0 011.402 1.437v7.187a1.438 1.438 0 01-1.438 1.438h-.718A3.594 3.594 0 011 15.375V12.5a3.594 3.594 0 012.9-3.526 8.625 8.625 0 0117.2 0A3.593 3.593 0 0124 12.5v2.875a3.594 3.594 0 01-2.875 3.521v.073a3.593 3.593 0 01-3.594 3.593h-1.56A2.156 2.156 0 0113.936 24H12.5a2.157 2.157 0 010-4.313h1.438a2.156 2.156 0 012.032 1.438h1.561a2.157 2.157 0 002.157-2.156 1.438 1.438 0 01-1.438-1.438v-7.187a1.438 1.438 0 011.401-1.437 7.187 7.187 0 00-7.151-6.47zm1.946 18.898a.719.719 0 01-.508 1.227H12.5a.719.719 0 110-1.438h1.438c.19 0 .373.076.508.21zm5.96-3.805a2.156 2.156 0 002.157-2.156V12.5a2.157 2.157 0 00-2.157-2.156h-.718v7.187h.718zM3.07 10.975a2.156 2.156 0 011.525-.631h.718v7.187h-.718a2.156 2.156 0 01-2.157-2.156V12.5c0-.572.228-1.12.632-1.525z"
        fillRule="evenodd"
      />
      <path d="M10.11 15.267a.719.719 0 00-.97 1.063 4.987 4.987 0 006.72 0 .719.719 0 00-.97-1.063 3.55 3.55 0 01-4.78 0zM9.625 9.85a.72.72 0 01.719.719v1.257a.719.719 0 01-1.438 0V10.57a.72.72 0 01.719-.719zm6.469.719a.719.719 0 10-1.438 0v1.257a.719.719 0 001.438 0V10.57z" />
    </svg>
  );
});

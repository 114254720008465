/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ExpandBold = React.forwardRef(function ExpandBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20.996 8.496a1.5 1.5 0 003 0v-6.5a1.496 1.496 0 00-1.5-1.5h-6.5a1.5 1.5 0 000 3h2.879l-5.94 5.94a1.5 1.5 0 102.121 2.12l5.94-5.939v2.879zm-19 5.728a1.5 1.5 0 011.5 1.5v2.878l5.94-5.939a1.5 1.5 0 112.12 2.121l-5.939 5.94h2.879a1.5 1.5 0 110 3h-6.5a1.494 1.494 0 01-1.374-.897 1.495 1.495 0 01-.126-.603v-6.5a1.5 1.5 0 011.5-1.5z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ThreedPrintingBold = React.forwardRef(function ThreedPrintingBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M10 3.312h.75a.25.25 0 01.25.25v1a1 1 0 102 0v-1a.25.25 0 01.25-.25H14a.75.75 0 00.473-1.332l-2-1.625a.752.752 0 00-.946 0l-2 1.625A.75.75 0 0010 3.312z" />
      <path
        clipRule="evenodd"
        d="M15.04 16.531a2 2 0 00.546 1.945l1.154 1.155a.25.25 0 01-.076.406L12.2 21.99a.5.5 0 01-.4 0l-4.47-1.947a.251.251 0 01-.077-.406l1.161-1.161a2 2 0 10-2.828-2.828l-.659.659a.25.25 0 01-.427-.176v-5.975a.75.75 0 01.449-.687L11.7 6.516a.75.75 0 01.6 0l6.751 2.953c.273.12.449.39.449.687v5.975a.25.25 0 01-.427.176l-.659-.659a2 2 0 00-3.373.883zm1.465-6.375a.25.25 0 00-.15-.229L12.1 8.066a.249.249 0 00-.2 0L7.645 9.927a.25.25 0 000 .458l4.255 1.862a.257.257 0 00.2 0l4.255-1.862a.25.25 0 00.15-.229z"
        fillRule="evenodd"
      />
      <path d="M7.707 17.769a1 1 0 10-1.414-1.414L2.5 20.148a.25.25 0 01-.354 0l-.616-.616a.75.75 0 00-1.28.53v3c0 .414.336.75.75.75h3a.75.75 0 00.53-1.28l-.616-.616a.25.25 0 010-.354l3.793-3.793zm16.043 5.293v-3a.75.75 0 00-1.28-.53l-.616.616a.25.25 0 01-.354 0l-3.793-3.793a1 1 0 10-1.414 1.414l3.793 3.793a.25.25 0 010 .354l-.616.616a.75.75 0 00.53 1.28h3a.75.75 0 00.75-.75z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CloudUpload = React.forwardRef(function CloudUpload(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12.254 22.298a.75.75 0 00.75-.75v-7.19l2.47 2.47a.75.75 0 001.06-1.06l-3.75-3.73a.75.75 0 00-.52-.22.75.75 0 00-.49.22l-3.8 3.73a.764.764 0 101.1 1.06l2.47-2.47v7.18a.75.75 0 00.71.76z" />
      <path d="M18.754 15.888a.75.75 0 01-.22-1.47c.13 0 3.14-1 3.14-4a4 4 0 00-3.48-4.12 1 1 0 01-.88-.65 5.22 5.22 0 00-5.49-3.16c-4.06.23-5 4-5.28 5.26a1.06 1.06 0 01-1 .86 2.79 2.79 0 00-3 2.74 2.54 2.54 0 00.58 2 3.82 3.82 0 002.42 1 .75.75 0 01.73.77.8.8 0 01-.76.73 5.31 5.31 0 01-3.49-1.47 4 4 0 01-1-3.11 4.21 4.21 0 014.13-4.09c1.29-4.84 4.28-6 6.58-6.14a6.79 6.79 0 016.85 3.84 5.48 5.48 0 014.58 5.58 5.86 5.86 0 01-4.21 5.39.731.731 0 01-.2.04z" />
    </svg>
  );
});

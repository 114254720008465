/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesQueryFile = React.forwardRef(function FilesQueryFile(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M22.34 6.59L16.47.71A2.25 2.25 0 0014.88 0H4.22A2.25 2.25 0 002 2.3v19.4A2.25 2.25 0 004.22 24h16.5a2.25 2.25 0 002.25-2.25V8.18a2.25 2.25 0 00-.63-1.59zM15.5 1.86l5.72 5.7h-5a.75.75 0 01-.75-.75l.03-4.95zm6 19.84a.75.75 0 01-.75.75H4.22a.75.75 0 01-.75-.75V2.3a.75.75 0 01.75-.75h9.75v5.26a2.25 2.25 0 002.25 2.25h5.25l.03 12.64z" />
      <path d="M16.34 11.75a1.84 1.84 0 00-1.93-1.69h-.61a.64.64 0 100 1.28h.69a.58.58 0 01.6.5v1.05a1.73 1.73 0 00.89 1.46l.38.22-.39.2a1.7 1.7 0 00-.89 1.46v1.05a.57.57 0 01-.6.5h-.69a.64.64 0 100 1.28h.72a1.83 1.83 0 001.82-1.69v-1.09a.42.42 0 01.23-.36l1.34-.77a.63.63 0 00.32-.55.61.61 0 00-.31-.57l-1.34-.77a.46.46 0 01-.23-.37v-1.14zm-8.238-.057a1.84 1.84 0 011.93-1.69h.61a.64.64 0 110 1.28h-.69a.58.58 0 00-.6.5v1.05a1.73 1.73 0 01-.89 1.46l-.38.22.39.2a1.7 1.7 0 01.89 1.46v1.05a.57.57 0 00.6.5h.69a.64.64 0 110 1.28h-.72a1.83 1.83 0 01-1.82-1.69v-1.09a.42.42 0 00-.23-.36l-1.34-.77a.63.63 0 01-.32-.55.61.61 0 01.31-.57l1.34-.77a.46.46 0 00.23-.37v-1.14z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesToDo = React.forwardRef(function FilesToDo(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M10.12 9.027a.75.75 0 01.149 1.05l-2.132 2.845a1.5 1.5 0 01-2.26.16L4.72 11.927a.751.751 0 011.06-1.062l1.157 1.157L9.07 9.177a.75.75 0 011.05-.15zm.149 7.806a.751.751 0 00-1.2-.9l-2.132 2.844-1.157-1.158a.75.75 0 10-1.06 1.061l1.157 1.158a1.5 1.5 0 002.26-.16l2.132-2.845zM12 12.772a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zm.75 5.254a.75.75 0 000 1.502h4.5a.75.75 0 000-1.502h-4.5z" />
      <path
        clipRule="evenodd"
        d="M22.5 21.66a2.253 2.253 0 01-2.25 2.251H3.75a2.25 2.25 0 01-2.25-2.252V2.263A2.253 2.253 0 013.75.01h10.629a2.25 2.25 0 011.59.659l5.873 5.877c.421.423.658.995.658 1.592v13.52zM3.22 1.731a.75.75 0 01.53-.22h9.75v5.255a2.253 2.253 0 002.25 2.252H21v12.64a.75.75 0 01-.75.75H3.75a.75.75 0 01-.75-.75V2.263c0-.2.079-.39.22-.53zm17.47 5.785h-4.94a.75.75 0 01-.75-.75V1.823l5.69 5.694z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { StepperItem, StepperItemProps, StepperState } from './StepperItem';

export type StepperProps = {
  isVertical?: boolean;
  alignment?: 'left' | 'center' | 'right';
  activeStep?: number;
  onChange?: (index: number) => void;
  completedSteps?: number[];
  children: React.ReactElement<StepperItemProps>[] | React.ReactElement<StepperItemProps>;
};

interface IStepperSubComponents {
  Item: typeof StepperItem;
}

/**
 * Stepper
 * Stepper base element
 *
 * @example <Stepper>
 *            <Stepper.item />
 *          </Stepper>
 *
 * @param param0
 * @returns Stepper Component
 * @memberof stepper
 * @author Simon Groenborg
 */
export const Stepper: React.FunctionComponent<StepperProps> & IStepperSubComponents = ({
  children,
  isVertical,
  alignment = 'center',
  activeStep,
  completedSteps,
  onChange,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const state = React.useCallback(
    (index: number): StepperState => {
      if (index === activeStep) {
        return 'active';
      }

      return completedSteps?.includes(index) ? 'complete' : 'incomplete';
    },
    [activeStep, completedSteps]
  );

  return (
    <ul
      className={cn('stepper', {
        'is-vertical': isVertical,
        [`is-${alignment}`]: alignment,
      })}
      {...props}
    >
      {React.Children.map(children, (child: React.ReactElement<StepperItemProps>, index) => {
        return React.cloneElement(child, {
          index: index + 1,
          state: state(index + 1),
          onClick: () => {
            if (onChange && state(index + 1) === 'complete') {
              onChange(index + 1);
            }
          },
          ...child.props,
        });
      })}
    </ul>
  );
};

Stepper.Item = StepperItem;

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const GraphBar = React.forwardRef(function GraphBar(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 25 24" width={25} {...props}>
      <path
        clipRule="evenodd"
        d="M21.751 0a2.25 2.25 0 00-2.22 2.612L16.79 4.754a2.25 2.25 0 00-2.87.69l-3.42-.915v-.028a2.25 2.25 0 10-4.463.406l-3.384 3.38a2.25 2.25 0 101.336.786l2.835-2.832a2.25 2.25 0 003.154-.299l3.527.944a2.25 2.25 0 104.31-1.03l2.451-1.916A2.25 2.25 0 1021.751 0zM2.542 9.81l.032.015a.75.75 0 11-.032-.015zm18.679-8.09a.75.75 0 111.06 1.06.75.75 0 01-1.06-1.06zM7.564 4.804l.006.014a.75.75 0 10-.006-.014zM15.75 6a.75.75 0 100 1.5.75.75 0 000-1.5z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9 22.5V10.25A1.25 1.25 0 0110.25 9h2.751a1.25 1.25 0 011.25 1.25V22.5h2.25v-7.75a1.25 1.25 0 011.247-1.25h2.754a1.25 1.25 0 011.25 1.25v7.75h.749a.75.75 0 010 1.5H.75a.75.75 0 010-1.5h.75v-3.25A1.25 1.25 0 012.75 18h2.751a1.25 1.25 0 011.25 1.25v3.25H9zm1.5 0v-12h2.251v12H10.5zm9.751 0h-2.25V15h2.25v7.5zM3 19.5v3h2.251v-3H3z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Upload = React.forwardRef(function Upload(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M12.52 3.21a.747.747 0 00-1.05.01l-4.5 4.5a.75.75 0 101.061 1.06l3.22-3.22v10.19a.75.75 0 001.5 0V5.56l3.219 3.22a.75.75 0 101.06-1.06l-4.5-4.5a.544.544 0 00-.01-.01z" />
      <path d="M1.5 15.75a.75.75 0 10-1.499 0v1.5A3.75 3.75 0 003.751 21h16.5A3.75 3.75 0 0024 17.25v-1.5a.75.75 0 00-1.5 0v1.5a2.25 2.25 0 01-2.25 2.25H3.75a2.25 2.25 0 01-2.25-2.25v-1.5z" />
    </svg>
  );
});

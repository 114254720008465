/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const RefreshBold = React.forwardRef(function RefreshBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M14.339 3.815a8.834 8.834 0 00-11.044 7.02l-.234-.234a1.5 1.5 0 10-2.122 2.122l2.617 2.616a1.496 1.496 0 002.224-.002l2.614-2.614a1.5 1.5 0 00-1.905-2.301 5.833 5.833 0 018.995-2.767 1.5 1.5 0 101.792-2.406 8.832 8.832 0 00-2.937-1.434zm5.625 5.819a1.495 1.495 0 00-1.157-.044 1.495 1.495 0 00-.534.345L15.606 12.6a1.5 1.5 0 001.534 2.485 5.825 5.825 0 01-2.986 2.663 5.833 5.833 0 01-5.336-.532 1.5 1.5 0 10-1.636 2.514 8.833 8.833 0 0013.41-5.355l.347.347a1.5 1.5 0 102.122-2.122l-2.656-2.655a1.495 1.495 0 00-.44-.312z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { MenuBlocks } from './MenuBlocks';
import { MenuDropdown } from './MenuDropdown';
import { MenuIcons } from './MenuIcons';
import { MenuItem } from './MenuItem';
import { MenuList } from './MenuList';
import { MenuPages } from './MenuPages';
import { MenuStack } from './MenuStack';
import { MenuTrigger } from './MenuTrigger';

export interface IMenuSubComponents {
  Dropdown: typeof MenuDropdown;
  List: typeof MenuList;
  Stack: typeof MenuStack;
  Blocks: typeof MenuBlocks;
  Item: typeof MenuItem;
  Icons: typeof MenuIcons;
  Trigger: typeof MenuTrigger;
  Pages: typeof MenuPages;
}

/**
 * Menu
 * React Menu component.
 *
 *
 * @returns Menu Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const Menu: React.FunctionComponent & IMenuSubComponents = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <div className={cn('menu')}>{children}</div>;
};

Menu.Dropdown = MenuDropdown;
Menu.List = MenuList;
Menu.Stack = MenuStack;
Menu.Blocks = MenuBlocks;
Menu.Icons = MenuIcons;
Menu.Item = MenuItem;
Menu.Trigger = MenuTrigger;
Menu.Pages = MenuPages;

import * as React from 'react';

type PressHookProps = {
  onPressStart?: (e: React.SyntheticEvent) => void;
  onPressEnd?: (e: React.SyntheticEvent) => void;
  onPressChange?: (e: React.SyntheticEvent) => void;
  onPress?: (e: React.SyntheticEvent) => void;
  isDisabled?: boolean;
  ref: React.RefObject<HTMLElement | null>;
};

export interface IOnPress {
  onPressStart?: (e: React.SyntheticEvent) => void;
  onPressEnd?: (e: React.SyntheticEvent) => void;
  onPressChange?: (e: React.SyntheticEvent) => void;
  onPress?: (e: React.SyntheticEvent) => void;
  isDisabled?: boolean;
}

export type PressHookActions = {
  isPressed: boolean;
  pressProps: any;
};

/**
 * TODO: Normalizing the inputs as in spectrum will make it easier in the long run to have control
 * over focus, and interaction events. The exact consequences are unknown as of now, but this will
 * especially help for touch controls.
 * this needs to be wrapped up. We should start with click and touch events and eventually, look
 * at keyboard events as well.
 *
 * @param param0
 */
export const usePress = ({ onPress, ref }: PressHookProps): PressHookActions => {
  React.useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line no-console
      console.log(onPress);
    }
  });

  return {
    isPressed: true,
    pressProps: {},
  };
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LogOutBold = React.forwardRef(function LogOutBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.257.75a2.808 2.808 0 012.742 2.894v16.344a2.809 2.809 0 01-2.742 2.894H10.076c-.025 0-.05 0-.075-.002a2.81 2.81 0 01-2.668-2.892V18a1.5 1.5 0 113 0v1.882h10.666V3.75H10.333V5.5a1.5 1.5 0 01-3 0V3.644A2.81 2.81 0 0110.076.75h11.18z" />
      <path d="M5.977 7.343a1.5 1.5 0 00-2.121 0L.45 10.749a1.5 1.5 0 000 2.142l3.406 3.406a1.5 1.5 0 102.121-2.122l-.856-.855H15.85a1.5 1.5 0 000-3H5.121l.856-.856a1.5 1.5 0 000-2.121z" />
    </svg>
  );
});

import * as React from 'react';
import { getValidChildren, useFrameworkClassnamePrefixer } from '../../utils';
import { BreadcrumbLinkProps } from './BreadcrumbLink';
import { BreadcrumbSeparator } from './BreadcrumbSeparator';

export type BreadcrumbItemProps = React.HTMLAttributes<HTMLLIElement> & {
  isActive?: boolean;
  hasDropdown?: boolean;
  isLastItem?: boolean;
};

// there is no HTMLNavElement
export const BreadcrumbItem = React.forwardRef<HTMLLIElement, BreadcrumbItemProps>(
  function BreadcrumbItem({ children, hasDropdown, isActive, isLastItem, ...props }, ref) {
    const cn = useFrameworkClassnamePrefixer();

    const className = cn('breadcrumb-item', {
      'is-current': isActive,
      'has-dropdown': hasDropdown,
    });

    const validChildren = getValidChildren<BreadcrumbLinkProps>(children);
    const validChildrenCount = validChildren.length;

    return (
      <li className={className} ref={ref} {...props}>
        {validChildren.map((child, index) =>
          React.cloneElement(child, {
            isActive,
            isLastChild: validChildrenCount === index + 1,
          })
        )}
        {!isLastItem && <BreadcrumbSeparator />}
      </li>
    );
  }
);

BreadcrumbItem.displayName = 'Breadcrumb.Item';

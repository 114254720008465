import { ArrowDownBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { makeAccordionHeaderId, makeAccordionPanelId } from './utils';

export interface IAccordionItemProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  id?: string;
  isDisabled?: boolean;
  isExpanded?: boolean;
  index?: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
}

export const AccordionItem: React.FunctionComponent<IAccordionItemProps> = ({
  children,
  isDisabled,
  isExpanded = false,
  label,
  id = 'accordion',
  index = 0,
  onClick,
}) => {
  const cn = useFrameworkClassnamePrefixer();
  const [isOpen, setOpen] = React.useState(isExpanded);

  const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => {
    onClick?.(event);
    setOpen(!isOpen);
  };

  const headerId = makeAccordionHeaderId(id, index);
  const panelId = makeAccordionPanelId(id, index);

  return (
    <React.Fragment>
      <button
        aria-controls={panelId}
        aria-disabled={isDisabled}
        aria-expanded={isOpen}
        className={cn('accordion-trigger')}
        disabled={isDisabled}
        id={headerId}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleClick}
      >
        <div className={cn('accordion-trigger-text')}>{label}</div>
        <span className={cn('icon')}>
          <ArrowDownBold />
        </span>
      </button>
      <div
        aria-hidden={!isOpen}
        aria-labelledby={headerId}
        className={cn('accordion-panel')}
        hidden={!isOpen}
        id={panelId}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

AccordionItem.displayName = 'Accordion.Item';

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { useTabsContext } from './TabsContext';
import { makeTabId, makeTabPanelId } from './utils';

export interface ITabProps {
  children: React.ReactNode;
  index?: number;
  isDisabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
}

export const Tab: React.FunctionComponent<ITabProps> = ({
  children,
  index,
  isDisabled = false,
  ...props
}) => {
  const { id, activeTab, setActiveTab, focusedTab, setFocusedTab } = useTabsContext();
  const cn = useFrameworkClassnamePrefixer();

  if (index === undefined) {
    // eslint-disable-next-line no-null/no-null
    return null;
  }

  const className = cn('tab', {
    'is-active': activeTab === index,
    'is-focused': focusedTab === index,
    'is-disabled': isDisabled,
  });

  return (
    <li className={className} role="presentation">
      <button
        aria-controls={activeTab === index ? makeTabPanelId(id, index) : undefined}
        aria-selected={activeTab === index ? true : undefined}
        id={makeTabId(id, index)}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          setFocusedTab(index);
          setActiveTab(index);
        }}
        role="tab"
        tabIndex={activeTab === index ? 0 : -1}
        {...props}
      >
        {children}
      </button>
    </li>
  );
};

Tab.displayName = 'Tabs.Tab';

import { CheckTickBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { Pair } from '../../utility/types';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';

export interface IDropdownItemProps<T extends string | number> {
  value: T;
  label: string;
  selected?: boolean;
  onClick?: (option: Pair<T>) => void;
}

/**
 * DropDownItem
 * The dropdown item is a list element used inside the dropdown
 * It takes a generic Pair<T> containing the data for this entry.
 * TODO: Add aria attributes and a11y navigation
 *
 *
 * @param is the item selected
 * @param option value and label for this item
 * @param onClick click handler for paging
 * @returns DropDown List Item
 * @memberof dropdown
 * @author Simon Groenborg
 */
export const DropdownItem = <T extends string | number>({
  selected = false,
  value,
  label,
  onClick,
}: React.PropsWithChildren<IDropdownItemProps<T>>): JSX.Element => {
  const cn = useFrameworkClassnamePrefixer();

  const clickHandler = React.useCallback(() => {
    if (onClick) {
      onClick({ value, label });
    }
  }, [value, label]);

  return (
    <div
      aria-label={label}
      className={cn('dropdown-item', {
        ['is-selected']: selected,
      })}
      key={label}
      onClick={clickHandler}
      role="button"
    >
      <span className={cn('dropdown-item-content')}>{label}</span>
      {selected && <Icon icon={<CheckTickBold />} />}
    </div>
  );
};

DropdownItem.displayName = 'Dropdown.Item';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Search = React.forwardRef(function Search(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M10 1.25a8.75 8.75 0 105.634 15.445l5.836 5.835a.75.75 0 001.06-1.06l-5.835-5.836A8.75 8.75 0 0010 1.25zM2.75 10a7.25 7.25 0 1114.5 0 7.25 7.25 0 01-14.5 0z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Security = React.forwardRef(function Security(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M21.181 1.874A22.449 22.449 0 0012 .001 22.45 22.45 0 002.806 1.88 2.237 2.237 0 001.5 3.926v7.611A12.657 12.657 0 009.612 23.35l1.041.4a3.75 3.75 0 002.692 0l1.042-.4a12.656 12.656 0 008.112-11.813V3.926a2.238 2.238 0 00-1.318-2.052zM3 3.924v-.005a.737.737 0 01.425-.673 20.95 20.95 0 018.582-1.745 20.95 20.95 0 018.567 1.745.737.737 0 01.425.673v7.619a11.156 11.156 0 01-7.15 10.412l-1.042.4c-.52.2-1.095.2-1.615 0l-1.041-.4A11.156 11.156 0 013 11.538V3.923z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.745 12.917a.696.696 0 10-1.391 0V14.5a.696.696 0 001.391 0v-1.583z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.583 8.167v1.284h-.887a.696.696 0 00-.696.695v7.125c0 .384.311.696.696.696h8.707a.696.696 0 00.696-.696v-7.125a.696.696 0 00-.695-.695h-.888V8.167a3.467 3.467 0 10-6.933 0zm2-1.467a2.075 2.075 0 013.541 1.467v1.284h-4.15V8.167c0-.55.22-1.078.608-1.467zM8.39 16.575v-5.733h7.317v5.733H8.39z"
        fillRule="evenodd"
      />
    </svg>
  );
});

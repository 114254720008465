/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const AccountingDocumentBold = React.forwardRef(function AccountingDocumentBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M21.561 5.854L16.146.439A1.511 1.511 0 0015.086 0H3.5A1.5 1.5 0 002 1.5v21A1.5 1.5 0 003.5 24h18a.5.5 0 00.5-.5V6.914a1.49 1.49 0 00-.439-1.06zM9.817 11.183l-3.37-1.26A2.55 2.55 0 015 7.455 2.529 2.529 0 017.289 4.92a.249.249 0 00.211-.247V4a1 1 0 012 0v.625c0 .138.112.25.25.25H11a1 1 0 110 2H7.875C7 6.875 7 7.311 7 7.455a.9.9 0 00.183.612l3.37 1.26A2.55 2.55 0 0112 11.8a2.53 2.53 0 01-2.289 2.53.249.249 0 00-.211.247v.673a1 1 0 11-2 0v-.625a.25.25 0 00-.25-.25H6a1 1 0 110-2h3.125c.875 0 .875-.436.875-.58a.9.9 0 00-.183-.612zM19.25 15.5a.75.75 0 00-.75-.75h-5a.75.75 0 000 1.5h5a.75.75 0 00.75-.75zm-4.5-4.5a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zm4.5 9a.75.75 0 00-.75-.75h-10a.75.75 0 000 1.5h10a.75.75 0 00.75-.75z"
        fillRule="evenodd"
      />
    </svg>
  );
});

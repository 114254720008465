/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Analytics = React.forwardRef(function Analytics(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M.75 0a.75.75 0 01.75.75v9.175l4.545-2.727a2.25 2.25 0 114.448-.272l2.873 1.276.043-.043a2.25 2.25 0 012.33-.534l3.836-4.796a2.25 2.25 0 11.9 1.276L16.91 8.56a2.25 2.25 0 11-4.153 1.012L9.884 8.296a2.25 2.25 0 01-3.067.188L1.5 11.674v7.362l3.017-1.509A2.25 2.25 0 018.871 16.5h3.257a2.248 2.248 0 013.611-.936l3.76-2.089a2.25 2.25 0 11.5 1.439l-3.531 1.961a2.253 2.253 0 01-.627 1.966 2.25 2.25 0 01-3.713-.84H8.871a2.25 2.25 0 01-3.683.869L1.5 20.713v1.786h21.75a.75.75 0 010 1.5H.75a.75.75 0 01-.75-.75V.75A.75.75 0 01.75 0zM7.5 17.25a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM21.08 2.59l.017.03a.749.749 0 001.401-.37.75.75 0 10-1.418.34zM7.607 7.137l.003.006a.75.75 0 10-.007-.012l.004.006zm6.112 10.644a.75.75 0 111.06-1.06.75.75 0 01-1.06 1.06zm7.309-4.077a.75.75 0 101.444-.408.75.75 0 00-1.444.409zM14.469 9.22a.75.75 0 111.061 1.062.75.75 0 01-1.06-1.062z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { Sizes } from '../../constants';
import { useFrameworkClassnamePrefixer } from '../../utils';

export interface IButtonToggleItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  index?: number;
  isSelected?: boolean;
  isFocus?: boolean;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  iconSize?: Extract<Sizes, 'small' | 'medium' | 'large'>;
  label?: string;
}

export const ButtonToggleItem = React.forwardRef<HTMLButtonElement, IButtonToggleItemProps>(
  function ButtonToggleItem(
    {
      isSelected,
      isFocus,
      isDisabled,
      label,
      icon,
      iconPosition = 'left',
      iconSize = 'medium',
      ...props
    },
    ref
  ) {
    const cn = useFrameworkClassnamePrefixer();
    const iconOnly = icon && !label;
    const iconElement = <span className={cn('icon')}>{icon}</span>;

    const className = cn('button-toggle-item', {
      'is-selected': isSelected,
      'is-focus': isFocus,
      'is-disabled': isDisabled,
      'is-icon-only': iconOnly,
      [`is-icon-${iconSize}`]: iconSize,
    });

    return (
      <button className={className} disabled={isDisabled} ref={ref} {...props}>
        {
          // eslint-disable-next-line no-null/no-null
          icon ? iconPosition === 'left' && iconElement : null
        }
        {label && <span className={cn('button-toggle-text')}>{label}</span>}
        {
          // eslint-disable-next-line no-null/no-null
          icon ? iconPosition === 'right' && iconElement : null
        }
      </button>
    );
  }
);

ButtonToggleItem.displayName = 'ButtonToggle.Item';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const BookmarkFavouriteStar = React.forwardRef(function BookmarkFavouriteStar(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M18.75 0A2.25 2.25 0 0121 2.25v20.252a1.5 1.5 0 01-2.382 1.21L12.44 19.22a.75.75 0 00-.882 0l-6.177 4.492A1.5 1.5 0 013 22.5V2.25A2.25 2.25 0 015.25 0h13.5zm0 1.5H5.25a.75.75 0 00-.75.75v20.248l6.177-4.492a2.25 2.25 0 012.646 0l6.177 4.492V2.25a.75.75 0 00-.75-.75zM12 4.715c.507 0 .97.285 1.199.736l1.039 2.047 1.65.001c.502-.012.965.26 1.202.694l.059.123a1.33 1.33 0 01-.298 1.468l-1.586 1.56.88 2.022a1.34 1.34 0 01-.249 1.424l-.102.1c-.432.38-1.058.44-1.548.156L12 13.778l-2.237 1.26a1.339 1.339 0 01-1.445-.064l-.111-.088a1.342 1.342 0 01-.348-1.533l.876-2.013-1.58-1.556a1.332 1.332 0 01-.349-1.344l.046-.128c.212-.506.713-.83 1.243-.817l1.667-.001 1.04-2.042c.208-.41.61-.684 1.062-.73L12 4.715zm-.001 1.688l-1.11 2.182a.75.75 0 01-.668.41L8.49 8.994l1.664 1.638a.75.75 0 01.2.726l-.038.108-.889 2.038 2.205-1.24a.75.75 0 01.636-.047l.1.048 2.203 1.242-.887-2.037a.75.75 0 01.086-.747l.076-.087 1.664-1.638-1.731.001a.75.75 0 01-.615-.32l-.054-.09L12 6.403z" />
    </svg>
  );
});

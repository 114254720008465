import * as React from 'react';
import { NotificationActions, NotificationContext } from '../context/NotificationContext';

/**
 * useNotification
 * use the hook to control the notifications of the application
 *
 * @returns NotificationActions
 * @memberof hooks
 * @author Simon Groenborg
 */
export const useNotification = (): NotificationActions => {
  const ctx = React.useContext(NotificationContext);

  if (!ctx) {
    throw new Error('"useNotification" can only be called inside of NotificationContextProvider!');
  }

  return ctx;
};

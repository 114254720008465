import * as React from 'react';

enum ClickResult {
  OutsideElement = 0,
  InsideElement = 1,
}

/**
 * useClickOutside hook
 * detect if there was a click outside the targeted element
 * use this hook to subscribe add behaviour to a component when the parent dom is targeted
 *
 * @param focusedElement the element which should be in focus
 * @param clickOutsideCallback callback when a touch or mousedown event is registered outside
 * @returns ClickOutside detection hook
 * @memberof hooks
 * @author Simon Groenborg
 */
export const useClickOutside = (
  focusedElement: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
  clickOutsideCallback: (event: MouseEvent | TouchEvent) => void,
  deps?: React.DependencyList | undefined
): void => {
  // This function runs on every click-event, for every single menu.
  // be aware the cancelling the event propagation can impact monitoring tools
  const detectClickOutside = (event: MouseEvent | TouchEvent): void => {
    if (Array.isArray(focusedElement)) {
      const res = focusedElement.map((elm) => {
        if (elm.current) {
          if (elm.current.contains(event.target as Element)) {
            return ClickResult.InsideElement;
          }
        }

        return ClickResult.OutsideElement;
      });

      if (!res.includes(ClickResult.InsideElement)) {
        clickOutsideCallback(event);
      }

      return;
    }

    if (focusedElement.current) {
      if (!focusedElement.current.contains(event.target as Element)) {
        clickOutsideCallback(event);
      }
    }
  };

  // Initialize the position of the dropdown.
  // TODO: document event listeners should be removed in the future.
  React.useLayoutEffect(() => {
    document.addEventListener('mousedown', detectClickOutside);
    document.addEventListener('touchstart', detectClickOutside);

    return (): void => {
      document.removeEventListener('mousedown', detectClickOutside);
      document.removeEventListener('touchstart', detectClickOutside);
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  }, [focusedElement, ...(deps ? deps : [])]);
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const StatusWarning = React.forwardRef(function StatusWarning(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={25} ref={svgRef} viewBox="0 0 24 25" width={24} {...props}>
      <path d="M23.75 20.727L14.289 1.424C13.98.807 13.466.344 12.815.138a2.576 2.576 0 00-1.954.12 2.56 2.56 0 00-1.166 1.166L.23 20.727a2.272 2.272 0 001.028 3.034c.31.154.652.24.995.24H21.71a2.26 2.26 0 002.263-2.263 2.037 2.037 0 00-.223-1.011zm-1.49 1.543a.793.793 0 01-.55.223H2.255a.791.791 0 01-.703-1.114l9.497-19.287c.103-.205.274-.377.48-.48a1.14 1.14 0 01.463-.102c.394 0 .771.222.943.582l9.48 19.303a.781.781 0 01.085.343c-.017.206-.085.395-.24.532z" />
      <path d="M11.558 16.119h1.152l.252-8.118h-1.674l.27 8.118zm.576 3.96c.63 0 1.134-.468 1.134-1.134 0-.666-.504-1.134-1.134-1.134-.612 0-1.134.468-1.134 1.134 0 .666.522 1.134 1.134 1.134z" />
    </svg>
  );
});

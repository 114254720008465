/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesPdf = React.forwardRef(function FilesPdf(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M2.25 1.494a.752.752 0 00-.75.747v19.418a.745.745 0 00.75.747h3c.414 0 .75.335.75.747a.748.748 0 01-.75.747h-3a2.255 2.255 0 01-1.591-.656A2.236 2.236 0 010 21.659V2.241C0 1.646.237 1.076.659.656 1.081.236 1.653 0 2.25 0h10.629a2.26 2.26 0 011.592.657l5.87 5.847A2.24 2.24 0 0121 8.088v4.609a.748.748 0 01-.75.747.748.748 0 01-.75-.747V8.963h-5.25a2.255 2.255 0 01-1.591-.657A2.236 2.236 0 0112 6.722V1.494H2.25zM13.5 6.722V1.803l5.69 5.666h-4.94a.751.751 0 01-.75-.747z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.25 14.935a.748.748 0 00-.75.747v7.47c0 .412.336.746.75.746s.75-.334.75-.747v-2.24h.004c.796 0 1.559-.315 2.121-.876a2.981 2.981 0 000-4.225 3.006 3.006 0 00-2.121-.875H8.25zM9 16.43v2.988h.004a1.5 1.5 0 001.06-.438 1.49 1.49 0 000-2.112 1.503 1.503 0 00-1.06-.438H9zm5.25-1.495a.748.748 0 00-.75.747v7.47a.745.745 0 00.75.746c.995 0 1.948-.393 2.652-1.094A3.727 3.727 0 0018 20.164V18.67c0-.99-.395-1.94-1.098-2.64a3.758 3.758 0 00-2.652-1.095zm.75 7.341v-5.719a2.25 2.25 0 011.5 2.113v1.494a2.236 2.236 0 01-1.5 2.112z"
        fillRule="evenodd"
      />
      <path d="M21.75 16.43a.751.751 0 00-.75.746v2.24h1.5a.748.748 0 110 1.494H21v2.241a.748.748 0 01-.75.747.748.748 0 01-.75-.747v-5.975c0-.594.237-1.164.659-1.584.422-.42.994-.657 1.591-.657h1.5c.414 0 .75.335.75.747a.748.748 0 01-.75.747h-1.5z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type BreadcrumbLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  isActive?: boolean;
  isLastChild?: boolean;
};

// there is no HTMLNavElement
export const BreadcrumbLink = React.forwardRef<HTMLAnchorElement, BreadcrumbLinkProps>(
  function BreadcrumbLink({ children, isActive, ...props }, ref) {
    const cn = useFrameworkClassnamePrefixer();

    const className = cn('breadcrumb-link');

    const commonProps = { ref, className, ...props };

    return (
      <a {...(isActive ? { 'aria-current': 'page' } : {})} href="#" {...commonProps}>
        {children}
      </a>
    );
  }
);

BreadcrumbLink.displayName = 'Breadcrumb.Link';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Server = React.forwardRef(function Server(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M13.509 9H12a.761.761 0 00-.754.754c0 .412.343.755.754.755h1.509a.761.761 0 00.754-.755.761.761 0 00-.754-.754zm6.737.754A.76.76 0 0019.49 9h-3a.761.761 0 00-.754.754c0 .412.343.755.754.755h3c.429-.018.755-.343.755-.755zM5.623 8.623c-.617 0-1.132.497-1.132 1.114 0 .617.498 1.132 1.115 1.132a1.13 1.13 0 001.131-1.132c.017-.6-.497-1.114-1.114-1.114zM13.509 18H12a.761.761 0 00-.754.754c0 .412.343.755.754.755h1.509a.761.761 0 00.754-.755.761.761 0 00-.754-.754zm6 0H16.49a.761.761 0 00-.754.754c0 .412.343.755.754.755h3a.761.761 0 00.755-.755.746.746 0 00-.737-.754z" />
      <path d="M21.446 14.246A5.235 5.235 0 0024 9.754a5.04 5.04 0 00-.497-2.211v-.017l-2.52-5.366A3.742 3.742 0 0017.589 0H6.41a3.76 3.76 0 00-3.394 2.16L.497 7.526v.017A5.04 5.04 0 000 9.754a5.235 5.235 0 002.554 4.492A5.264 5.264 0 000 18.754 5.246 5.246 0 005.246 24h13.508A5.246 5.246 0 0024 18.754c0-1.92-1.029-3.583-2.554-4.508zM4.37 2.794a2.27 2.27 0 012.04-1.303h11.195a2.27 2.27 0 012.04 1.303l.96 2.04a5.357 5.357 0 00-1.835-.325H5.246c-.652 0-1.269.12-1.835.325l.96-2.04zm-2.86 6.96A3.748 3.748 0 015.246 6h13.508a3.753 3.753 0 013.755 3.754 3.753 3.753 0 01-3.755 3.755H5.246c-2.057 0-3.737-1.698-3.737-3.755zm17.245 12.737H5.246a3.753 3.753 0 01-3.755-3.754 3.753 3.753 0 013.755-3.754h13.508a3.753 3.753 0 013.755 3.754 3.764 3.764 0 01-3.755 3.754z" />
      <path d="M5.623 17.623c-.617 0-1.132.497-1.132 1.114 0 .309.12.583.326.789a1.13 1.13 0 001.92-.806c.017-.583-.497-1.097-1.114-1.097z" />
    </svg>
  );
});

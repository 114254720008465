import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { CardAction } from './CardAction';
import { CardActions } from './CardActions';
import { CardBody } from './CardBody';
import { CardContent } from './CardContent';
import { CardDivider } from './CardDivider';
import { CardFigure } from './CardFigure';
import { CardFooter } from './CardFooter';
import { CardHeader } from './CardHeader';
import { CardHeading } from './CardHeading';
import { CardHeadings } from './CardHeadings';
import { CardImage } from './CardImage';
import { CardItem } from './CardItem';
import { CardLinks } from './CardLinks';
import { CardSubHeading } from './CardSubHeading';

export interface ICardProps {
  isCompact?: boolean;
  isLandscape?: boolean;
  children: React.ReactNode;
}

export interface ICardSubComponents {
  Action: typeof CardAction;
  Actions: typeof CardActions;
  Body: typeof CardBody;
  Content: typeof CardContent;
  Divider: typeof CardDivider;
  Figure: typeof CardFigure;
  Footer: typeof CardFooter;
  Header: typeof CardHeader;
  Heading: typeof CardHeading;
  Headings: typeof CardHeadings;
  Image: typeof CardImage;
  Item: typeof CardItem;
  Links: typeof CardLinks;
  SubHeading: typeof CardSubHeading;
}

export type CardType = React.FunctionComponent<ICardProps> & ICardSubComponents;

/**
 * Card
 * @param param0
 */
export const Card: CardType = ({ children, isCompact, isLandscape }) => {
  const cn = useFrameworkClassnamePrefixer();

  const className = cn('card', {
    'is-compact': isCompact,
    'is-landscape': isLandscape,
  });

  return <div className={className}>{children}</div>;
};

Card.Action = CardAction;
Card.Actions = CardActions;
Card.Body = CardBody;
Card.Content = CardContent;
Card.Divider = CardDivider;
Card.Figure = CardFigure;
Card.Footer = CardFooter;
Card.Header = CardHeader;
Card.Heading = CardHeading;
Card.Headings = CardHeadings;
Card.Image = CardImage;
Card.Item = CardItem;
Card.Links = CardLinks;
Card.SubHeading = CardSubHeading;

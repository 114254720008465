/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LinkBold = React.forwardRef(function LinkBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M17.02 6.183a2.331 2.331 0 113.297 3.297l-5.588 5.588a2.332 2.332 0 01-3.536-3.018 1.5 1.5 0 00-2.426-1.764 5.332 5.332 0 008.085 6.902l5.586-5.586a5.331 5.331 0 10-7.54-7.54 1.5 1.5 0 102.122 2.12z" />
      <path d="M6.98 17.506a2.332 2.332 0 01-3.297-3.298L9.27 8.62a2.332 2.332 0 013.536 3.019 1.5 1.5 0 002.426 1.764A5.331 5.331 0 007.148 6.5l-5.586 5.587a5.332 5.332 0 007.54 7.54 1.5 1.5 0 10-2.122-2.121z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowUpAndDownBold = React.forwardRef(function ArrowUpAndDownBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M13.053.931a1.5 1.5 0 00-2.106 0l-6.6 6.5A1.5 1.5 0 006.453 9.57L12 4.105l5.547 5.464a1.5 1.5 0 102.106-2.138l-6.6-6.5zm-6.6 13.5a1.5 1.5 0 10-2.106 2.138l6.6 6.5a1.5 1.5 0 002.106 0l6.6-6.5a1.5 1.5 0 00-2.105-2.138L12 19.895 6.453 14.43z" />
    </svg>
  );
});

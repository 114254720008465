/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const StatusWarningBold = React.forwardRef(function StatusWarningBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={25} ref={svgRef} viewBox="0 0 24 25" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M14.297 1.424l9.463 19.303c.154.308.24.651.223 1.011a2.26 2.26 0 01-2.263 2.263H2.263c-.343 0-.686-.086-.994-.24a2.272 2.272 0 01-1.03-3.034L9.704 1.424A2.56 2.56 0 0110.868.258a2.576 2.576 0 011.955-.12c.651.206 1.165.669 1.474 1.286zm-1.263 14.352h-2.046l-.483-7.775h2.994l-.465 7.775zm.726 2.995c0 .986-.744 1.73-1.711 1.73-1.024 0-1.749-.744-1.749-1.73 0-.967.725-1.73 1.749-1.73.967 0 1.71.763 1.71 1.73z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import * as React from 'react';
import { FontSizeMap, SIZE_MAP } from '../constants';

/**
 * useFont
 * use the hook to get the correct fontsize of an element
 * TODO: this hooks could be extended with font weigth, or moved to a font utility or base props class
 * TODO: useMemo probably has worse performance that just recalculating the if statement.
 * @param font size key
 * @return css class of the selected font size
 */
export const useFont = (fontSize?: keyof FontSizeMap): string => {
  const size = React.useMemo(() => {
    if (!fontSize) {
      return '';
    }

    return `text-size-${SIZE_MAP[fontSize]}`;
  }, [fontSize]);

  return size;
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ArrowRightBold = React.forwardRef(function ArrowRightBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M8.612.494a1.5 1.5 0 00-2.224 2.012L14.978 12l-8.59 9.494a1.5 1.5 0 002.224 2.012l9.5-10.5a1.5 1.5 0 000-2.012l-9.5-10.5z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { HeaderItem } from './HeaderItem';

export interface IHeaderDropdownProps {
  isOpen?: boolean;
}

/**
 * HeaderDropdown
 * @param param0
 */
export const HeaderDropdown: React.FunctionComponent<IHeaderDropdownProps> = ({
  children,
  isOpen,
}) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <div
      className={cn('header-dropdown', {
        'is-open': isOpen,
      })}
    >
      <ul className={cn('header-dropdown-menu')}>
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return <HeaderItem _context="li" {...child.props} />;
          }

          return child;
        })}
      </ul>
    </div>
  );
};

HeaderDropdown.displayName = 'Header.Dropdown';

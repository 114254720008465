/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const BookmarkFavourite = React.forwardRef(function BookmarkFavourite(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M18.75 0A2.25 2.25 0 0121 2.25v20.252a1.5 1.5 0 01-2.382 1.21L12.44 19.22a.75.75 0 00-.882 0l-6.177 4.492A1.5 1.5 0 013 22.5V2.25A2.25 2.25 0 015.25 0h13.5zm0 1.5H5.25a.75.75 0 00-.75.75v20.248l6.177-4.492a2.25 2.25 0 012.646 0l6.177 4.492V2.25a.75.75 0 00-.75-.75z" />
    </svg>
  );
});

import { canUseDom } from '../utils';

export enum PortalRoots {
  TOAST = 'toast',
  POPOVER = 'popover',
  TOOLTIP = 'tooltip',
  DROPDOWN = 'dropdown',
  MODAL = 'modal',
}

type PortalRootMap = {
  [key in PortalRoots]: Element | undefined;
};

const portalRenderRoots: PortalRootMap = {} as PortalRootMap;

export type UseTooltipHookAction = {
  portalRoot: Element;
};

/**
 * usePortalRoot
 * Get the portal render root of a specific Modal
 * TODO: This is an experiment. A better abstraction would be to completely bastract the renderroots
 * portals and other placement logic away into its own, context or hook.
 *
 *
 *
 * @returns Portal Root Hook
 * @memberof hooks
 * @author Simon Groenborg
 */
export const usePortalRoot = (root: PortalRoots): UseTooltipHookAction => {
  if (!canUseDom) {
    throw new Error('dom not available');
  }

  if (!portalRenderRoots[root]) {
    const elm = document.createElement('div');
    elm.id = root;
    portalRenderRoots[root] = elm;
    document.body.appendChild(elm);
  }

  return {
    portalRoot: portalRenderRoots[root] as Element,
  };
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ShoppingBagEmptyBold = React.forwardRef(function ShoppingBagEmptyBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#shopping-bag-empty-bold_svg__shopping-bag-empty-bold_svg__clip0)">
        <path d="M4.967 6h14.212a2.157 2.157 0 012.048 1.8l2.006 13.98c.033.6-.192 1.186-.618 1.61a2.077 2.077 0 01-1.567.61l-18.066-.001a2.107 2.107 0 01-1.612-.609c-.426-.424-.651-1.009-.612-1.675L2.76 7.777a2.15 2.15 0 012.206-1.776zm7.012-6a4.515 4.515 0 014.42 3.666.75.75 0 01-1.474.282A3.014 3.014 0 0011.98 1.5a3.013 3.013 0 00-2.943 2.432.75.75 0 01-1.472-.288A4.514 4.514 0 0111.98 0z" />
      </g>
      <defs>
        <clipPath id="shopping-bag-empty-bold_svg__shopping-bag-empty-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

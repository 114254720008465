/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const SeoSearchBold = React.forwardRef(function SeoSearchBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M2.25 2.983H11a1 1 0 100-2H1.58C.73.963.024 1.633 0 2.483v3c.024.85.73 1.52 1.58 1.5H11a1 1 0 100-2H2.25a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25z" />
      <path
        clipRule="evenodd"
        d="M21.369 6.18a.249.249 0 00.043.3l2.297 2.3a1 1 0 01-1.414 1.414L20 7.894a.251.251 0 00-.3-.042 4.182 4.182 0 111.669-1.672zm-5.683-1.163a2.18 2.18 0 104.028-1.668 2.18 2.18 0 00-4.028 1.668z"
        fillRule="evenodd"
      />
      <path d="M3 15.983a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0 5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm11.5-6H5a1 1 0 100 2h9.5a1 1 0 100-2zm-11.5-4a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm11.5-1H5a1 1 0 100 2h9.5a1 1 0 100-2zm0 10H5a1 1 0 100 2h9.5a1 1 0 100-2z" />
    </svg>
  );
});

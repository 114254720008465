/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DirectionsBold = React.forwardRef(function DirectionsBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M20.811 1.714l2.469 2.469c.583.583.583 1.543 0 2.108L20.81 8.76a.752.752 0 01-.531.223h-7.53v1.508h7.547a.76.76 0 01.754.755v6a.761.761 0 01-.754.754H12.75v4.5a1.5 1.5 0 01-3 0V18H3.788a.752.752 0 01-.53-.223L.787 15.31a1.493 1.493 0 010-2.126l2.47-2.434a.752.752 0 01.53-.223H9.75v-1.51H3.788a.761.761 0 01-.754-.754v-6a.76.76 0 01.754-.754H9.75V1.5a1.5 1.5 0 013-.009h7.53c.206 0 .394.086.531.223z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CodeBold = React.forwardRef(function CodeBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#code-bold_svg__code-bold_svg__clip0)">
        <path d="M21.39 23.28H2.76a2.69 2.69 0 01-2.68-2.69V4.44a2.69 2.69 0 012.68-2.69h18.63a2.69 2.69 0 012.69 2.69v16.15a2.69 2.69 0 01-2.69 2.69zM2.76 3.75a.68.68 0 00-.69.68v16.16a.69.69 0 00.69.69h18.63a.69.69 0 00.69-.69V4.44a.69.69 0 00-.69-.69H2.76z" />
        <path d="M1.08 7.38L1 22.28h22.08V7.38h-22zm7.28 10.53a1 1 0 01-1.41.09L4.6 15.92a2 2 0 01.09-2.72l2-2a1 1 0 011.628 1.096 1 1 0 01-.218.324l-2 2 2.15 1.9a1 1 0 01.11 1.39zm6-5.46L11.66 18a1 1 0 01-1.79-.88l2.72-5.51a1 1 0 111.79.88l-.02-.04zm5.32 3.65l-.09.09-2.26 2a1.003 1.003 0 01-1.33-1.5l2.17-1.92-2-2a1 1 0 011.41-1.42l2 2a2 2 0 01.12 2.76l-.02-.01z" />
      </g>
      <defs>
        <clipPath id="code-bold_svg__code-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

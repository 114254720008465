/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ListSearch = React.forwardRef(function ListSearch(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M2.356 0h19.267a2.369 2.369 0 012.363 2.361v8.133a.75.75 0 01-1.5 0V5.997H1.5v12.625a.87.87 0 00.866.867H6.76a.75.75 0 010 1.499h-4.4A2.369 2.369 0 010 18.625V2.353A2.36 2.36 0 012.353 0h.003zm-.857 4.497h20.988V2.365a.87.87 0 00-.867-.866H2.357a.862.862 0 00-.858.858v2.14z"
        fillRule="evenodd"
      />
      <path d="M17.643 12.835a.75.75 0 010 1.06l-2.998 2.998a.75.75 0 01-1.06 0l-1.5-1.499a.75.75 0 111.06-1.06l.97.97 2.468-2.469a.75.75 0 011.06 0z" />
      <path
        clipRule="evenodd"
        d="M12.331 8.749a6.62 6.62 0 017.714 10.236l3.735 3.735a.75.75 0 11-1.06 1.06l-3.735-3.735a6.621 6.621 0 01-10.236-2.648 6.62 6.62 0 013.582-8.648zm2.533.995a5.121 5.121 0 100 10.242 5.121 5.121 0 000-10.242z"
        fillRule="evenodd"
      />
    </svg>
  );
});

/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * SidebarGroup
 */
export const SidebarGroup: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return <div className={cn('sidebar-group')}>{children}</div>;
};

SidebarGroup.displayName = 'Sidebar.Group';

/**
 * SidebarDivider
 */
export const SidebarDivider: React.FunctionComponent = () => {
  const cn = useFrameworkClassnamePrefixer();
  return <hr className={cn('sidebar-divider')} />;
};

SidebarDivider.displayName = 'Sidebar.Divider';

/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * HeaderGroup
 * Group Menu Links together in the navigation
 * @param param0
 */
export const HeaderGroup: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  return (
    <nav className={cn('header-nav')} id="headerNav">
      {children}
    </nav>
  );
};

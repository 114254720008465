import { Cross } from '@lego/klik-icons-react';
import * as React from 'react';
import { Nullable } from '../../utility/types';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';

export type PopoverBodyProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  content: string;
  position?: string;
  onExit?: () => void;
};

/**
 * PopoverBody
 * This is the visual element of the tooltip and does not include placement or any utilities
 * for using it.
 *
 * @param title title of the popover
 * @param content title of the popover
 * @param title title of the popover
 * @returns TooltipBox Component
 * @memberof tooltip
 * @author Simon Groenborg
 */
export const PopoverBody = React.forwardRef<HTMLDivElement, PopoverBodyProps>(
  ({ content, title, onExit, position, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    // eslint-disable-next-line no-null/no-null
    const exitRef = React.useRef<Nullable<HTMLButtonElement>>(null);

    const onClickHandler = React.useCallback(() => {
      if (onExit) {
        onExit();
      }
    }, [onExit]);

    React.useEffect(() => {
      // focus the exit button immediately
      exitRef.current?.focus();
    }, []);

    return (
      <div
        className={cn('toast', 'popover', position)}
        ref={ref}
        role="presentation"
        tabIndex={-1}
        {...props}
      >
        <div className={cn('arrow-below')} />
        <div className={cn('arrow-above')} />
        <div className={cn('toast-main')}>
          <div className={cn('toast-content')}>
            <p className={cn('toast-heading')}>{title}</p>
            <div className={cn('toast-body')}>
              <p>{content}</p>
            </div>
          </div>
        </div>
        <button className={cn('toast-close')} onClick={onClickHandler} ref={exitRef} tabIndex={0}>
          <Icon as="span" icon={<Cross />} />
        </button>
      </div>
    );
  }
);

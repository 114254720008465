/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DeleteBold = React.forwardRef(function DeleteBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M9.577 1A3.115 3.115 0 006.46 4.115v.116H1.5a1.5 1.5 0 000 3h1.731v13.038a3.116 3.116 0 003.115 3.116h11.308a3.115 3.115 0 003.115-3.116V7.231H22.5a1.5 1.5 0 000-3h-4.962v-.116A3.116 3.116 0 0014.423 1H9.577zm0 8.077a1.5 1.5 0 011.5 1.5v6.461a1.5 1.5 0 01-3 0v-6.461a1.5 1.5 0 011.5-1.5zm6.346 1.5a1.5 1.5 0 10-3 0v6.461a1.5 1.5 0 003 0v-6.461z"
        fillRule="evenodd"
      />
    </svg>
  );
});

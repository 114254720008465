import * as React from 'react';

export enum FocusSelectionMode {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
}

export interface ITabsContext {
  id: string;
  activeTab: number;
  setActiveTab: (index: number) => void;
  focusedTab: number;
  setFocusedTab: (index: number) => void;
  selectionMode: FocusSelectionMode;
}

export const TabsContext = React.createContext<ITabsContext | undefined>(undefined);

export const useTabsContext = (): ITabsContext => {
  const context = React.useContext(TabsContext);

  if (context) {
    return context;
  }

  throw new Error("Tab compound components can't be rendered outside Tabs component");
};

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LearningLibrary = React.forwardRef(function LearningLibrary(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#learning-library_svg__learning-library_svg__clip0)">
        <path d="M2.213 2.273l.183.005c3.703.192 6.703 1.053 8.547 2.339a.75.75 0 11-.858 1.23C8.496 4.74 5.762 3.954 2.304 3.775a.75.75 0 00-.797.648l-.007.104v13.356a.747.747 0 00.69.753c5.618.253 9.792 2.08 10.525 4.388a.75.75 0 01-1.392.549l-.038-.095c-.486-1.532-4.106-3.116-9.174-3.345a2.25 2.25 0 01-2.107-2.101L0 17.878V4.528a2.25 2.25 0 012.213-2.255z" />
        <path d="M23.25 8.25a.75.75 0 01.743.648L24 9v8.873a2.253 2.253 0 01-2.123 2.261c-4.938.223-8.507 1.74-9.123 3.237l-.04.107a.75.75 0 11-1.429-.454c.733-2.308 4.907-4.135 10.512-4.388a.75.75 0 00.697-.659l.006-.099V9a.75.75 0 01.75-.75zM3.852 6.728c1.96.22 3.889.67 5.745 1.34a.75.75 0 11-.51 1.41 23.688 23.688 0 00-5.403-1.26.75.75 0 01.066-1.495l.102.005zm.002 3.815a25.71 25.71 0 015.74 1.339.75.75 0 11-.505 1.412 24.267 24.267 0 00-5.407-1.26.75.75 0 01.07-1.496l.102.005zM3.7 14.582l.101.005a26 26 0 015.792 1.338.75.75 0 01-.502 1.414 24.496 24.496 0 00-5.458-1.262.75.75 0 01.066-1.495z" />
        <path d="M12 9.751a.75.75 0 01.743.648l.007.102v12.75a.75.75 0 01-1.493.102l-.007-.102v-12.75a.75.75 0 01.75-.75zm5.25-9.75a.75.75 0 01.743.648L18 .751v1.5a.75.75 0 01-1.493.102L16.5 2.25V.75a.75.75 0 01.75-.75zM11.909 2.69a.75.75 0 01.976-.072l.084.073 1.061 1.06a.75.75 0 01-.976 1.134l-.084-.073-1.061-1.06a.75.75 0 010-1.061zm9.621 0a.75.75 0 011.134.977l-.073.084-1.06 1.061a.75.75 0 01-1.134-.976l.073-.084 1.06-1.061zm-4.28 9.311a.75.75 0 01.743.648l.007.102v1.5a.75.75 0 01-1.493.102l-.007-.102v-1.5a.75.75 0 01.75-.75z" />
        <path d="M13.664 7.14a3.75 3.75 0 001.218 4.005l.118.091v1.515c0 .414.336.75.75.75h3l.102-.007a.75.75 0 00.648-.743v-1.514l.164-.129a3.75 3.75 0 10-5.936-4.16l-.064.192zm3.984-1.118a2.25 2.25 0 01.729 4.162l-.094.065a.75.75 0 00-.283.586v1.166h-1.5v-1.166a.75.75 0 00-.375-.65 2.25 2.25 0 011.523-4.163z" />
      </g>
      <defs>
        <clipPath id="learning-library_svg__learning-library_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

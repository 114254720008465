/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const ShoppingBagEmpty = React.forwardRef(function ShoppingBagEmpty(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#shopping-bag-empty_svg__shopping-bag-empty_svg__clip0)">
        <path d="M4.806 6H19.18a2.157 2.157 0 012.048 1.8l2.006 13.98c.033.6-.192 1.186-.618 1.61a2.077 2.077 0 01-1.567.61l-18.066-.001a2.107 2.107 0 01-1.612-.609c-.426-.424-.651-1.009-.612-1.675L2.76 7.777a2.15 2.15 0 012.206-1.776L4.807 6zM19.05 7.5H4.906a.645.645 0 00-.663.514L2.249 21.863a.607.607 0 00.178.463.642.642 0 00.51.174l18.157.001a.606.606 0 00.463-.175.608.608 0 00.185-.397L19.745 8.037c-.056-.321-.34-.55-.696-.537zM11.98 0a4.515 4.515 0 014.42 3.666.75.75 0 01-1.474.282A3.014 3.014 0 0011.98 1.5a3.013 3.013 0 00-2.943 2.432.75.75 0 01-1.472-.288A4.514 4.514 0 0111.98 0z" />
      </g>
      <defs>
        <clipPath id="shopping-bag-empty_svg__shopping-bag-empty_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

export type TooltipBoxProps = React.HTMLAttributes<HTMLDivElement> & {
  label?: string;
  arrowPosition?: string;
};

/**
 * TooltipBox
 * This is the visual element of the tooltip and does not include placement or any utilities
 * for using it.
 * @param position
 * @param content the content of the tooltip
 * @returns TooltipBox Component
 * @memberof tooltip
 * @author Simon Groenborg
 */
export const TooltipBox = React.forwardRef<HTMLDivElement, TooltipBoxProps>(
  ({ label, arrowPosition, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();

    return (
      <div
        className={cn('toast', 'popover', 'tooltip', arrowPosition)}
        ref={ref}
        tabIndex={-1}
        {...props}
      >
        <div className={cn('arrow-below')} />
        <div className={cn('arrow-above')} />
        <div className={cn('toast-main')}>
          <div className={cn('toast-content')}>
            <div className={cn('toast-body')}>
              <p>{label}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

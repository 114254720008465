/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const DashboardBold = React.forwardRef(function DashboardBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M11 12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h7a2 2 0 012 2v10zm2 0a2 2 0 012-2h7a2 2 0 012 2v10a2 2 0 01-2 2h-7a2 2 0 01-2-2V12zm2-12a2 2 0 00-2 2v4a2 2 0 002 2h7a2 2 0 002-2V2a2 2 0 00-2-2h-7zm-4 22a2 2 0 01-2 2H2a2 2 0 01-2-2v-4a2 2 0 012-2h7a2 2 0 012 2v4z"
        fillRule="evenodd"
      />
    </svg>
  );
});

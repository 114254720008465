/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const FilesCode = React.forwardRef(function FilesCode(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.34 6.59L15.5.71A2.25 2.25 0 0013.88 0H3.25A2.25 2.25 0 001 2.3v19.4A2.25 2.25 0 003.25 24h16.5A2.25 2.25 0 0022 21.7V8.18a2.25 2.25 0 00-.66-1.59zM14.5 1.86l5.69 5.69h-4.94a.75.75 0 01-.75-.74V1.86zm6 19.84a.75.75 0 01-.75.75H3.25a.75.75 0 01-.75-.75V2.3a.75.75 0 01.75-.75H13v5.26a2.25 2.25 0 002.25 2.25h5.25V21.7z" />
      <path d="M13.5 10.32a.68.68 0 000 1l2.91 2.91-2.91 2.87a.68.68 0 000 1 .67.67 0 001 0l3.39-3.39a.681.681 0 000-1l-3.39-3.39a.68.68 0 00-1 0zm-3.12 0a.68.68 0 00-1 0l-3.35 3.39a.68.68 0 000 1l3.39 3.39a.67.67 0 001 0 .681.681 0 000-1L7.5 14.19l2.91-2.91a.68.68 0 00-.03-.96z" />
    </svg>
  );
});

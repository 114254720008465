/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LogOut = React.forwardRef(function LogOut(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M21.507 2h.037A2.058 2.058 0 0123.5 4.133V20.5a2.06 2.06 0 01-1.992 2.132h-11.22A2.059 2.059 0 018.333 20.5v-4.084a.75.75 0 011.5 0v4.1a.56.56 0 00.515.617h11.136a.559.559 0 00.515-.617V4.082a.558.558 0 00-.515-.582H10.348a.56.56 0 00-.515.617v4.1a.75.75 0 11-1.5 0V4.131A2.06 2.06 0 0110.326 2h11.18z" />
      <path d="M5.697 8.373a.75.75 0 00-1.06 0l-3.41 3.41a.748.748 0 000 1.075l3.41 3.408a.75.75 0 101.06-1.06L3.56 13.07h12.538a.75.75 0 100-1.5H3.561l2.136-2.136a.75.75 0 000-1.061z" />
    </svg>
  );
});

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const IdeasBold = React.forwardRef(function IdeasBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <g clipPath="url(#ideas-bold_svg__ideas-bold_svg__clip0)">
        <path d="M12 3.75a7.62 7.62 0 00-7.5 7.49 7.35 7.35 0 003.75 6.5h7.5a7.35 7.35 0 003.75-6.51A7.62 7.62 0 0012 3.75zm-.75 12.75v-2.62L9.53 12.1c-.39-.39-.76-1-.37-1.38.39-.38.83-.3 1.22.09L12 12.44l1.48-1.53c.39-.39.91-.58 1.3-.19.39.39.23.83-.16 1.22l-1.87 1.87v2.69h-1.5zm-3 1.99v1a2.25 2.25 0 002.25 2.25h.5V23a1 1 0 00.66.93.68.68 0 00.67 0A1 1 0 0013 23v-1.25h.5a2.25 2.25 0 002.25-2.25v-1h-1.5l-6-.01zM12 3.35a1 1 0 001-1V1a1 1 0 00-2 0v1.4a1 1 0 001 .95zm8.92-.25a1 1 0 00-1.41 0l-1 1a1 1 0 101.38 1.44l1-1a1 1 0 00.03-1.44zM5.53 5.45A1 1 0 005.5 4l-1-1a1.033 1.033 0 10-1.4 1.52l1 1a1 1 0 001.41 0l.02-.07zm16.87 4.67H21a1 1 0 000 2h1.4a1 1 0 000-2zm-20.85.13a1 1 0 000 2H3a1 1 0 000-2H1.55z" />
      </g>
      <defs>
        <clipPath id="ideas-bold_svg__ideas-bold_svg__clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
});

import * as React from 'react';
import { FontSizeMap } from '../../constants';
import { useFont } from '../../hooks';
import { useFrameworkClassnamePrefixer } from '../../utils';

type SizeCompatibility = keyof Pick<FontSizeMap, 'small' | 'normal' | 'medium'>;

export type LabelProps = React.HTMLAttributes<HTMLDivElement> & {
  label?: string;
  size?: SizeCompatibility;
  htmlFor?: string;
  form?: string;
};

/**
 * Label
 * @summary use the label in kombination with any form element
 * @returns ExoticLabelElement
 */

export const Label = React.forwardRef<HTMLDivElement, LabelProps>(
  ({ label, htmlFor, form, size, ...props }, ref) => {
    const cn = useFrameworkClassnamePrefixer();
    const fontSize = useFont(size);

    return (
      <div className={cn('form-label', fontSize)} ref={ref} {...props}>
        <label form={form} htmlFor={htmlFor}>
          {label}
        </label>
      </div>
    );
  }
);

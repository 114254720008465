import { ArrowLeft, CrossBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';

export type MenuPageProps = {
  label?: string;
  page?: number;
  onClick?: () => void;
};

/**
 * MenuPagesBack
 * React MenuPagesBack component.
 *
 *
 * @returns MenuPagesBack Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuPagesBack: React.FunctionComponent<MenuPageProps> = ({ label, page, onClick }) => {
  const cn = useFrameworkClassnamePrefixer();

  if (page === 0) {
    // eslint-disable-next-line no-null/no-null
    return null;
  }

  return (
    <button className={cn('menu-pages-back')} onClick={onClick}>
      <Icon icon={<ArrowLeft />} />
      <span>{label}</span>
    </button>
  );
};

MenuPagesBack.displayName = 'MenuPages.PagesBack';

/**
 * MenuPagesClose
 * React MenuPagesClose component.
 *
 *
 * @returns MenuPagesClose Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuPagesClose: React.FunctionComponent<MenuPageProps> = ({ onClick }) => {
  const cn = useFrameworkClassnamePrefixer();

  return (
    <button className={cn('menu-pages-close')} onClick={onClick}>
      <Icon icon={<CrossBold />} />
    </button>
  );
};

MenuPagesClose.displayName = 'MenuPages.PagesClose';

/**
 * MenuPagesHeader
 * React MenuPagesHeader component.
 *
 *
 * @returns MenuPagesHeader Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuPagesHeader: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();
  const dropDownClasses = cn('menu-pages-header');

  return <div className={dropDownClasses}>{children}</div>;
};

MenuPagesHeader.displayName = 'MenuPages.PagesHeader';

/**
 * MenuPagesList
 * React MenuPagesList component.
 *
 *
 * @returns MenuPagesList Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuPagesList: React.FunctionComponent<MenuPageProps> = ({ children, page }) => {
  const cn = useFrameworkClassnamePrefixer();
  const dropDownClasses = cn('menu-pages-list');

  return (
    <div className={dropDownClasses}>
      {React.Children.map(children, (child, index) => {
        if (page === index) {
          return child;
        }

        // eslint-disable-next-line no-null/no-null
        return null;
      })}
    </div>
  );
};

MenuPagesList.displayName = 'MenuPages.PagesList';

/**
 * MenuPage
 * React MenuPage component.
 *
 *
 * @returns MenuPage Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuPage: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('menu-page')}>{children}</div>;
};

MenuPage.displayName = 'MenuPages.Page';

interface IMenuPagesSubcomponents {
  Header: typeof MenuPagesHeader;
  List: typeof MenuPagesList;
  Back: typeof MenuPagesBack;
  Close: typeof MenuPagesClose;
  Page: typeof MenuPage;
}

/**
 * MenuPages
 * React MenuPages component.
 *
 *
 * @returns MenuPages Component
 * @memberof menu
 * @author Jeremy Thomas
 */
export const MenuPages: React.FunctionComponent & IMenuPagesSubcomponents = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('menu-pages')}>{children}</div>;
};

MenuPages.Header = MenuPagesHeader;
MenuPages.List = MenuPagesList;
MenuPages.Back = MenuPagesBack;
MenuPages.Close = MenuPagesClose;
MenuPages.Page = MenuPage;

MenuPages.displayName = 'Menu.Pages';

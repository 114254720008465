import { ArrowDownBold } from '@lego/klik-icons-react';
import * as React from 'react';
import { Sizes } from '../../constants';
import { useClickOutside, useShareForwardRef } from '../../hooks';
import { Nullable, Pair } from '../../utility/types';
import { chain, useFrameworkClassnamePrefixer } from '../../utils';
import { Dropdown, DropdownScreenProps, IDropdownableElement, useDropdown } from '../dropdown';
import { DropdownItem } from '../dropdown/DropdownItem';
import { Icon } from '../icon';

export type SplitButtonProps = DropdownScreenProps &
  IDropdownableElement<string> &
  React.HTMLAttributes<HTMLButtonElement> & {
    isDisabled?: boolean;
    size?: Sizes;
    label?: string;
    defaultSelected?: Pair<string>;
    onDropdownSelect?: (option: Pair<string>) => void;
  };

interface ISplitButtonSubComponents
  extends React.ForwardRefExoticComponent<SplitButtonProps & React.RefAttributes<HTMLElement>> {
  Action: typeof DropdownItem;
}

/**
 * Split button
 *
 * @returns SplitButton Component
 * @memberof SplitButton
 * @author Simon Groenborg
 */
export const SplitButton = React.forwardRef<HTMLButtonElement, SplitButtonProps>(
  (
    {
      isDisabled = false,
      size = 'medium',
      label,
      onDropdownSelect,
      isNarrow,
      isClipped,
      children,
      ...props
    },
    ref
  ) => {
    const cn = useFrameworkClassnamePrefixer();
    // eslint-disable-next-line no-null/no-null
    const dropdown = React.useRef<Nullable<HTMLDivElement>>(null);
    const reference = useShareForwardRef<HTMLButtonElement>(ref);
    // eslint-disable-next-line no-null/no-null
    const reff = React.useRef<Nullable<HTMLDivElement>>(null);

    const { toggleVisible, setVisible } = useDropdown(dropdown, reference);

    const buttonClasses = {
      [`button`]: true,
      [`is-${size}`]: size,
      [`is-disabled`]: isDisabled,
    };

    useClickOutside(dropdown, () => {
      setVisible(false);
    });

    return (
      <div className={cn('split-button')} ref={reff} role="button">
        <button className={cn(buttonClasses)} ref={ref} {...props}>
          {label}
        </button>
        <button
          className={cn('is-split-icon', buttonClasses)}
          onClick={toggleVisible}
          ref={reference}
        >
          <Icon as="span" icon={<ArrowDownBold />} />
        </button>
        <Dropdown
          isClipped={isClipped}
          isNarrow={isNarrow}
          onDropdownSelect={chain(toggleVisible, onDropdownSelect)}
          ref={dropdown}
        >
          {children}
        </Dropdown>
      </div>
    );
  }
) as ISplitButtonSubComponents;

SplitButton.Action = DropdownItem;

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';

/**
 * CardHeader
 * @param param0 props
 */
export const CardHeader: React.FunctionComponent = ({ children }) => {
  const cn = useFrameworkClassnamePrefixer();

  return <div className={cn('card-header')}>{children}</div>;
};

CardHeader.displayName = 'Card.Header';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const CloudAvailable = React.forwardRef(function CloudAvailable(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M17.03 19.502H4.52c-1.5-.11-4.34-1.06-4.5-3.88a4.42 4.42 0 011.33-3.61 4.36 4.36 0 013-1.07 7.13 7.13 0 016.71-5.93c4.34-.21 6.31 2.34 6.88 3.75a5.77 5.77 0 013.36 1.32 5.25 5.25 0 011.72 4.18 4.849 4.849 0 01-1.7 3.66 6.25 6.25 0 01-4.29 1.58zm-11.51-7.23a.839.839 0 01-.69.21 3.605 3.605 0 00-.54 0 2.8 2.8 0 00-1.9.68 2.91 2.91 0 00-.87 2.38c.13 2.2 3 2.46 3.1 2.47h12.41a4.76 4.76 0 003.25-1.19 3.37 3.37 0 001.24-2.57 3.85 3.85 0 00-1.15-3 4.35 4.35 0 00-2.75-1 .9.9 0 01-1-.69s-1-3.28-5.49-3.06c-4.85.25-5.36 5.06-5.36 5.11a1.06 1.06 0 01-.25.66z" />
    </svg>
  );
});

import * as React from 'react';
import { useFrameworkClassnamePrefixer } from '../../utils';
import { Icon } from '../icon';
import { useContentSwitcherContext } from './ContentSwitcherContext';
import { makeItemId } from './utils';

export interface IContentSwitcherItemProps {
  label?: string;
  index: number;
  icon?: React.ReactElement;
  iconSelected?: React.ReactElement;
  iconBefore?: React.ReactElement;
  iconBeforeSelected?: React.ReactElement;
  iconAfter?: React.ReactElement;
  iconAfterSelected?: React.ReactElement;
  disabled?: boolean;
}

// eslint-disable-next-line complexity
export const ContentSwitcherItem: React.FunctionComponent<IContentSwitcherItemProps> = ({
  label,
  index,
  icon,
  iconSelected,
  iconBefore,
  iconBeforeSelected,
  iconAfter,
  iconAfterSelected,
  disabled = false,
  ...props
}) => {
  const cn = useFrameworkClassnamePrefixer();

  const {
    id,
    selectedItem,
    setSelectedItem,
    focusedItem,
    setFocusedItem,
    isDisabled,
  } = useContentSwitcherContext();

  const isSelected = selectedItem === index;

  const className = cn('switcher-item', {
    'is-selected': isSelected,
    'is-focused': focusedItem === index,
    'is-disabled': disabled,
    'is-icon-only': icon,
  });

  const iconBeforeEl = iconBefore && (
    <Icon
      as="span"
      icon={isSelected ? iconBeforeSelected ?? iconBefore : iconBefore}
      parentClass="icon-before"
    />
  );

  const iconAfterEl = iconAfter && (
    <Icon
      as="span"
      icon={isSelected ? iconAfterSelected ?? iconAfter : iconAfter}
      parentClass="icon-after"
    />
  );

  const attr = {
    className,
    role: 'presentation',
    'aria-selected': isSelected ? true : undefined,
    id: makeItemId(id, index),
    // eslint-disable-next-line react/jsx-no-bind
    onClick: () => {
      setFocusedItem(index);
      setSelectedItem(index);
    },
    disabled: disabled || isDisabled,
    ...props,
  };

  if (icon) {
    return (
      <button type="button" {...attr}>
        <Icon as="span" icon={isSelected ? iconSelected ?? icon : icon} />
      </button>
    );
  }

  return (
    <button type="button" {...attr}>
      {iconBeforeEl}
      <span>{label}</span>
      {iconAfterEl}
    </button>
  );
};

ContentSwitcherItem.displayName = 'ContentSwitcher.Item';

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Refresh = React.forwardRef(function Refresh(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M19.378 10.056a.746.746 0 00-.825.159l-2.667 2.667a.75.75 0 001.06 1.06l1.368-1.367a6.583 6.583 0 01-11.352 4.02.75.75 0 10-1.09 1.03 8.084 8.084 0 0013.948-5.082l1.4 1.4a.75.75 0 001.06-1.061l-2.664-2.664a.763.763 0 00-.238-.162zm-11.7.811a.776.776 0 00-1.092 0l-1.39 1.398v-.2c0-1.397.397-2.695 1.191-3.793.795-1.099 1.887-1.897 3.178-2.396 1.291-.5 2.681-.5 3.973-.2 1.29.4 2.482 1.098 3.277 2.196.298.3.695.4 1.092.1.298-.3.397-.699.1-1.098-1.093-1.298-2.483-2.196-4.072-2.596-1.59-.399-3.277-.399-4.866.2a8.206 8.206 0 00-3.873 2.995c-.993 1.398-1.49 2.995-1.49 4.592v.2l-1.39-1.398a.776.776 0 00-1.093 0 .786.786 0 000 1.098l2.682 2.696c.099.1.298.2.496.2.199 0 .397-.1.497-.2l2.681-2.695c.397-.3.397-.8.1-1.099z" />
    </svg>
  );
});

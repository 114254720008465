/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const Bluetooth = React.forwardRef(function Bluetooth(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M11.287.076a.75.75 0 01.79.082l6.75 5.25a.75.75 0 01.054 1.138l-5.982 5.63 5.964 5.262a.75.75 0 01-.036 1.154l-6.75 5.25a.75.75 0 01-1.21-.592v-9.161L6.13 18.546a.75.75 0 11-1.028-1.092l5.57-5.243L5.12 7.312a.75.75 0 11.993-1.124l4.754 4.194V.75a.75.75 0 01.42-.674zm1.08 13.63l4.825 4.258-4.825 3.753v-8.01zm0-3.089V2.283l4.848 3.771-4.848 4.563z"
        fillRule="evenodd"
      />
    </svg>
  );
});

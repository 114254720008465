import { toast } from 'react-toastify';
import { StatusSuccessBold, StatusWarningBold } from '@lego/klik-icons-react';
import { SnackbarContent } from '../../components/SnackbarContent';
import { snackbarTypes } from './snackbarTypes';
import { colors } from '../../config';

const getIconColor = (snackbarType) => {
  if (snackbarType === snackbarTypes.SUCCESS)
    return colors.utilityColors.success.success400;
  if (snackbarType === snackbarTypes.INFO)
    return colors.utilityColors.information.information400;
  if (snackbarType === snackbarTypes.WARNING)
    return colors.utilityColors.warning.warning400;
  if (snackbarType === snackbarTypes.ERROR)
    return colors.utilityColors.error.error500;

  return colors.neutralColors.slate90;
};

const addSnackbar = ({ header, text, type }) => {
  const Icon =
    type === snackbarTypes.SUCCESS ? StatusSuccessBold : StatusWarningBold;

  const getContent = () => (
    <SnackbarContent
      icon={<Icon css={{ color: getIconColor(type) }} />}
      header={header}
      text={text}
    />
  );

  if (!type) return toast(getContent());

  return toast[type](getContent());
};

export { addSnackbar };

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const PieChart = React.forwardRef(function PieChart(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path
        clipRule="evenodd"
        d="M20.894 3.856A9.75 9.75 0 0014 1a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h9a.75.75 0 00.75-.75 9.75 9.75 0 00-2.856-6.894zM14.75 2.534A8.249 8.249 0 0122.216 10H14.75V2.534z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.333 5.393A9.75 9.75 0 0110.75 3.75a.75.75 0 01.75.75v8.25h8.25a.75.75 0 01.75.75A9.75 9.75 0 115.333 5.393zM10 5.284a8.25 8.25 0 108.966 8.966H10.75a.75.75 0 01-.75-.75V5.284z"
        fillRule="evenodd"
      />
    </svg>
  );
});

import clsx, { ClassValue } from 'clsx';
import * as React from 'react';
import { useKlikContext } from '.';
import { Pair } from './utility/types';

type Prefixer = (...classes: ClassValue[]) => string;

export const prefixclsx = (prefix: string): Prefixer => {
  return (...classes): string => {
    return clsx(classes)
      .split(' ')
      .map((cname) => {
        const newStr = cname.replace(`${prefix}-`, '');

        return `${prefix}-${newStr}`;
      })
      .join(' ');
  };
};

// for some reason when you have one node as a child, you get back a single node,
// but if you have more, you get back an array of nodes.
// this ensures we always get back an array of nodes
export const getValidChildren = <T>(children?: React.ReactNode): React.ReactElement<T>[] => {
  return React.Children.toArray(children).filter((child) =>
    React.isValidElement(child)
  ) as React.ReactElement<T>[];
};

/**
 * Verify that the dom is available (needed for SSR)
 */
export const canUseDom = !!(typeof window !== 'undefined' && window.document.createElement);

export const alreadyInArray = <T extends string | number>(arr: Pair<T>[], obj: Pair<T>): boolean =>
  arr.some((item) => item.label === obj.label && item.value === obj.value);

export const useFrameworkClassnamePrefixer = (): Prefixer => {
  const { frameworkPrefix } = useKlikContext();

  return prefixclsx(frameworkPrefix);
};

/**
 * chain functions together
 * Typing is not done, but can be extended furter.
 * The base generic will provide base type safety for function arguments
 *  https://github.com/microsoft/TypeScript/pull/39094
 *
 * This code is Heavily inspired from react spectrum
 *
 * @param callbacks
 * @memberof utils
 * @author Simon Groenborg
 */
export const chain = <T>(...callbacks: T[]): ((...args: any[]) => void) => {
  return (...args: any[]): void => {
    for (const callback of callbacks) {
      if (typeof callback === 'function') {
        // eslint-disable-next-line node/callback-return
        callback(...args);
      }
    }
  };
};

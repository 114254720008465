/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import * as React from 'react';

export const LayoutDiagramBold = React.forwardRef(function LayoutDiagramBold(
  props: React.SVGProps<SVGSVGElement>,
  svgRef: React.Ref<SVGSVGElement>
) {
  return (
    <svg fill="currentColor" height={24} ref={svgRef} viewBox="0 0 24 24" width={24} {...props}>
      <path d="M8.25 0a.75.75 0 00-.75.75v7.5c0 .414.336.75.75.75h2.625v2.625H6a2.625 2.625 0 00-2.625 2.625V15H.75a.75.75 0 00-.75.75v7.5c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75v-7.5a.75.75 0 00-.75-.75H5.625v-.75A.375.375 0 016 13.875h12a.375.375 0 01.375.375V15H15.75a.75.75 0 00-.75.75v7.5c0 .414.336.75.75.75h7.5a.75.75 0 00.75-.75v-7.5a.75.75 0 00-.75-.75h-2.625v-.75A2.625 2.625 0 0018 11.625h-4.875V9h2.625a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75h-7.5z" />
    </svg>
  );
});
